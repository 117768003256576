import Follow from './FollowComponent';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CacheContext, CacheContextType } from '../context/CacheContext';
import { FollowData } from '../model/types';
function FollowPage() {
  const getFollow = useContext<CacheContextType>(CacheContext).getFollow;
  let location = useLocation().pathname.replace('/follows/', '');
  const [follow, setFollow] = useState<FollowData | null>(
    getFollow(location) ?? null,
  );

  useEffect(() => {
    setFollow(getFollow(location) ?? null);
  }, [location, getFollow]);

  return (
    <div id={follow?.id}>
      {follow && <Follow follow={follow} useParentID={false} />}
    </div>
  );
}

export default FollowPage;
