import React, { useEffect, useState, useContext } from 'react';

import {
  Divider,
  Stack,
  Select,
  Box,
  SelectChangeEvent,
  MenuItem,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { FeedData } from '../model/types';
import { CacheContext, CacheContextType } from '../context/CacheContext';
import TimeDuration from '../TimeDuration/index';
import { DateTime } from 'luxon';
function FeedsListPage({ showMenu = true }: { showMenu?: boolean }) {
  const [feeds, setFeeds] = useState<FeedData[]>([]);
  const [filterType, setFilterType] = useState('popular');
  const getFeeds = useContext<CacheContextType>(CacheContext).allFeeds();

  useEffect(() => {
    setFeeds(getFeeds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event: SelectChangeEvent) => {
    setFilterType(event.target.value as string);
  };

  function sortPopular(a: any, b: any) {
    return a.posts.length > b.posts.length ? -1 : 1;
  }
  function sortRecent(a: any, b: any) {
    return a.createdAt > b.createdAt ? -1 : 1;
  }
  function byExpire(a: any, b: any) {
    return a.expireAt > b.expireAt ? 1 : -1;
  }
  function longestLife(a: any, b: any) {
    return a.expireAt > b.expireAt ? -1 : 1;
  }
  function mostTime(a: any, b: any) {
    return a.expireAt - a.createdAt > b.expireAt - b.createdAt ? -1 : 1;
  }
  return (
    <Box width="100%">
      <Stack direction="row" spacing={2} alignContent="center">
        <Box alignContent="center"> Feeds</Box>
        {showMenu && (
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={filterType}
              label="Filter Type"
              onChange={handleChange}
              sx={{
                backgroundColor: 'white',
              }}
            >
              <MenuItem value={'popular'}>Popular</MenuItem>
              <MenuItem value={'recent'}>Recent</MenuItem>
              <MenuItem value={'expire'}>Expiring soon</MenuItem>
              <MenuItem value={'lifespan'}>Most Time</MenuItem>
              <MenuItem value={'time'}>Longest Lived</MenuItem>
            </Select>
          </FormControl>
        )}
      </Stack>
      <Stack
        divider={<Divider flexItem />}
        spacing={1}
        padding={0}
        sx={{
          width: '100%',
        }}
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>Feed</TableCell>
                <TableCell align="right">Posts</TableCell>
                <TableCell align="right">Created</TableCell>
                <TableCell align="right">Time Left</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {feeds
                .sort((a, b) => {
                  switch (filterType) {
                    case 'popular':
                      return sortPopular(a, b);
                    case 'recent':
                      return sortRecent(a, b);
                    case 'expire':
                      return byExpire(a, b);
                    case 'time':
                      return mostTime(a, b);
                    case 'lifespan':
                      return longestLife(a, b);
                    default:
                      return sortPopular(a, b);
                  }
                })
                .map((feed) => {
                  const isExpired =
                    DateTime.fromSeconds(feed.expireAt.seconds).toJSDate() <
                    new Date();

                  return (
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      key={feed.feedTag}
                    >
                      <TableCell component="th" scope="row">
                        <Link to={'/feeds/' + feed.feedTag}>
                          #{feed.feedTag}
                        </Link>
                      </TableCell>
                      <TableCell align="right">{feed.posts.length}</TableCell>
                      <TableCell align="right">
                        <TimeDuration
                          jsDate={DateTime.fromSeconds(
                            feed.createdAt.seconds,
                          ).toJSDate()}
                          invertSign={true}
                          titleDisplay="absoluteTime"
                        />{' '}
                        ago
                      </TableCell>

                      <TableCell align="right">
                        {isExpired && 'Expired '}
                        <TimeDuration
                          jsDate={DateTime.fromSeconds(
                            feed.expireAt.seconds,
                          ).toJSDate()}
                        />{' '}
                        {isExpired ? 'ago' : 'remaining'}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Box>
  );
}

export default FeedsListPage;
