import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Post from './postComponent';
import { CacheContext, CacheContextType } from '../context/CacheContext';
import { PostData, FollowData } from '../model/types';
import Follow from '../Follows/FollowComponent';
function PostPage() {
  const getPost = useContext<CacheContextType>(CacheContext).getPost;
  const getFollow = useContext<CacheContextType>(CacheContext).getFollow;
  let location = useLocation().pathname.replace('/posts/', '');
  const [parentIsFollow, setParentIsFollow] = useState(false);
  location = location.replace('&isReply=true', '');
  const originalPost = getPost(location) ?? null;
  const [post, setPost] = useState<PostData | FollowData | null>(
    getPost(location) ?? null,
  );
  function findParent(
    stackPost: PostData | FollowData | null,
  ): PostData | FollowData | null {
    if (stackPost?.parentType === 'follows') {
      setParentIsFollow(true);
      return getFollow(stackPost?.parentID) ?? null;
    }
    if (stackPost?.parentID !== undefined && stackPost?.parentID !== null) {
      return findParent(getPost(stackPost?.parentID) ?? null);
    }

    return stackPost;
  }
  useEffect(() => {
    setPost(findParent(getPost(location) ?? null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, getPost, getFollow]);

  return (
    <div id={post?.id}>
      {post &&
        (!parentIsFollow ? (
          <Post post={post} expandToReply={originalPost?.id} />
        ) : (
          <Follow
            follow={post}
            useParentID={false}
            expandToReply={originalPost?.id}
          />
        ))}
    </div>
  );
}
export default PostPage;
