import React, { useEffect, useState, useContext } from 'react';

import Post from '../postComponent/postComponent';
import {
  Divider,
  Stack,
  Select,
  Box,
  SelectChangeEvent,
  MenuItem,
  FormControl,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { PostData, FeedData, FollowData } from '../model/types';
import { CacheContext, CacheContextType } from '../context/CacheContext';
import { AuthContext, MyContext } from '../context/AuthContext';
import Follow from '../Follows/FollowComponent';
import { DateTime } from 'luxon';
function Feed({
  userRecordIDs,
  showMenu,
  feedRecord,
}: {
  userRecordIDs?: string[];
  showMenu?: boolean;
  feedRecord?: FeedData;
}) {
  const [feedType, setFeedType] = useState('all');
  const getPossiblePosts = useContext<CacheContextType>(CacheContext).allPosts;
  const getFollow = useContext<CacheContextType>(CacheContext).getFollow;
  const getPost = useContext<CacheContextType>(CacheContext).getPost;
  const getFeedFromID =
    useContext<CacheContextType>(CacheContext).getFeedFromID;
  const [posts, setPosts] = useState<Array<JSX.Element>>([]);
  const [filterExpired, setFilterExpired] = useState(false);
  const userRecord = useContext<MyContext>(AuthContext).userRecord;
  function findParent(
    stackPost: PostData | FollowData,
    expandToReply?: string,
  ): any {
    if (stackPost === undefined) return;
    if (!expandToReply) expandToReply = stackPost.id;
    if (stackPost.parentType === 'follows') {
      if (getFollow(stackPost.parentID) === undefined) return;
      return {
        post: (
          <Follow
            follow={getFollow(stackPost.parentID)}
            useParentID={false}
            expandToReply={expandToReply}
            key={stackPost.parentID}
          />
        ),
        id: stackPost.parentID,
      };
    } else if (
      stackPost.parentID !== undefined &&
      stackPost.parentID !== null
    ) {
      return findParent(
        getPost(stackPost?.parentID) as PostData,
        expandToReply,
      );
    }
    return {
      post: (
        <Post
          post={stackPost}
          expandToReply={expandToReply}
          key={expandToReply}
        />
      ),
      id: stackPost.id,
    };
  }

  // function findParentID(stackPost: PostData | FollowData): any {
  //   if (stackPost === undefined) return;
  //   if (stackPost.parentType === 'follows') {
  //     return stackPost.parentID;
  //   } else if (
  //     stackPost.parentID !== undefined &&
  //     stackPost.parentID !== null
  //   ) {
  //     return findParentID(getPost(stackPost?.parentID) as PostData);
  //   }
  //   return stackPost.id;
  // }

  //TODO: This is a mess, clean it up, (copilot wrote that)
  // need to think about that logic that expands replies in feed,
  // Do we just want to see any replies from someone we care about?
  //also need to add logic around expiration- maybe expired posts
  // might be good to auto-expand if they have living comments
  function sortPostsAndExpandReplies(posts: PostData[]) {
    let sortedPosts = posts.sort((a, b) =>
      a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0,
    );
    let parentIDs: string[] = [];
    let returnPosts: Array<JSX.Element> = [];
    sortedPosts.forEach((post) => {
      if (post.parentID === null) {
        parentIDs.push(post.id);
      }
    });

    sortedPosts.forEach((post) => {
      if (post.parentID === null) {
        returnPosts.push(<Post post={post} key={post.id} />);
      } else {
        let parent = findParent(getPost(post.id) as PostData);

        if (parent === undefined) return;

        if (!parentIDs.includes(parent.id)) {
          parentIDs.push(parent.parentID);
          returnPosts.push(parent.post);
        }
      }
    });

    return returnPosts;
  }

  useEffect(() => {
    const possiblePosts = getPossiblePosts();
    let displayPosts = possiblePosts;

    if (feedRecord !== undefined) setFeedType('feed');
    else if (userRecordIDs) setFeedType('user');
    else if (userRecord?.follows === undefined || userRecord.follows.length < 1)
      setFeedType('all');
    switch (feedType) {
      default:
        displayPosts = possiblePosts;
        break;
      case 'user':
        displayPosts = possiblePosts.filter((post) =>
          userRecordIDs?.includes(post.userRecordID),
        );

        break;
      case 'feed':
        displayPosts = possiblePosts.filter((post) =>
          feedRecord?.posts.includes(post.id),
        );
        break;
      case 'follows':
        displayPosts = possiblePosts.filter((post) => {
          let flag = false;
          userRecord?.follows?.forEach((follow) => {
            if (
              !follow.isExpired &&
              DateTime.fromSeconds(follow.expireAt.seconds).toJSDate() >
                new Date()
            ) {
              if (
                follow.parentType === undefined ||
                follow.parentType === 'users'
              ) {
                if (post.userRecordID === follow.parentID) flag = true;
              } else if (follow.parentType === 'feeds') {
                const getFeed = getFeedFromID(follow.parentID) || null;
                if (getFeed?.posts.includes(post.id)) flag = true;
              }
            }
          });
          return flag;
        });
        break;
      case 'graveyard':
        setFilterExpired(false);
        displayPosts = possiblePosts.filter(
          (post) =>
            post.isExpired ||
            DateTime.fromSeconds(post.expireAt.seconds) < DateTime.now(),
        );
        break;
    }
    if (filterExpired) {
      displayPosts = displayPosts.filter(
        (post) => DateTime.fromSeconds(post.expireAt.seconds) > DateTime.now(),
      );
    }
    setPosts(sortPostsAndExpandReplies(displayPosts));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRecordIDs, feedType, getPossiblePosts, filterExpired]);

  const handleChange = (event: SelectChangeEvent) => {
    setFeedType(event.target.value as string);
  };

  return (
    <Box width="100%">
      <Stack direction="row" spacing={2} alignContent="center">
        {/* <Box alignContent="center"> Posts </Box> */}
        {showMenu && (
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Stack direction={'row'} spacing={2} alignContent="center">
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={feedType}
                label="Feed Type"
                onChange={handleChange}
                sx={{
                  backgroundColor: 'white',
                }}
              >
                <MenuItem value={'all'}>All Posts</MenuItem>
                <MenuItem value={'follows'}>Following</MenuItem>
                <MenuItem value={'graveyard'}>The Graveyard</MenuItem>
              </Select>
              {feedType !== 'graveyard' && (
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={filterExpired}
                      onChange={() => setFilterExpired((prev) => !prev)}
                    />
                  }
                  label="Filter expired posts"
                />
              )}
            </Stack>
          </FormControl>
        )}
      </Stack>
      <Divider
        flexItem
        sx={{
          width: {
            xs: '100%',
            md: '560px',
          },
          margin: '12px 0 12px 0',
        }}
      />
      <Stack
        divider={<Divider flexItem />}
        spacing={1}
        padding={0}
        sx={{
          width: {
            xs: '100%',
            md: '560px',
          },
        }}
      >
        {posts}
      </Stack>
    </Box>
  );
}

export default Feed;
