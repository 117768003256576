import React, { useContext } from 'react';

import { CacheContext, CacheContextType } from '../context/CacheContext';
import { Link } from 'react-router-dom';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
function MostFollowedTable({
  objectList,
  stat,
  identifier,
}: {
  objectList: Array<any>;
  stat: string;
  identifier: string;
}) {
  const getUserRecord =
    useContext<CacheContextType>(CacheContext).getUserRecord;

  interface followCountMap {
    [key: string]: number;
  }
  let followCounts: followCountMap = {};
  let userIDs: Array<string> = [];
  objectList.forEach((follow) => {
    if (followCounts[follow.parentID] === undefined) {
      userIDs.push(follow.parentID);
      followCounts[follow.parentID] = 1;
    } else {
      followCounts[follow.parentID]++;
    }
  });
  userIDs.sort((a, b) => {
    if (followCounts[a] === undefined) return 1;
    if (followCounts[b] === undefined) return -1;
    return followCounts[a] > followCounts[b] ? -1 : 1;
  });

  const leaders = userIDs.map((userID) => {
    return {
      id: userID,
      identifier: getUserRecord(userID)?.username,
      statValue: followCounts[userID],
    };
  });
  const top5 = leaders.slice(0, 5);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 200 }} size="small">
        <TableHead>
          <TableRow>
            <TableCell>{identifier}</TableCell>
            <TableCell align="right">{stat}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {top5.map((object) => {
            if (object === null) return null;
            return (
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                key={object?.id}
              >
                <TableCell component="th" scope="row">
                  <Link to={'/' + object?.identifier}>
                    @{object?.identifier}
                  </Link>
                </TableCell>
                <TableCell align="right">{object?.statValue}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default MostFollowedTable;
