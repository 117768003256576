import Box from '@mui/material/Box';
import humanizeDuration from 'humanize-duration';
import React, { useEffect, useState } from 'react';
import { useInterval } from 'usehooks-ts';

function TimeDisplay({
  timeValue,
  invertSign = false,
  titleDisplay = 'remainingTime',
}: {
  timeValue: number;
  invertSign?: boolean;
  titleDisplay?: 'remainingTime' | 'absoluteTime';
}) {
  const [shortString, setShortString] = useState('');
  const [fullString, setFullString] = useState('');

  const updateStrings = () => {
    const short = humanizeDuration(timeValue, { largest: 1, round: true });
    const full = humanizeDuration(timeValue, { round: true }) + ' remaining';
    setShortString(short);
    setFullString(full);
  };

  useEffect(updateStrings, [invertSign, timeValue, titleDisplay]);
  useInterval(updateStrings, 10000);

  return (
    <Box display={'inline'} title={fullString}>
      {shortString}
    </Box>
  );
}

export default TimeDisplay;
