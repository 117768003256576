import * as React from 'react';
const TimeWalletIcon = (props: any) => (
  <svg
    viewBox="95.07 93.089 305.648 255.835"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="m318.142 167.025 4.301 4.294-6.377 2.897-4.302-4.294zm-51.617-51.537 4.301 4.294-6.377 2.897-4.302-4.294zm10.68 1.398 47.315 47.241-6.378 2.897-47.315-47.241zm-67.53 62.275 9.265-.014-20.339-20.306 6.378-2.897-4.302-4.295-12.755 5.794 21.752 21.718Z" />
    <path d="m211.353 153.048 4.302 4.295-6.378 2.897-4.301-4.295zm10.68 1.398 4.301 4.295-6.377 2.897-4.302-4.295zm10.677 1.396 4.302 4.294-6.377 2.897-4.302-4.294zm23.285-23.073 4.301 4.295-6.377 2.897-4.302-4.295zm34.41 34.357 4.301 4.295-6.377 2.897-4.302-4.295zm-40.488 5.896 4.302 4.295-7.08 1.765-3.599-3.163 6.377-2.897Zm4.005-33.062 4.301 4.295-6.377 2.897-4.302-4.295z" />
    <path d="m279.73 165.728 4.302 4.295-6.378 2.897-4.301-4.295zm-27.886-18.578 4.301 4.295-6.377 2.897-4.302-4.295zm-2.076 7.192 4.301 4.295-12.755 5.794-4.301-4.295zm19.283 9.989 4.301 4.294-6.377 2.897-4.302-4.294zm-10.68-1.398 4.302 4.294-12.755 5.794-4.302-4.294z" />
    <path d="m264.449 122.68-12.755 5.794 4.301 4.295 6.378-2.897 8.602 8.589-6.377 2.897 4.301 4.295-6.376 2.896 4.301 4.295-6.377 2.897 4.301 4.295 6.378-2.897 4.301 4.295 6.377-2.897 4.301 4.295 6.378-2.897 8.602 8.589-6.377 2.897 4.301 4.295 12.755-5.794z" />
    <path
      fill="none"
      stroke="#000"
      strokeWidth={2}
      d="M99.819 185.497h298.225V344.91H99.819z"
    />
    <path d="M130.511 199.13h30.693v9.963H130.51zm46.039 0h30.692v9.963H176.55zm46.038 0h30.692v9.963h-30.692zm46.038 0h30.693v9.963h-30.693zm46.039 0h30.692v9.963h-30.692zM130.51 320.001h30.693v9.963H130.51zm46.039 0h30.692v9.963H176.55zm46.038 0h30.692v9.963h-30.692zm46.038 0h30.693v9.963h-30.693zm46.039 0h30.692v9.963h-30.692zm47.904-113.157v21.323h14.342v-21.323zm0 31.986v21.323h14.342V238.83zm0 31.982v21.324h14.342v-21.324zm0 31.986v21.324h14.342v-21.324zM98.846 199.048h15.935v9.963H98.846zm0 120.872h15.935v9.963H98.846z" />
    <g fill="#242424">
      <path
        d="m10900 12290-1515 1160h-115v230l-232 2-233 3-3 111-3 111-803 7c-442 4-1169 4-1615 0l-811-7v-227h-470v-230l-1632-1160h-228v-230h-230v-230h-230v-460h-230v-240h-230v-690h-228l-7-465-7-465h-218V7880h218l7-465 7-465h215l6-167c4-91 7-246 7-345v-178h230v-230h227l8-230 7-230h217l3-117 3-118 118-3 117-3v-229h230v-230h230v-230h230v-230h230v-228l235-7 235-7v-228h460v-230h-230 4640-240v230h460v230h240v230h115l817 700h228v230h230v240h230v460h230v230h230v173c0 94 3 249 7 344l6 172 111 3 111 3 3 462 2 462 113 3 112 3 3 813 2 812h-230l-2 463-3 462-112 3-113 3v163c0 89-3 245-7 344l-6 182h-227v230h-230v460h-230v240h-230v230h-230Zm-3020 810v-110h178c97 0 255-3 350-7l172-6v-214l173-6c94-4 250-7 345-7h172v-230h243l-5-115-5-115h457v-230h240v-240h230v-230h228l7-230 7-230h218v-230h228l7-235 7-235h228v-920h230V7660h-230v-177c0-98-3-256-7-350l-6-173h-215l-7-235-7-235h-228v-460h-230v-230h-230v-230h-230v-229l-117-3-118-3-3-117-3-118h-449v-230h-240v-230l-232-2-233-3-3-111-3-111-559-7c-307-4-984-4-1504 0l-946 7v227h-690v230h-460v230h-229l-3 118-3 117-117 3-118 3v229h-230v230h-230v460h-230v230h-230l-2 233-3 232-111 3-111 3-6 172c-4 95-7 250-7 345v172h-217l-6 173c-9 223-9 1521 0 1745l6 172h217v168c0 93 3 251 7 350l6 182h215l7 230 8 230h227v460h230v230h230v230h229l3 118 3 117 118 3 117 3v229h230v230h460v230h178c99 0 254 3 345 7l167 6v214l182 6c99 4 257 7 350 7h168v220h1390v-110Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          fill: '#000',
          paintOrder: 'stroke',
        }}
        transform="translate(227.225 180.053) scale(.00938)"
      />
      <path
        d="M4935.293 13370.064h420.765v310.634h-420.765v-310.634Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="translate(227.225 180.053) scale(.00938)"
      />
      <path
        d="m4595.258 13111.678 67.492-2.123 341.513 15.816-2.898 340.933-405.716 2.806-.391-357.432Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="translate(227.225 180.053) scale(.00938)"
      />
      <path
        d="M4206.558 13007.374h452.175v232.189h-452.175v-232.189Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="translate(227.225 180.053) scale(.00938)"
      />
      <path
        d="M3964.902 12766.441h339.804v243.771h-339.804v-243.771Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="translate(227.225 180.053) scale(.00938)"
      />
      <path
        d="M4151.806 12744.719h420.765v265.169h-420.765v-265.169Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="translate(227.225 180.053) scale(.00938)"
      />
      <path
        d="M3728.276 12515.515h491.051v253.375h-491.051v-253.375Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="translate(227.225 180.053) scale(.00938)"
      />
      <path
        d="M3488.063 12259.936h387.183v257.22h-387.183v-257.22Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="translate(227.225 180.053) scale(.00938)"
      />
      <path
        d="M3237.178 12044.034h284.041v243.771h-284.041v-243.771ZM6033 12753l-232-3-3-113-3-112-232-3-233-2v-230h-460v-230h-240v-230h-230v-230h-230v-240h-230v-230h-230v-460h-228l-7-235-7-235h-215l-6-172c-4-95-7-251-7-345v-173h-217l-6-182c-9-233-9-1052 0-1275l6-173h217v-172c0-95 3-251 7-345l6-173h215l7-235 7-235h228v-460h230v-230h230v-230h229l3-117 3-118 118-3 117-3v-229h460v-230h240v-230h460v-230h2550v230h690v230h240v230l228 2 227 3 3 113 3 112h229v240h230v230h228l7 230 7 230h217l3 118 3 117 111 3 112 3 7 229 8 230h214l6 173c4 94 7 355 7 580v407h230v293c0 162-3 369-7 460l-6 167h-217v293c0 160-3 370-7 465l-6 172h-215l-7 235-7 235h-216l-7 230-8 230h-227v230h-230v240h-230v230h-230v230h-240v230h-460v230l-232 2-233 3-3 111-3 111-691 6c-646 7-1300 6-1845 0zm1847-578v-115l348-2 347-3 3-117 3-118h459v-218l230-7 230-7v-228h228l7-230 7-230h228v-230h-240v-230h-220v-242l460 7 460 7v-472h230V7650h-230v-690h-230v-240h-228l-7-230-7-230h-228v-230h-230v-230h-460v-228l-230-7-230-7v-216l-224-7c-124-4-229-4-235 0-8 4-11 115-11 351v344h-230v230h230v230h-930v-230h240v-230h230v-460h-230v230h-240v-470h700v-230l-577 2-578 3-3 693-2 692h-460V5342l-224-7c-124-4-229-4-235 0-7 4-11 48-11 114v109l-230 7-230 7v216l-230 7-230 7v228h-230v230h-229l-3 118-3 117-111 3-112 3-7 229-8 230h-227v460h-230v2550h230v470h228l7 230 7 230h228v230h230v230h230v230h230v228l230 7 229 7 3 112 3 111 348 3 347 2v230h1620v-115z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="translate(227.225 180.053) scale(.00938)"
      />
      <path
        d="M6720 11940v-120h-230v-220h230v-470h229l3-117 3-118 113-3 112-3v-219h240v460h230v917l-172 6c-95 4-305 7-465 7h-293v-120zm-1272-347-118-4v-57c0-31 3-135 7-229l6-173h457v229l113 3 112 3 3 113 3 112h-63c-35 0-139 2-233 4-93 2-223 1-287-1zm3020 0-118-4v-218l113-3 112-3 3-117 3-118h229v230h230v227l-172 6c-95 4-198 6-228 5s-108-4-172-5zm-4061-1098c-4-96-4-254 0-350l6-175h444l6 173c4 94 7 200 7 235v62h-229l-3 113-3 112-111 3-111 3-6-176zm5323-1100v-115h-230v-240h473l-6-172c-4-95-7-199-7-230v-58h-461l3-117 3-118 112-3 112-3 3-112 3-112 233-3 232-2v230h230v940h-230v230h-470v-115zm-5560-350v-235h-230v-700h293c160 0 370 3 465 7l172 6v292c0 160-3 367-7 458l-6 167h-216l-3 118-3 117-232 3-233 2v-235zm460-580v-115h-220v230h220v-115zm2929.283 530.197-444.386-3.496-117-3-118-3v-459l-.72-233.222 240.72 3.222h220l219.549 4.292-.163 694.204Zm-219.386-353.496v-115h-220v230h220v-115ZM4170 7190v-230h470v460h-470v-230zm5560 173c0-32 3-136 7-230l6-173h227v220h230v240h-470v-57zM5560 6030v-230h460v460h-460v-230zm3010 173c0-32 3-136 7-230l6-173h457v460h-470v-57zm2058.052-1331.91h272.969v229.301h-272.969V4871.09Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="translate(227.225 180.053) scale(.00938)"
      />
      <path
        d="M10353.289 4641.886h318.567v229.301h-318.567v-229.301Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="translate(227.225 180.053) scale(.00938)"
      />
      <path
        d="M10095.234 4411.338h353.401v232.189h-353.401v-232.189Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="translate(227.225 180.053) scale(.00938)"
      />
      <path
        d="M9967.714 4170.405h251.248v243.771h-251.248v-243.771Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="translate(227.225 180.053) scale(.00938)"
      />
      <path
        d="M10669.657 12198.995h272.969v229.301h-272.969v-229.301Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="rotate(-90 305 91.916) scale(.00938)"
      />
      <path
        d="m10439.893 12418.57 43.785-1.428 221.555 10.637-1.88 229.301-263.206 1.887-.254-240.397Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="rotate(-90 302.81 94.021) scale(.00938)"
      />
      <path
        d="M10201.161 12660.126h293.346v232.189h-293.346v-232.189Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="rotate(-90 300.702 96.255) scale(.00938)"
      />
      <path
        d="M10002.467 12847.558h220.446v243.771h-220.446v-243.771Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="rotate(-90 298.496 98.068) scale(.00938)"
      />
      <path
        d="M9965.183 12689.344h272.969v265.169h-272.969v-265.169Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="rotate(-90 298.393 96.684) scale(.00938)"
      />
      <path
        d="M9707.285 12947.204h318.567v253.375h-318.567v-253.375Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="rotate(-90 296.187 99.047) scale(.00938)"
      />
      <path
        d="M9487.32 13134.81h251.183v257.22H9487.32v-257.22Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="rotate(-90 293.808 100.825) scale(.00938)"
      />
      <path
        d="M9298.149 13337.75h184.27v243.771h-184.27v-243.771Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="rotate(-90 291.72 102.666) scale(.00938)"
      />
    </g>
    <path
      d="M115.374 121.231h232.95l36.069 89.921h-40.948l-216.518-61.118-11.553-28.803Z"
      style={{
        stroke: '#000',
        fill: 'none',
      }}
      transform="rotate(-15.759 249.884 166.191)"
    />
    <g fill="#242424">
      <path
        d="m10900 12290-1515 1160h-115v230l-232 2-233 3-3 111-3 111-803 7c-442 4-1169 4-1615 0l-811-7v-227h-470v-230l-1632-1160h-228v-230h-230v-230h-230v-460h-230v-240h-230v-690h-228l-7-465-7-465h-218V7880h218l7-465 7-465h215l6-167c4-91 7-246 7-345v-178h230v-230h227l8-230 7-230h217l3-117 3-118 118-3 117-3v-229h230v-230h230v-230h230v-230h230v-228l235-7 235-7v-228h460v-230h-230 4640-240v230h460v230h240v230h115l817 700h228v230h230v240h230v460h230v230h230v173c0 94 3 249 7 344l6 172 111 3 111 3 3 462 2 462 113 3 112 3 3 813 2 812h-230l-2 463-3 462-112 3-113 3v163c0 89-3 245-7 344l-6 182h-227v230h-230v460h-230v240h-230v230h-230Zm-3020 810v-110h178c97 0 255-3 350-7l172-6v-214l173-6c94-4 250-7 345-7h172v-230h243l-5-115-5-115h457v-230h240v-240h230v-230h228l7-230 7-230h218v-230h228l7-235 7-235h228v-920h230V7660h-230v-177c0-98-3-256-7-350l-6-173h-215l-7-235-7-235h-228v-460h-230v-230h-230v-230h-230v-229l-117-3-118-3-3-117-3-118h-449v-230h-240v-230l-232-2-233-3-3-111-3-111-559-7c-307-4-984-4-1504 0l-946 7v227h-690v230h-460v230h-229l-3 118-3 117-117 3-118 3v229h-230v230h-230v460h-230v230h-230l-2 233-3 232-111 3-111 3-6 172c-4 95-7 250-7 345v172h-217l-6 173c-9 223-9 1521 0 1745l6 172h217v168c0 93 3 251 7 350l6 182h215l7 230 8 230h227v460h230v230h230v230h229l3 118 3 117 118 3 117 3v229h230v230h460v230h178c99 0 254 3 345 7l167 6v214l182 6c99 4 257 7 350 7h168v220h1390v-110Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          fill: '#000',
          paintOrder: 'stroke',
        }}
        transform="matrix(.00281 0 0 .0017 304.783 94.25)"
      />
      <path
        d="M4935.293 13370.064h420.765v310.634h-420.765v-310.634Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(.00281 0 0 .0017 304.783 94.25)"
      />
      <path
        d="m4595.258 13111.678 67.492-2.123 341.513 15.816-2.898 340.933-405.716 2.806-.391-357.432Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(.00281 0 0 .0017 304.783 94.25)"
      />
      <path
        d="M4206.558 13007.374h452.175v232.189h-452.175v-232.189Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(.00281 0 0 .0017 304.783 94.25)"
      />
      <path
        d="M3964.902 12766.441h339.804v243.771h-339.804v-243.771Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(.00281 0 0 .0017 304.783 94.25)"
      />
      <path
        d="M4151.806 12744.719h420.765v265.169h-420.765v-265.169Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(.00281 0 0 .0017 304.783 94.25)"
      />
      <path
        d="M3728.276 12515.515h491.051v253.375h-491.051v-253.375Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(.00281 0 0 .0017 304.783 94.25)"
      />
      <path
        d="M3488.063 12259.936h387.183v257.22h-387.183v-257.22Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(.00281 0 0 .0017 304.783 94.25)"
      />
      <path
        d="M3237.178 12044.034h284.041v243.771h-284.041v-243.771ZM6033 12753l-232-3-3-113-3-112-232-3-233-2v-230h-460v-230h-240v-230h-230v-230h-230v-240h-230v-230h-230v-460h-228l-7-235-7-235h-215l-6-172c-4-95-7-251-7-345v-173h-217l-6-182c-9-233-9-1052 0-1275l6-173h217v-172c0-95 3-251 7-345l6-173h215l7-235 7-235h228v-460h230v-230h230v-230h229l3-117 3-118 118-3 117-3v-229h460v-230h240v-230h460v-230h2550v230h690v230h240v230l228 2 227 3 3 113 3 112h229v240h230v230h228l7 230 7 230h217l3 118 3 117 111 3 112 3 7 229 8 230h214l6 173c4 94 7 355 7 580v407h230v293c0 162-3 369-7 460l-6 167h-217v293c0 160-3 370-7 465l-6 172h-215l-7 235-7 235h-216l-7 230-8 230h-227v230h-230v240h-230v230h-230v230h-240v230h-460v230l-232 2-233 3-3 111-3 111-691 6c-646 7-1300 6-1845 0zm1847-578v-115l348-2 347-3 3-117 3-118h459v-218l230-7 230-7v-228h228l7-230 7-230h228v-230h-240v-230h-220v-242l460 7 460 7v-472h230V7650h-230v-690h-230v-240h-228l-7-230-7-230h-228v-230h-230v-230h-460v-228l-230-7-230-7v-216l-224-7c-124-4-229-4-235 0-8 4-11 115-11 351v344h-230v230h230v230h-930v-230h240v-230h230v-460h-230v230h-240v-470h700v-230l-577 2-578 3-3 693-2 692h-460V5342l-224-7c-124-4-229-4-235 0-7 4-11 48-11 114v109l-230 7-230 7v216l-230 7-230 7v228h-230v230h-229l-3 118-3 117-111 3-112 3-7 229-8 230h-227v460h-230v2550h230v470h228l7 230 7 230h228v230h230v230h230v230h230v228l230 7 229 7 3 112 3 111 348 3 347 2v230h1620v-115z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(.00281 0 0 .0017 304.783 94.25)"
      />
      <path
        d="M6720 11940v-120h-230v-220h230v-470h229l3-117 3-118 113-3 112-3v-219h240v460h230v917l-172 6c-95 4-305 7-465 7h-293v-120zm-1272-347-118-4v-57c0-31 3-135 7-229l6-173h457v229l113 3 112 3 3 113 3 112h-63c-35 0-139 2-233 4-93 2-223 1-287-1zm3020 0-118-4v-218l113-3 112-3 3-117 3-118h229v230h230v227l-172 6c-95 4-198 6-228 5s-108-4-172-5zm-4061-1098c-4-96-4-254 0-350l6-175h444l6 173c4 94 7 200 7 235v62h-229l-3 113-3 112-111 3-111 3-6-176zm5323-1100v-115h-230v-240h473l-6-172c-4-95-7-199-7-230v-58h-461l3-117 3-118 112-3 112-3 3-112 3-112 233-3 232-2v230h230v940h-230v230h-470v-115zm-5560-350v-235h-230v-700h293c160 0 370 3 465 7l172 6v292c0 160-3 367-7 458l-6 167h-216l-3 118-3 117-232 3-233 2v-235zm460-580v-115h-220v230h220v-115zm2929.283 530.197-444.386-3.496-117-3-118-3v-459l-.72-233.222 240.72 3.222h220l219.549 4.292-.163 694.204Zm-219.386-353.496v-115h-220v230h220v-115ZM4170 7190v-230h470v460h-470v-230zm5560 173c0-32 3-136 7-230l6-173h227v220h230v240h-470v-57zM5560 6030v-230h460v460h-460v-230zm3010 173c0-32 3-136 7-230l6-173h457v460h-470v-57zm2058.052-1331.91h272.969v229.301h-272.969V4871.09Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(.00281 0 0 .0017 304.783 94.25)"
      />
      <path
        d="M10353.289 4641.886h318.567v229.301h-318.567v-229.301Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(.00281 0 0 .0017 304.783 94.25)"
      />
      <path
        d="M10095.234 4411.338h353.401v232.189h-353.401v-232.189Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(.00281 0 0 .0017 304.783 94.25)"
      />
      <path
        d="M9967.714 4170.405h251.248v243.771h-251.248v-243.771Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(.00281 0 0 .0017 304.783 94.25)"
      />
      <path
        d="M10669.657 12198.995h272.969v229.301h-272.969v-229.301Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(0 -.0017 .00281 0 300.547 133.554)"
      />
      <path
        d="m10439.893 12418.57 43.785-1.428 221.555 10.637-1.88 229.301-263.206 1.887-.254-240.397Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(0 -.0017 .00281 0 299.26 133.539)"
      />
      <path
        d="M10201.161 12660.126h293.346v232.189h-293.346v-232.189Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(0 -.0017 .00281 0 297.96 133.561)"
      />
      <path
        d="M10002.467 12847.558h220.446v243.771h-220.446v-243.771Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(0 -.0017 .00281 0 296.756 133.49)"
      />
      <path
        d="M9965.183 12689.344h272.969v265.169h-272.969v-265.169Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(0 -.0017 .00281 0 297.14 133.22)"
      />
      <path
        d="M9707.285 12947.204h318.567v253.375h-318.567v-253.375Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(0 -.0017 .00281 0 295.77 133.25)"
      />
      <path
        d="M9487.32 13134.81h251.183v257.22H9487.32v-257.22Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(0 -.0017 .00281 0 294.526 133.14)"
      />
      <path
        d="M9298.149 13337.75h184.27v243.771h-184.27v-243.771Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(0 -.0017 .00281 0 293.349 133.095)"
      />
    </g>
    <g fill="#242424">
      <path
        d="m10900 12290-1515 1160h-115v230l-232 2-233 3-3 111-3 111-803 7c-442 4-1169 4-1615 0l-811-7v-227h-470v-230l-1632-1160h-228v-230h-230v-230h-230v-460h-230v-240h-230v-690h-228l-7-465-7-465h-218V7880h218l7-465 7-465h215l6-167c4-91 7-246 7-345v-178h230v-230h227l8-230 7-230h217l3-117 3-118 118-3 117-3v-229h230v-230h230v-230h230v-230h230v-228l235-7 235-7v-228h460v-230h-230 4640-240v230h460v230h240v230h115l817 700h228v230h230v240h230v460h230v230h230v173c0 94 3 249 7 344l6 172 111 3 111 3 3 462 2 462 113 3 112 3 3 813 2 812h-230l-2 463-3 462-112 3-113 3v163c0 89-3 245-7 344l-6 182h-227v230h-230v460h-230v240h-230v230h-230Zm-3020 810v-110h178c97 0 255-3 350-7l172-6v-214l173-6c94-4 250-7 345-7h172v-230h243l-5-115-5-115h457v-230h240v-240h230v-230h228l7-230 7-230h218v-230h228l7-235 7-235h228v-920h230V7660h-230v-177c0-98-3-256-7-350l-6-173h-215l-7-235-7-235h-228v-460h-230v-230h-230v-230h-230v-229l-117-3-118-3-3-117-3-118h-449v-230h-240v-230l-232-2-233-3-3-111-3-111-559-7c-307-4-984-4-1504 0l-946 7v227h-690v230h-460v230h-229l-3 118-3 117-117 3-118 3v229h-230v230h-230v460h-230v230h-230l-2 233-3 232-111 3-111 3-6 172c-4 95-7 250-7 345v172h-217l-6 173c-9 223-9 1521 0 1745l6 172h217v168c0 93 3 251 7 350l6 182h215l7 230 8 230h227v460h230v230h230v230h229l3 118 3 117 118 3 117 3v229h230v230h460v230h178c99 0 254 3 345 7l167 6v214l182 6c99 4 257 7 350 7h168v220h1390v-110Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          fill: '#000',
          paintOrder: 'stroke',
        }}
        transform="matrix(.00281 0 0 .0017 350.188 153.543)"
      />
      <path
        d="M4935.293 13370.064h420.765v310.634h-420.765v-310.634Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(.00281 0 0 .0017 350.188 153.543)"
      />
      <path
        d="m4595.258 13111.678 67.492-2.123 341.513 15.816-2.898 340.933-405.716 2.806-.391-357.432Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(.00281 0 0 .0017 350.188 153.543)"
      />
      <path
        d="M4206.558 13007.374h452.175v232.189h-452.175v-232.189Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(.00281 0 0 .0017 350.188 153.543)"
      />
      <path
        d="M3964.902 12766.441h339.804v243.771h-339.804v-243.771Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(.00281 0 0 .0017 350.188 153.543)"
      />
      <path
        d="M4151.806 12744.719h420.765v265.169h-420.765v-265.169Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(.00281 0 0 .0017 350.188 153.543)"
      />
      <path
        d="M3728.276 12515.515h491.051v253.375h-491.051v-253.375Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(.00281 0 0 .0017 350.188 153.543)"
      />
      <path
        d="M3488.063 12259.936h387.183v257.22h-387.183v-257.22Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(.00281 0 0 .0017 350.188 153.543)"
      />
      <path
        d="M3237.178 12044.034h284.041v243.771h-284.041v-243.771ZM6033 12753l-232-3-3-113-3-112-232-3-233-2v-230h-460v-230h-240v-230h-230v-230h-230v-240h-230v-230h-230v-460h-228l-7-235-7-235h-215l-6-172c-4-95-7-251-7-345v-173h-217l-6-182c-9-233-9-1052 0-1275l6-173h217v-172c0-95 3-251 7-345l6-173h215l7-235 7-235h228v-460h230v-230h230v-230h229l3-117 3-118 118-3 117-3v-229h460v-230h240v-230h460v-230h2550v230h690v230h240v230l228 2 227 3 3 113 3 112h229v240h230v230h228l7 230 7 230h217l3 118 3 117 111 3 112 3 7 229 8 230h214l6 173c4 94 7 355 7 580v407h230v293c0 162-3 369-7 460l-6 167h-217v293c0 160-3 370-7 465l-6 172h-215l-7 235-7 235h-216l-7 230-8 230h-227v230h-230v240h-230v230h-230v230h-240v230h-460v230l-232 2-233 3-3 111-3 111-691 6c-646 7-1300 6-1845 0zm1847-578v-115l348-2 347-3 3-117 3-118h459v-218l230-7 230-7v-228h228l7-230 7-230h228v-230h-240v-230h-220v-242l460 7 460 7v-472h230V7650h-230v-690h-230v-240h-228l-7-230-7-230h-228v-230h-230v-230h-460v-228l-230-7-230-7v-216l-224-7c-124-4-229-4-235 0-8 4-11 115-11 351v344h-230v230h230v230h-930v-230h240v-230h230v-460h-230v230h-240v-470h700v-230l-577 2-578 3-3 693-2 692h-460V5342l-224-7c-124-4-229-4-235 0-7 4-11 48-11 114v109l-230 7-230 7v216l-230 7-230 7v228h-230v230h-229l-3 118-3 117-111 3-112 3-7 229-8 230h-227v460h-230v2550h230v470h228l7 230 7 230h228v230h230v230h230v230h230v228l230 7 229 7 3 112 3 111 348 3 347 2v230h1620v-115z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(.00281 0 0 .0017 350.188 153.543)"
      />
      <path
        d="M6720 11940v-120h-230v-220h230v-470h229l3-117 3-118 113-3 112-3v-219h240v460h230v917l-172 6c-95 4-305 7-465 7h-293v-120zm-1272-347-118-4v-57c0-31 3-135 7-229l6-173h457v229l113 3 112 3 3 113 3 112h-63c-35 0-139 2-233 4-93 2-223 1-287-1zm3020 0-118-4v-218l113-3 112-3 3-117 3-118h229v230h230v227l-172 6c-95 4-198 6-228 5s-108-4-172-5zm-4061-1098c-4-96-4-254 0-350l6-175h444l6 173c4 94 7 200 7 235v62h-229l-3 113-3 112-111 3-111 3-6-176zm5323-1100v-115h-230v-240h473l-6-172c-4-95-7-199-7-230v-58h-461l3-117 3-118 112-3 112-3 3-112 3-112 233-3 232-2v230h230v940h-230v230h-470v-115zm-5560-350v-235h-230v-700h293c160 0 370 3 465 7l172 6v292c0 160-3 367-7 458l-6 167h-216l-3 118-3 117-232 3-233 2v-235zm460-580v-115h-220v230h220v-115zm2929.283 530.197-444.386-3.496-117-3-118-3v-459l-.72-233.222 240.72 3.222h220l219.549 4.292-.163 694.204Zm-219.386-353.496v-115h-220v230h220v-115ZM4170 7190v-230h470v460h-470v-230zm5560 173c0-32 3-136 7-230l6-173h227v220h230v240h-470v-57zM5560 6030v-230h460v460h-460v-230zm3010 173c0-32 3-136 7-230l6-173h457v460h-470v-57zm2058.052-1331.91h272.969v229.301h-272.969V4871.09Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(.00281 0 0 .0017 350.188 153.543)"
      />
      <path
        d="M10353.289 4641.886h318.567v229.301h-318.567v-229.301Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(.00281 0 0 .0017 350.188 153.543)"
      />
      <path
        d="M10095.234 4411.338h353.401v232.189h-353.401v-232.189Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(.00281 0 0 .0017 350.188 153.543)"
      />
      <path
        d="M9967.714 4170.405h251.248v243.771h-251.248v-243.771Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(.00281 0 0 .0017 350.188 153.543)"
      />
      <path
        d="M10669.657 12198.995h272.969v229.301h-272.969v-229.301Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(0 -.0017 .00281 0 345.952 192.846)"
      />
      <path
        d="m10439.893 12418.57 43.785-1.428 221.555 10.637-1.88 229.301-263.206 1.887-.254-240.397Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(0 -.0017 .00281 0 344.665 192.83)"
      />
      <path
        d="M10201.161 12660.126h293.346v232.189h-293.346v-232.189Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(0 -.0017 .00281 0 343.365 192.854)"
      />
      <path
        d="M10002.467 12847.558h220.446v243.771h-220.446v-243.771Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(0 -.0017 .00281 0 342.161 192.782)"
      />
      <path
        d="M9965.183 12689.344h272.969v265.169h-272.969v-265.169Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(0 -.0017 .00281 0 342.545 192.513)"
      />
      <path
        d="M9707.285 12947.204h318.567v253.375h-318.567v-253.375Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(0 -.0017 .00281 0 341.176 192.541)"
      />
      <path
        d="M9487.32 13134.81h251.183v257.22H9487.32v-257.22Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(0 -.0017 .00281 0 339.93 192.432)"
      />
      <path
        d="M9298.149 13337.75h184.27v243.771h-184.27v-243.771Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(0 -.0017 .00281 0 338.754 192.388)"
      />
    </g>
    <g fill="#242424">
      <path
        d="m10900 12290-1515 1160h-115v230l-232 2-233 3-3 111-3 111-803 7c-442 4-1169 4-1615 0l-811-7v-227h-470v-230l-1632-1160h-228v-230h-230v-230h-230v-460h-230v-240h-230v-690h-228l-7-465-7-465h-218V7880h218l7-465 7-465h215l6-167c4-91 7-246 7-345v-178h230v-230h227l8-230 7-230h217l3-117 3-118 118-3 117-3v-229h230v-230h230v-230h230v-230h230v-228l235-7 235-7v-228h460v-230h-230 4640-240v230h460v230h240v230h115l817 700h228v230h230v240h230v460h230v230h230v173c0 94 3 249 7 344l6 172 111 3 111 3 3 462 2 462 113 3 112 3 3 813 2 812h-230l-2 463-3 462-112 3-113 3v163c0 89-3 245-7 344l-6 182h-227v230h-230v460h-230v240h-230v230h-230Zm-3020 810v-110h178c97 0 255-3 350-7l172-6v-214l173-6c94-4 250-7 345-7h172v-230h243l-5-115-5-115h457v-230h240v-240h230v-230h228l7-230 7-230h218v-230h228l7-235 7-235h228v-920h230V7660h-230v-177c0-98-3-256-7-350l-6-173h-215l-7-235-7-235h-228v-460h-230v-230h-230v-230h-230v-229l-117-3-118-3-3-117-3-118h-449v-230h-240v-230l-232-2-233-3-3-111-3-111-559-7c-307-4-984-4-1504 0l-946 7v227h-690v230h-460v230h-229l-3 118-3 117-117 3-118 3v229h-230v230h-230v460h-230v230h-230l-2 233-3 232-111 3-111 3-6 172c-4 95-7 250-7 345v172h-217l-6 173c-9 223-9 1521 0 1745l6 172h217v168c0 93 3 251 7 350l6 182h215l7 230 8 230h227v460h230v230h230v230h229l3 118 3 117 118 3 117 3v229h230v230h460v230h178c99 0 254 3 345 7l167 6v214l182 6c99 4 257 7 350 7h168v220h1390v-110Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          fill: '#000',
          paintOrder: 'stroke',
        }}
        transform="matrix(.00281 0 0 .0017 109.793 149.515)"
      />
      <path
        d="M4935.293 13370.064h420.765v310.634h-420.765v-310.634Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(.00281 0 0 .0017 109.793 149.515)"
      />
      <path
        d="m4595.258 13111.678 67.492-2.123 341.513 15.816-2.898 340.933-405.716 2.806-.391-357.432Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(.00281 0 0 .0017 109.793 149.515)"
      />
      <path
        d="M4206.558 13007.374h452.175v232.189h-452.175v-232.189Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(.00281 0 0 .0017 109.793 149.515)"
      />
      <path
        d="M3964.902 12766.441h339.804v243.771h-339.804v-243.771Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(.00281 0 0 .0017 109.793 149.515)"
      />
      <path
        d="M4151.806 12744.719h420.765v265.169h-420.765v-265.169Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(.00281 0 0 .0017 109.793 149.515)"
      />
      <path
        d="M3728.276 12515.515h491.051v253.375h-491.051v-253.375Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(.00281 0 0 .0017 109.793 149.515)"
      />
      <path
        d="M3488.063 12259.936h387.183v257.22h-387.183v-257.22Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(.00281 0 0 .0017 109.793 149.515)"
      />
      <path
        d="M3237.178 12044.034h284.041v243.771h-284.041v-243.771ZM6033 12753l-232-3-3-113-3-112-232-3-233-2v-230h-460v-230h-240v-230h-230v-230h-230v-240h-230v-230h-230v-460h-228l-7-235-7-235h-215l-6-172c-4-95-7-251-7-345v-173h-217l-6-182c-9-233-9-1052 0-1275l6-173h217v-172c0-95 3-251 7-345l6-173h215l7-235 7-235h228v-460h230v-230h230v-230h229l3-117 3-118 118-3 117-3v-229h460v-230h240v-230h460v-230h2550v230h690v230h240v230l228 2 227 3 3 113 3 112h229v240h230v230h228l7 230 7 230h217l3 118 3 117 111 3 112 3 7 229 8 230h214l6 173c4 94 7 355 7 580v407h230v293c0 162-3 369-7 460l-6 167h-217v293c0 160-3 370-7 465l-6 172h-215l-7 235-7 235h-216l-7 230-8 230h-227v230h-230v240h-230v230h-230v230h-240v230h-460v230l-232 2-233 3-3 111-3 111-691 6c-646 7-1300 6-1845 0zm1847-578v-115l348-2 347-3 3-117 3-118h459v-218l230-7 230-7v-228h228l7-230 7-230h228v-230h-240v-230h-220v-242l460 7 460 7v-472h230V7650h-230v-690h-230v-240h-228l-7-230-7-230h-228v-230h-230v-230h-460v-228l-230-7-230-7v-216l-224-7c-124-4-229-4-235 0-8 4-11 115-11 351v344h-230v230h230v230h-930v-230h240v-230h230v-460h-230v230h-240v-470h700v-230l-577 2-578 3-3 693-2 692h-460V5342l-224-7c-124-4-229-4-235 0-7 4-11 48-11 114v109l-230 7-230 7v216l-230 7-230 7v228h-230v230h-229l-3 118-3 117-111 3-112 3-7 229-8 230h-227v460h-230v2550h230v470h228l7 230 7 230h228v230h230v230h230v230h230v228l230 7 229 7 3 112 3 111 348 3 347 2v230h1620v-115z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(.00281 0 0 .0017 109.793 149.515)"
      />
      <path
        d="M6720 11940v-120h-230v-220h230v-470h229l3-117 3-118 113-3 112-3v-219h240v460h230v917l-172 6c-95 4-305 7-465 7h-293v-120zm-1272-347-118-4v-57c0-31 3-135 7-229l6-173h457v229l113 3 112 3 3 113 3 112h-63c-35 0-139 2-233 4-93 2-223 1-287-1zm3020 0-118-4v-218l113-3 112-3 3-117 3-118h229v230h230v227l-172 6c-95 4-198 6-228 5s-108-4-172-5zm-4061-1098c-4-96-4-254 0-350l6-175h444l6 173c4 94 7 200 7 235v62h-229l-3 113-3 112-111 3-111 3-6-176zm5323-1100v-115h-230v-240h473l-6-172c-4-95-7-199-7-230v-58h-461l3-117 3-118 112-3 112-3 3-112 3-112 233-3 232-2v230h230v940h-230v230h-470v-115zm-5560-350v-235h-230v-700h293c160 0 370 3 465 7l172 6v292c0 160-3 367-7 458l-6 167h-216l-3 118-3 117-232 3-233 2v-235zm460-580v-115h-220v230h220v-115zm2929.283 530.197-444.386-3.496-117-3-118-3v-459l-.72-233.222 240.72 3.222h220l219.549 4.292-.163 694.204Zm-219.386-353.496v-115h-220v230h220v-115ZM4170 7190v-230h470v460h-470v-230zm5560 173c0-32 3-136 7-230l6-173h227v220h230v240h-470v-57zM5560 6030v-230h460v460h-460v-230zm3010 173c0-32 3-136 7-230l6-173h457v460h-470v-57zm2058.052-1331.91h272.969v229.301h-272.969V4871.09Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(.00281 0 0 .0017 109.793 149.515)"
      />
      <path
        d="M10353.289 4641.886h318.567v229.301h-318.567v-229.301Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(.00281 0 0 .0017 109.793 149.515)"
      />
      <path
        d="M10095.234 4411.338h353.401v232.189h-353.401v-232.189Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(.00281 0 0 .0017 109.793 149.515)"
      />
      <path
        d="M9967.714 4170.405h251.248v243.771h-251.248v-243.771Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(.00281 0 0 .0017 109.793 149.515)"
      />
      <path
        d="M10669.657 12198.995h272.969v229.301h-272.969v-229.301Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(0 -.0017 .00281 0 105.557 188.818)"
      />
      <path
        d="m10439.893 12418.57 43.785-1.428 221.555 10.637-1.88 229.301-263.206 1.887-.254-240.397Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(0 -.0017 .00281 0 104.27 188.803)"
      />
      <path
        d="M10201.161 12660.126h293.346v232.189h-293.346v-232.189Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(0 -.0017 .00281 0 102.97 188.826)"
      />
      <path
        d="M10002.467 12847.558h220.446v243.771h-220.446v-243.771Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(0 -.0017 .00281 0 101.766 188.754)"
      />
      <path
        d="M9965.183 12689.344h272.969v265.169h-272.969v-265.169Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(0 -.0017 .00281 0 102.15 188.485)"
      />
      <path
        d="M9707.285 12947.204h318.567v253.375h-318.567v-253.375Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(0 -.0017 .00281 0 100.78 188.513)"
      />
      <path
        d="M9487.32 13134.81h251.183v257.22H9487.32v-257.22Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(0 -.0017 .00281 0 99.536 188.404)"
      />
      <path
        d="M9298.149 13337.75h184.27v243.771h-184.27v-243.771Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(0 -.0017 .00281 0 98.359 188.36)"
      />
    </g>
  </svg>
);

export default TimeWalletIcon;
