import * as React from 'react';
const TombstoneIcon = (props: any) => (
  <svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <path d="M219.187 120.07h56.204v5.47h-56.204v-5.47ZM214.077 125.54h5.11v5.469h-5.11v-5.47ZM275.391 125.54h5.11v5.469h-5.11v-5.47ZM214.077 196.64h5.11v5.47h-5.11v-5.47ZM275.391 196.64h5.11v5.47h-5.11v-5.47ZM219.187 202.11h56.204v5.47h-56.204v-5.47ZM219.187 131.009v10.939h5.11v-5.47h45.984v5.47h5.11v-10.939h-56.204ZM265.171 141.948h5.11v5.47h-5.11v-5.47Z" />
      <path d="M260.064 147.416h5.11v5.469h-5.11v-5.47ZM254.954 152.885h5.11v5.47h-5.11v-5.47ZM224.297 141.948h5.11v5.47h-5.11v-5.47ZM265.171 180.234h5.11v5.47h-5.11v-5.47ZM224.297 180.234h5.11v5.47h-5.11v-5.47ZM229.407 147.416h5.11v5.469h-5.11v-5.47ZM234.515 152.885h5.11v5.47h-5.11v-5.47ZM260.064 174.764h5.11v5.47h-5.11v-5.47ZM229.407 174.764h5.11v5.47h-5.11v-5.47ZM254.954 169.294h5.11v5.47h-5.11v-5.47ZM249.844 158.355h5.11v10.939h-5.11v-10.939ZM234.515 169.294h5.11v5.47h-5.11v-5.47ZM239.624 158.355h5.11v10.939h-5.11v-10.939Z" />
      <path d="M270.281 185.7v5.47h-10.218v-5.47h-5.11v-5.468h-5.11v-5.469h-5.109v5.47h-5.11v5.468h-5.11v5.47h-10.218v-5.47h-5.11v10.939h56.205V185.7h-5.11Z" />
    </g>
    <path d="M315.015 248.222h-140V224.89h140v23.332Zm-163.33 46.668h186.67v-23.332h-186.67v23.332Zm0 46.668h186.67v-23.332l-186.67-.004v23.336Zm0 46.664h186.67V364.89h-186.67v23.332Zm221.66-229.66h11.668v268.33h-11.668v11.668h-256.66v-11.668h-11.668v-268.33h11.668V135.23h11.668v-11.668h11.668v-11.668h11.668v-11.668h11.668V88.558h11.668l-.008-11.664h23.332V65.226h93.332v11.668h23.332v11.668h11.668v11.668h11.668v11.668h11.668v11.668h11.668v11.668h11.668l-.008 23.328Zm-11.664 0h-11.668v-11.668h-11.668v-11.668h-11.668v-11.668h-11.668V111.89H303.34v-11.668h-11.668V88.554H198.34v11.668h-11.668v11.668h-11.668v11.668h-11.668v11.668h-11.668v11.668H140v11.668h-11.668v256.67h233.33l.018-256.67Zm70-11.668v326.67h-11.668v11.668l-350-.004V473.56l-11.668.004v-326.67h11.668v-23.332H81.68V100.23h11.668V88.562h11.668l-.004-11.668h11.668V65.226h11.668l-.004-11.664h11.668V41.894h23.332V30.226h23.332l.004-11.664h116.67V30.23h23.332v11.668h23.332v11.668h11.668v11.668h11.668v11.668h11.668V88.57h11.668v11.668h11.668v23.332h11.668v23.332l11.658-.008Zm-23.336 0h-11.668v-23.332h-11.668v-11.668H373.34v-11.668h-11.668V88.558h-11.668l.008-11.664h-11.668V65.226h-11.668l.004-11.664h-23.332l-.004-11.668h-116.66v11.668h-23.332V65.23h-11.668v11.668h-11.668v11.668h-11.668v11.668H116.68v11.668h-11.668v11.668H93.345v23.332H81.677v315h326.67l-.002-315.008Z" />
  </svg>
);
export default TombstoneIcon;
