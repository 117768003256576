import React, { useRef, useContext } from 'react';
import { auth } from '../firebaseServices';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { Button, Col, Row, Container, Form, Navbar } from 'react-bootstrap';
import { AuthContext, MyContext } from '../context/AuthContext';
import { DateTime } from 'luxon';
import { Alert } from '@mui/material';
import TimeDuration from '../TimeDuration/index';

function LoginPanel() {
  const user = useContext<MyContext>(AuthContext).user;
  const userRecord = useContext<MyContext>(AuthContext).userRecord;
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const [loginError, setLoginError] = React.useState<string>();
  const signIn = async () => {
    try {
      setLoginError(undefined);
      await signInWithEmailAndPassword(
        auth,
        emailRef.current!.value,
        passwordRef.current!.value,
      );
    } catch (error) {
      console.error(error);
      const message: string = error.message
        .replace('Firebase: Error (auth/', '')
        .replace(').', '')
        .replace(/-/g, ' ');
      if (message !== 'internal error') setLoginError(message);
      else {
        if (passwordRef.current!.value.length === 0)
          setLoginError('Please enter a password');
        if (emailRef.current!.value.length === 0)
          setLoginError('Please enter your email address');
      }
    }
  };

  const signOut = async () => {
    await auth.signOut();
  };
  return (
    <>
      <Navbar className="justify-content-between" bg="dark" variant="dark">
        {loginError && <Alert severity="error">{loginError}</Alert>}
        <Navbar.Brand>Login</Navbar.Brand>
        {user && <Button onClick={signOut}>Sign Out</Button>}
        {userRecord && (
          <Container fluid>
            {userRecord.username}
            <TimeDuration
              jsDate={DateTime.fromSeconds(
                userRecord.timeWallet.seconds + DateTime.now().toSeconds(),
              ).toJSDate()}
            />
          </Container>
        )}
      </Navbar>
      {!user ? (
        <Container style={{ maxWidth: '500px' }} fluid>
          <Form className="mt-4">
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control ref={emailRef} type="email" placeholder="email" />
            </Form.Group>
            <Form.Group controlId="formPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                ref={passwordRef}
                type="password"
                placeholder="password"
              />
            </Form.Group>

            <Row>
              <Col xs={6}>
                <Button type="button" onClick={signIn} variant="secondary">
                  Sign In
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
      ) : (
        <Container style={{ maxWidth: '500px' }} fluid>
          {!userRecord ? (
            <h2 className="mt-4 text-center"> Welcome {user.email}</h2>
          ) : (
            <h2 className="mt-4 text-center">
              {' '}
              Welcome {userRecord.username} You have{' '}
              <TimeDuration
                jsDate={DateTime.fromSeconds(
                  userRecord.timeWallet.seconds + DateTime.now().toSeconds(),
                ).toJSDate()}
              />
              left in your wallet
              <br></br>
            </h2>
          )}
        </Container>
      )}
    </>
  );
}

export default LoginPanel;
