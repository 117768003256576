import React, { useEffect, useState, useContext } from 'react';

import {
  Stack,
  Select,
  Box,
  SelectChangeEvent,
  MenuItem,
  FormControl,
} from '@mui/material';
import TimeDisplay from '../TimeDisplay/index';
import { Link } from 'react-router-dom';

import { VoteData } from '../model/types';
import { CacheContext, CacheContextType } from '../context/CacheContext';
import AvatarIcon from '../resource/AvatarIcon';
import { DateTime } from 'luxon';
import TimeDuration from '../TimeDuration';
function VotesList({
  isPost,
  recordID,
  showMenu,
}: {
  isPost: boolean;
  recordID: string;
  showMenu?: boolean;
}) {
  const [votes, setVotes] = useState<VoteData[] | undefined>();
  const [feedType, setFeedType] = useState('votesBy');

  const getUserRecord =
    useContext<CacheContextType>(CacheContext).getUserRecord;
  const getVotes = useContext<CacheContextType>(CacheContext).allVotes;
  useEffect(() => {
    if (!recordID) return;
    let snapshotVotes: VoteData[] = getVotes();
    snapshotVotes = snapshotVotes.filter((vote: VoteData) => {
      if (vote.createdAt === undefined) return false;
      if (isPost) return vote.parentID === recordID;
      else if (feedType === 'votesFor') {
        return vote.userRecordID === recordID;
      } else if (feedType === 'votesBy') {
        return vote.userRecordID === recordID;
      }
      return false;
    });

    setVotes(snapshotVotes);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordID, getVotes]);

  const handleChange = (event: SelectChangeEvent) => {
    setFeedType(event.target.value as string);
  };
  if (!recordID || !votes || votes === undefined)
    return (
      <Box
        sx={{
          color: 'text.primary',
          boxSizing: 'border-box',
          background: 'white',
          margin: '0 auto',
          borderRadius: '3px',
          padding: 1,
          width: '100%',
        }}
      >
        {' '}
        No votes yet
      </Box>
    );
  return (
    <Box
      sx={{
        boxSizing: 'border-box',
        background: 'white',
        margin: '0 auto',
        borderRadius: '3px',
        padding: 1,
        width: '100%',
      }}
    >
      {showMenu && (
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={feedType}
            label="Feed Type"
            onChange={handleChange}
            sx={{
              backgroundColor: 'white',
            }}
          >
            <MenuItem value={'votesBy'}>Votes By</MenuItem>
            <MenuItem value={'votesFor'}>Votes For</MenuItem>
          </Select>
        </FormControl>
      )}
      <Stack direction="column" spacing={2}>
        {votes
          .sort((a: any, b: any) =>
            isPost
              ? a.createdAt < b.createdAt
                ? -1
                : a.createdAt > b.createdAt
                ? 1
                : 0
              : a.createdAt < b.createdAt
              ? 1
              : a.createdAt > b.createdAt
              ? -1
              : 0,
          )
          .map((vote: any) => {
            const voteUserRecord = getUserRecord(vote.userRecordID);
            const createdDate = DateTime.fromSeconds(
              vote.createdAt.seconds,
            ).toJSDate();
            return (
              <Stack direction="row" spacing={1} key={vote.id}>
                <AvatarIcon />
                <Stack direction="column">
                  <Stack direction="row" spacing={0.5}>
                    <Box
                      sx={{
                        color: 'rgb(83, 100, 113)',
                        fontSize: '16px',
                        fontWeight: 400,
                      }}
                    >
                      @
                      {(
                        <Link to={'/' + voteUserRecord?.username}>
                          {voteUserRecord?.username}
                        </Link>
                      ) || ''}
                    </Box>

                    {vote.madePermanent ? (
                      <Box
                        sx={{
                          color: 'rgb(83, 100, 113)',
                          fontSize: '17px',

                          fontWeight: 1000,
                        }}
                      >
                        MADE THE{' '}
                        <Link to={'/' + vote.objectType + '/' + vote.parentID}>
                          {vote.objectType.slice(0, -1).toUpperCase()}
                        </Link>{' '}
                        PERMANENT
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          color: 'rgb(83, 100, 113)',
                          fontSize: '16px',
                          fontWeight: 400,
                        }}
                      >
                        {vote.killed
                          ? 'KILLED the '
                          : vote.revived
                          ? 'REVIVED the '
                          : 'voted on the '}
                        <Link to={'/' + vote.objectType + '/' + vote.parentID}>
                          {vote.objectType.slice(0, -1)}
                        </Link>
                        , {vote.timeValue >= 0 ? 'adding ' : 'removing '}
                        <TimeDisplay timeValue={vote.timeValue * 1000} />{' '}
                      </Box>
                    )}
                  </Stack>
                  <Stack direction="row" spacing={0.5}>
                    <TimeDuration
                      jsDate={createdDate}
                      invertSign={true}
                      titleDisplay="absoluteTime"
                    />{' '}
                    <Box>ago</Box>
                  </Stack>
                </Stack>
              </Stack>
            );
          })}
      </Stack>
    </Box>
  );
}

export default VotesList;
