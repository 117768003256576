import React from 'react';
import './App.css';

import { ThemeProvider, createTheme } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import './fonts.css';
import { AuthProvider } from './provider/AuthProvider';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import UserPage from './userPageComponent/userPageComponent';
import PostPage from './postComponent/PostPageComponent';
import FollowPage from './Follows/FollowPage';
import Nav from './NavBarComponent/NavBar';
import LoginPanel from './LoginPage/loginComponent';
import SignUpPage from './LoginPage/SignUpPage';
import SettingsPage from './SettingsPage/SettingsPage';
//import PushNotifications from './NotificationComponent/PushNotifications';
import NotificationsList from './NotificationComponent/NotificationsList';
import FeedPage from './FeedComponent/FeedPage';
import { CacheProvider } from './provider/CacheProvider';
import FeedsListPage from './FeedComponent/FeedsListPage';
import LeaderboardPage from './Leaderboard/LeaderboardPage';
import WelcomePage from './LoginPage/WelcomePage';
import AboutPage from './LoginPage/AboutPage';
import HomePage from './HomePage';
const router = createBrowserRouter([
  {
    element: <Nav />,
    children: [
      {
        path: '/',
        element: <HomePage />,
        errorElement: <div>404</div>,
      },
      {
        path: '/list/feeds',
        element: <FeedsListPage />,
      },
      {
        path: '/leaderboard',
        element: <LeaderboardPage />,
      },
      // {
      //   path: '/howtowipe',
      //   element: <TutorialPage />,
      // },
      {
        path: '/about',
        element: <AboutPage />,
      },
      {
        path: '/welcome',
        element: <WelcomePage />,
      },
      {
        path: '/*',
        element: <div>404</div>,
      },
      {
        path: '/posts/:postId',
        element: <PostPage />,
      },
      {
        path: '/follows/:followId',
        element: <FollowPage />,
      },
      {
        path: '/feeds/:feedId',
        element: <FeedPage />,
      },
      {
        path: '/login',
        element: <LoginPanel />,
      },
      {
        path: '/signup',
        element: <SignUpPage />,
      },
      {
        path: '/settings',
        element: <SettingsPage />,
      },
      {
        path: '/notifications',
        element: <NotificationsList />,
      },
      {
        path: '/:username',
        element: <UserPage />,
      },
    ],
  },
]);

function App() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('./firebase-messaging-sw.js')
      .then(function (registration) {
        console.log('Registration successful, scope is:', registration.scope);
      })
      .catch(function (err) {
        console.log('Service worker registration failed, error:', err);
      });
  }

  const theme = createTheme({
    palette: {
      primary: {
        main: '#000',
      },
      secondary: {
        main: blueGrey[500],
      },
    },
    components: {
      // Name of the component ⚛️
      MuiButtonBase: {
        defaultProps: {
          // The props to apply
          disableRipple: true, // No more ripple, on the whole application 💣!
        },
      },
    },
  });
  // <PushNotifications />
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <CacheProvider>
          <RouterProvider router={router} />
          <Outlet />
        </CacheProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
