import React from 'react';
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Box } from '@mui/material';
import ChangingProgressProvider from '../HealthBar/ChangingProgressProvider';
import { DateTime } from 'luxon';

function HealthBar({
  objectRecord,
  children,
}: {
  objectRecord: any;
  children: any;
}) {
  const timePercentage = (objectRecord: any) => {
    const createdSeconds =
      objectRecord.createdAt.seconds ?? objectRecord.createdAt._seconds;
    const expireSeconds =
      objectRecord.expireAt.seconds ?? objectRecord.expireAt._seconds;
    const totalTime = expireSeconds - createdSeconds;
    const timeLeft = expireSeconds - DateTime.now().toSeconds();
    return Math.round((timeLeft / totalTime) * 100);
  };

  function pickHex(weight: number) {
    const color1 = [22, 168, 27];
    const color2 = [181, 30, 27];
    var w1 = weight;
    var w2 = 1 - w1;
    var rgb = [
      Math.round(color1[0] * w1 + color2[0] * w2),
      Math.round(color1[1] * w1 + color2[1] * w2),
      Math.round(color1[2] * w1 + color2[2] * w2),
    ];
    return rgb;
  }

  return (
    <Box
      sx={{
        background: 'white',
        borderRadius: '3px',
        padding: 1,
        width: 85,
        height: 85,
      }}
    >
      <ChangingProgressProvider values={[...Array(101).keys()]}>
        {(percentage) => (
          <CircularProgressbarWithChildren
            value={timePercentage(objectRecord)}
            styles={buildStyles({
              pathColor: `rgb(${pickHex(
                timePercentage(objectRecord) / 100,
              ).join(',')})`,
            })}
          >
            {children}
          </CircularProgressbarWithChildren>
        )}
      </ChangingProgressProvider>
    </Box>
  );
}
export default HealthBar;
