import React, { useContext, useEffect } from 'react';
import { AuthContext, MyContext } from '../context/AuthContext';
import {
  Box,
  Button,
  Stack,
  FormControl,
  InputLabel,
  Input,
  Divider,
} from '@mui/material';
import { db } from '../firebaseServices';
import { doc, updateDoc } from 'firebase/firestore';
function SettingsPage() {
  const userRecord = useContext<MyContext>(AuthContext).userRecord;
  const [state, setState] = React.useState<any>({
    bio: '',
    postDays: 1,
    postHours: 0,
    postMinutes: 0,
    voteDays: 0,
    voteHours: 1,
    voteMinutes: 0,
    followDays: 5,
    followHours: 0,
    followMinutes: 0,
  });

  useEffect(() => {
    if (!userRecord) return;
    setState({
      bio: userRecord.bio ?? '',
      postDays: userRecord.userSettings.postDays,
      postHours: userRecord.userSettings.postHours,
      postMinutes: userRecord.userSettings.postMinutes,
      voteDays: userRecord.userSettings.voteDays,
      voteHours: userRecord.userSettings.voteHours,
      voteMinutes: userRecord.userSettings.voteMinutes,
      followDays: userRecord.userSettings.followDays,
      followHours: userRecord.userSettings.followHours,
      followMinutes: userRecord.userSettings.followMinutes,
    });
  }, [userRecord]);
  const handleChange = (event: any) => {
    if (event.target.value < 0) return;
    const target = event.target;
    const name = target.name;
    setState({ ...state, [name]: event.target.value });
  };
  const handleSubmit = () => {
    const userRef = doc(db, 'users', userRecord?.id || '');
    updateDoc(userRef, {
      bio: state.bio,
      userSettings: state,
    });
  };
  return (
    <Box>
      <Stack direction="column" spacing={3}>
        {userRecord && userRecord.username} User Settings
        <Divider />
        <form>
          <Stack direction="column" spacing={3}>
            <Box>User Bio</Box>

            <FormControl>
              <InputLabel htmlFor="bio">User Bio</InputLabel>
              <Input
                type="text"
                id="bio"
                name="bio"
                value={state.bio}
                onChange={handleChange}
              />
            </FormControl>
            <Box>Default Post Lifespan</Box>
            <br />
            <Stack direction="row" spacing={3}>
              <FormControl>
                <InputLabel htmlFor="post-days">Days</InputLabel>
                <Input
                  type="number"
                  id="post-days"
                  name="postDays"
                  value={state.postDays}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl>
                <InputLabel htmlFor="post-hours">Hours</InputLabel>
                <Input
                  type="number"
                  id="post-hours"
                  name="postHours"
                  value={state.postHours}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl>
                <InputLabel htmlFor="post-minutes">Minutes</InputLabel>
                <Input
                  type="number"
                  id="post-minutes"
                  name="postMinutes"
                  value={state.postMinutes}
                  onChange={handleChange}
                />
              </FormControl>
            </Stack>
            <Box>Default Vote Value</Box>
            <br />
            <Stack direction="row" spacing={3}>
              <FormControl>
                <InputLabel htmlFor="vote-days">Days</InputLabel>
                <Input
                  type="number"
                  id="vote-days"
                  name="voteDays"
                  value={state.voteDays}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl>
                <InputLabel htmlFor="post-hours">Hours</InputLabel>
                <Input
                  type="number"
                  id="vote-hours"
                  name="voteHours"
                  value={state.voteHours}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl>
                <InputLabel htmlFor="vote-minutes">Minutes</InputLabel>
                <Input
                  type="number"
                  id="vote-minutes"
                  name="voteMinutes"
                  value={state.voteMinutes}
                  onChange={handleChange}
                />
              </FormControl>
            </Stack>
            <Box>Default Follow Value</Box>
            <br />
            <Stack direction="row" spacing={3}>
              <FormControl>
                <InputLabel htmlFor="follow-days">Days</InputLabel>
                <Input
                  type="number"
                  id="follow-days"
                  name="followDays"
                  value={state.followDays}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl>
                <InputLabel htmlFor="follow-hours">Hours</InputLabel>
                <Input
                  type="number"
                  id="follow-hours"
                  name="followHours"
                  value={state.followHours}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl>
                <InputLabel htmlFor="follow-minutes">Minutes</InputLabel>
                <Input
                  type="number"
                  id="follow-minutes"
                  name="followMinutes"
                  value={state.followMinutes}
                  onChange={handleChange}
                />
              </FormControl>
            </Stack>
            <Button onClick={handleSubmit}>
              <Box>Save</Box>
            </Button>
          </Stack>
        </form>
      </Stack>
    </Box>
  );
}

export default SettingsPage;
