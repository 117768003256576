import React, { useContext, useState, useEffect } from 'react';
import SubmitPostComponent from '../SubmitPostComponent/SubmitPostComponent';
import Feed from './FeedComponent';
import { AuthContext, MyContext } from '../context/AuthContext';
import { useLocation } from 'react-router-dom';
import { CacheContext, CacheContextType } from '../context/CacheContext';
import { Stack, Button, Box } from '@mui/material';
import FollowsList from '../Follows/FollowsList';
import FollowButton from '../Follows/FollowButtonComponent';
import TimeDuration from '../TimeDuration/index';
import { DateTime } from 'luxon';

function FeedPage() {
  let feedTag = useLocation().pathname.replace('/feeds/', '');
  const userRecord = useContext<MyContext>(AuthContext).userRecord;
  const getFeedFromTag =
    useContext<CacheContextType>(CacheContext).getFeedFromTag;
  const [isExpired, setIsExpired] = useState(false);
  const feed = getFeedFromTag(feedTag);

  const [showFollows, setShowFollows] = useState(false);
  const [showPosts, setShowPosts] = useState(true);
  const toggleFollows = () => {
    setShowFollows((prev) => !prev);
    setShowPosts(false);
  };

  const togglePosts = () => {
    setShowPosts((prev) => !prev);
    setShowFollows(false);
  };

  useEffect(() => {
    if (!feed) return;
    setIsExpired(
      DateTime.fromSeconds(feed.expireAt.seconds).toJSDate() < new Date(),
    );
  }, [feed]);
  return (
    <div className="App">
      {!feed ? (
        <div>loading</div>
      ) : (
        <Stack direction="column" spacing={2}>
          <Stack
            direction="row"
            spacing={2}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <h1>#{feed.feedTag}</h1>
            <FollowButton parentRecord={feed} objectType="feeds" />
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <Box
              sx={{
                fontSize: '20px',
                fontWeight: 400,
              }}
            >
              Created{' '}
              <TimeDuration
                jsDate={DateTime.fromSeconds(feed.createdAt.seconds).toJSDate()}
                invertSign={true}
                titleDisplay="absoluteTime"
              />{' '}
              ago
            </Box>
            <Box
              sx={{
                fontSize: '16px',
                fontWeight: 400,
              }}
            >
              -
            </Box>
            <Box
              sx={{
                fontSize: '20px',
                fontWeight: 400,
              }}
            >
              {isExpired && 'Expired '}
              <TimeDuration
                jsDate={DateTime.fromSeconds(feed.expireAt.seconds).toJSDate()}
              />{' '}
              {isExpired ? 'ago' : 'remaining'}
            </Box>
          </Stack>
          {userRecord ? (
            <SubmitPostComponent objectType="posts" />
          ) : (
            <div>Login to post</div>
          )}
          <Stack direction="row" spacing={2}>
            <Button onClick={togglePosts}> Posts</Button>

            <Button onClick={toggleFollows}> Follows</Button>
          </Stack>
        </Stack>
      )}
      {showPosts &&
        (feed ? (
          <Feed feedRecord={feed} showMenu={false} />
        ) : (
          "This feed doesn't exist yet or expired and was wiped, post something to create it!"
        ))}
      {feed && showFollows && (
        <FollowsList
          recordID={feed.id}
          showMenu={false}
          initialFeed="followers"
        />
      )}
    </div>
  );
}

export default FeedPage;
