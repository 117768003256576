import Box from '@mui/material/Box';
import humanizeDuration from 'humanize-duration';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useInterval } from 'usehooks-ts';

const shortEnglishHumanizer = humanizeDuration.humanizer({
  language: 'shortEn',
  languages: {
    shortEn: {
      y: () => 'years',
      mo: () => 'months',
      w: () => 'weeks',
      d: () => 'days',
      h: () => 'hours',
      m: () => 'mins',
      s: () => 'secs',
      ms: () => 'msecs',
    },
  },
});

function TimeDuration({
  jsDate,
  invertSign = false,
  titleDisplay = 'remainingTime',
}: {
  jsDate: Date;
  invertSign?: boolean;
  titleDisplay?: 'remainingTime' | 'absoluteTime';
}) {
  const [shortString, setShortString] = useState('');
  const [fullString, setFullString] = useState('');

  const updateStrings = () => {
    const diffMs =
      DateTime.now().diff(DateTime.fromJSDate(jsDate)).milliseconds *
      (invertSign ? -1 : 1);
    const short = shortEnglishHumanizer(diffMs, { largest: 1, round: true });
    const full =
      titleDisplay === 'remainingTime'
        ? humanizeDuration(diffMs, { round: true })
        : DateTime.fromJSDate(jsDate).toLocaleString(DateTime.DATETIME_FULL);
    setShortString(short);
    setFullString(full);
  };

  useEffect(updateStrings, [invertSign, jsDate, titleDisplay]);
  useInterval(updateStrings, 10000);

  return (
    <Box display={'inline'} title={fullString}>
      {shortString}
    </Box>
  );
}

export default TimeDuration;
