import React, { useState } from 'react';
import TimePicker from '../timePickerComponent/timePickerComponent';
import { AuthContext, MyContext } from '../context/AuthContext';
import { CacheContext, CacheContextType } from '../context/CacheContext';
import { Box, IconButton, Tooltip } from '@mui/material';
import { TimeSelection } from '../model/types';
import HourglassUpIcon from '../resource/HourglassUpIcon';
import HourglassDownIcon from '../resource/HourglassDownIcon';
import useLongPress from '../useLongPress';
import { DateTime } from 'luxon';
import {
  setDoc,
  doc,
  updateDoc,
  collection,
  arrayUnion,
  Timestamp,
} from 'firebase/firestore';
import { db } from '../firebaseServices';
import PermanenceIcon from '../resource/PermanenceIcon';
function VoteButton({
  objectType,
  parentID,
  giveTime,
  onSubmit,
  makePermanent = false,
}: {
  objectType: string;
  parentID: string;
  giveTime?: boolean;
  makePermanent?: boolean;
  onSubmit?: () => void;
}) {
  const getPost = React.useContext<CacheContextType>(CacheContext).getPost;
  const getFollow = React.useContext<CacheContextType>(CacheContext).getFollow;
  const getUser =
    React.useContext<CacheContextType>(CacheContext).getUserRecord;
  const [showClock, setShowClock] = useState(false);
  const ref = React.useRef(null);
  const userRecord = React.useContext<MyContext>(AuthContext).userRecord;
  const [voteTime, setVoteTime] = useState<TimeSelection>({
    days: 0,
    hours: 0,
    minutes: 0,
  });

  const handleVote = async (isPermanent: boolean = false) => {
    if (!userRecord) return;
    let timeValue =
      voteTime.days * 24 * 60 * 60 +
      voteTime.hours * 60 * 60 +
      voteTime.minutes * 60;
    if (isPermanent && userRecord.permanenceTokens < 1) {
      alert('Not enough permanence tokens');
      return;
    } else if (timeValue > userRecord?.timeWallet.seconds) {
      alert('Not enough time in your wallet');
      return;
    } else {
      const newVoteRef = doc(collection(db, 'votes'));
      const newTimeWallet = DateTime.fromSeconds(
        userRecord.timeWallet.seconds - timeValue,
      ).toJSDate();
      if (!giveTime) timeValue = -timeValue;

      const objectRecord =
        objectType === 'posts' ? getPost(parentID) : getFollow(parentID);
      const objectRef = doc(collection(db, objectType), parentID);
      const wasExpired =
        DateTime.now().toSeconds > objectRecord?.expireAt.seconds;
      const newExpiry = DateTime.fromSeconds(
        objectRecord?.expireAt.seconds +
          (isPermanent ? 99999999990 : timeValue),
      ).toJSDate();

      const revivedPost = wasExpired && DateTime.now().toJSDate() < newExpiry;
      const isExpired = DateTime.now().toJSDate() > newExpiry && !isPermanent;
      const killedPost = !wasExpired && isExpired;
      setDoc(newVoteRef, {
        createdAt: Timestamp.now(),
        userRecordID: userRecord.id,
        objectType: objectType,
        parentID: parentID,
        timeValue: timeValue,
        revived: revivedPost,
        killed: killedPost,
        madePermanent: isPermanent,
      });

      updateDoc(objectRef, {
        expireAt: newExpiry,
        votes: arrayUnion(newVoteRef.id),
        isPermanent: isPermanent,
        isExpired: isExpired,
      });

      const userRef = doc(collection(db, 'users'), userRecord.id);
      updateDoc(userRef, {
        timeWallet: isPermanent ? userRecord?.timeWallet : newTimeWallet,
        permanenceTokens:
          isPermanent && userRecord
            ? userRecord?.permanenceTokens - 1
            : userRecord?.permanenceTokens,
        votes: arrayUnion(newVoteRef.id),
      });
      if (!isPermanent && giveTime) {
        const originalExpiry = DateTime.fromSeconds(
          objectRecord?.createdAt.seconds + objectRecord?.timeValue,
        ).toJSDate();
        if (originalExpiry < newExpiry) {
          const postUserRecord = getUser(objectRecord?.userRecordID ?? '');
          const timeDifference = DateTime.fromJSDate(newExpiry).diff(
            DateTime.fromJSDate(originalExpiry),
            'seconds',
          ).seconds;
          let timeToAdd =
            timeValue < timeDifference ? timeValue : timeDifference;

          const newUserTimeWallet = DateTime.fromSeconds(
            postUserRecord?.timeWallet.seconds + timeToAdd,
          ).toJSDate();
          const posterRef = doc(
            collection(db, 'users'),
            objectRecord?.userRecordID,
          );
          updateDoc(posterRef, {
            timeWallet: newUserTimeWallet,
          });
        }
      }
      onSubmit && onSubmit();
    }
  };
  const toggleClock = () => {
    setShowClock((prev) => !prev);
  };

  const onLongPress = () => {
    toggleClock();
  };

  const onClick = () => {
    userRecord &&
      setVoteTime({
        days: userRecord.userSettings.postDays,
        hours: userRecord.userSettings.postHours,
        minutes: userRecord.userSettings.postMinutes,
      });

    handleVote();
  };
  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 300,
  };
  const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);

  return (
    <Box
      sx={{
        color: 'text.primary',
      }}
    >
      {makePermanent ? (
        <Tooltip title="Make permanent">
          <IconButton onClick={() => handleVote(true)}>
            {' '}
            <PermanenceIcon
              style={{
                width: '25px',
                height: '25px',
              }}
            />{' '}
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip
          title={
            (giveTime ? 'Give time' : 'Take time') +
            ', hold down to set custom amount'
          }
        >
          <IconButton
            {...longPressEvent}
            ref={ref}
            sx={{ touchAction: 'none' }}
          >
            {!showClock &&
              (giveTime ? (
                <HourglassUpIcon
                  style={{
                    width: '25px',
                    height: '25px',
                  }}
                />
              ) : (
                <HourglassDownIcon
                  style={{
                    width: '25px',
                    height: '25px',
                  }}
                />
              ))}
            <TimePicker
              time={voteTime}
              setTime={setVoteTime}
              submit={handleVote}
              showClock={showClock}
              toggleClock={toggleClock}
              parentRef={ref}
            />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
}

export default VoteButton;
