import * as React from 'react';
const SvgNotifications = (props: any) => (
  <svg
    viewBox="6.459 6.028 30 31.956"
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={31.956}
    {...props}
  >
    <path d="M8.761 6.611v3.08H7.17V37.41h25.424v-1.542h3.174V6.61H8.762Zm0 29.257v-24.64h22.242v24.639H8.761Zm25.42-1.538h-1.587V9.69H10.348V8.148h23.833v26.181Z" />
    <path d="M30.623 31.533v-1.668H28.69v-3.337h-1.929V18.19h-1.933v-1.668h-1.933v-3.337h-1.933V9.848h-1.931v3.337h-1.933v3.337h-1.933v1.668h-1.929v8.338h-1.933v3.337H9.371v1.668h9.66v1.668h-1.933v1.668h5.798v-1.668h-1.933v-1.668zM19.034 19.858h-1.932v8.338h-1.933v1.668h-1.933v-1.668h1.933v-8.338h1.933V18.19h1.933z" />
  </svg>
);
export default SvgNotifications;
