import * as React from 'react';
const LinkIcon = (props: any) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 30 31.5"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g origin="0 1">
      <path d="M21.285.108c-.023.018-.041.295-.041.614v.572l-1.239.042-1.239.04v1.228h-1.239v1.239h-1.239v1.239h-1.239v1.233l-.631.018-.638.018-.017 1.25-.012 1.257h-1.239v5.015H6.258v1.239H5.019v1.239H3.78v1.239H2.541v1.233l-.631.018-.638.018-.017.63-.018.638H.004v7.493h1.239v1.239h1.298v1.239h2.478v1.239h3.776v-1.239h2.478v-1.239h1.239V27.62h1.239v-1.233l.637-.018.632-.018.017-.637.018-.631h1.233v-2.478h1.239v-3.776h-1.239v-1.162l.932-.036c.508-.023 2.195-.041 3.747-.041h2.814v-1.239h1.239v-1.239h1.239v-1.233l.637-.018.632-.018.017-.637.018-.631h1.233v-1.239h1.239V3.843h-1.239V2.604h-1.239V1.365H25.02V.067h-1.847c-1.02 0-1.87.018-1.888.041zm3.735 1.877v.619h2.537v2.478h1.239v5.015h-1.239v1.239h-1.298v1.239H25.02v1.298h-1.239v1.239h-1.239v1.239h-3.717v-1.239h1.239v-1.239h1.239v-1.239h1.239v-1.239h1.239v-1.298h-1.239V8.858h-1.239V6.321h3.717V5.082h-1.239V3.843h-1.239v1.239h-2.537v1.239h-1.239v1.298h-1.239v1.239h-1.239v1.239h-1.239v1.239h-1.298V8.858h1.298V6.386l.608-.018.601-.017.018-.638.018-.631h1.233V3.843h1.239V2.604h2.537V1.365h3.717v.62zm-5.015 7.493v.619h1.298v2.472l-.631.018-.638.018-.017.63-.018.638h-1.233v1.239h-1.239v1.239h-1.239v1.239h-1.239v1.233l-.631.018-.638.018-.017.63-.018.638h-1.233v1.239h-1.239v1.245l.637-.018.631-.017.018-.608.018-.602h1.233v-1.239h2.478v1.239h-1.239v2.478h-1.298v1.239h-1.239v1.298h-1.239v1.239h-1.239v1.239H8.795v1.239H5.019v-1.239H2.541v-2.472l-.631-.018-.637-.018-.018-2.495-.012-2.49h1.298v-2.472l.602-.018.608-.018.017-.637.018-.631h1.233v-1.239h2.478v-1.239h2.537v1.239H8.795v1.239H7.497v1.239H6.258v1.298H5.019v1.239H3.78v3.776h1.239v1.239h1.239v1.239h1.239v-1.233l.637-.018.631-.018.018-.607.018-.602h1.233v-1.239h1.239v-1.298h-1.239v-1.239H8.795v-2.537h1.239V17.59h1.239v-1.239h1.239v-1.239h1.239v-1.233l.637-.018.632-.018.017-.637.018-.631h1.233v-1.239h1.239v-1.239h1.239V8.858h1.239v.62zM7.497 21.986v.619h1.298v2.478H5.019v-2.478h1.239v-1.239h1.239v.62z" />
      <path d="M25.02 7.59v1.268h-1.239v1.239h1.239V8.858h1.239V6.321H25.02V7.59z" />
    </g>
  </svg>
);
export default LinkIcon;
