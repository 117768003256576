import * as React from 'react';
const PermanenceIcon = (props: any) => (
  <svg
    viewBox="0 0 30.577 32"
    width={30}
    height={32}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M2.307 30h25.384v2H2.307zm25.384-2h2.307v2h-2.307zM-.001 28h2.308v2H0zM27.691 2h2.307v2h-2.307zM-.001 2h2.308v2H0zm2.308-2h25.384v2H2.307zm23.076 23.999v2H4.615v-2H2.307v4h25.384v-4zM2.307 4v4h2.308V6h20.768v2h2.308V4H2.307Z" />
    <path
      d="M2699.999 7235v-65h-400v-140h-270v-130h-140v-140h-130v-130h-140v-140h-130v-270h-140V5000h140v-270h130v-270h140v-130h130v-140h140v-130h130v-140h410v-130h810v130h410v140h270v130h270v140h130v130h140v140h270v-140h130v-130h140v-140h130v-130h270v-140h410v-130h810v130h410v140h270v130h130v140h140v130h130v140h140v400h130v1090h-130v270h-140v270h-130v130h-140v140h-130v130h-140v140h-410v130h-810v-130h-400v-140h-270v-130h-140v-140h-270v-130h-130v-140h-270v140h-140v130h-130v140h-270v130h-140v140h-410v130h-810v-65Zm810-405v-70h280v-130h270v-140h130v-130h140v-410h-140v140h-130v130h-140v140h-130v130h-280v140h-810v-140h-270v-130h-270v-270h-130v-410h-140v-140h140v-540h130v-130h140v-270h270v-140h940v140h280v130h130v140h140v130h130v140h140v130h130v140h140v270h130v140h140v270h130v130h140v140h130v130h140v140h130v130h270v140h1220v-140h140v-130h270v-270h130v-140h140v-270h130v-540h-130v-540h-140v-140h-130v-130h-140v-140h-130v-130h-140v-140h-1220v140h-270v130h-130v140h-140v130h-130v270h270v-130h130v-140h140v-130h270v-140h810v140h270v130h270v270h140v410h130v130h-130v550h-140v130h-130v270h-280v140h-810v-140h-400v-130h-140v-140h-130v-130h-140v-140h-130v-130h-140v-140h-130v-270h-140v-270h-130v-140h-140v-130h-130v-140h-140v-130h-130v-140h-140v-130h-270v-140h-1080v140h-270v130h-140v140h-130v130h-140v140h-130v400h-140v410h140v540h130v140h140v270h270v130h130v140h1080v-70Zm-400-540v-70h400v-130h140v-140h270v-130h140v-550h-140v-130h-130v-140h-280v-130h-270v-140h-270v140h-400v130h-140v140h-130v680h130v270h140v130h270v140h270v-70Zm3380 0v-70h400v-130h140v-140h140v-680h-140v-270h-140v-130h-270v-140h-270v140h-400v130h-270v140h-140v130h-130v140h-140v130h140v280h130v130h140v140h270v130h270v140h270v-70Z"
      style={{
        stroke: '#000',
        paintOrder: 'stroke',
        strokeWidth: '76.8167px',
      }}
      transform="matrix(.0034 0 0 -.00469 -1.024 41.524)"
    />
  </svg>
);
export default PermanenceIcon;
