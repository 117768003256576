import React, { useEffect, useState, PropsWithChildren } from 'react';

import { db } from '../firebaseServices';

import { CacheContext, CacheContextType } from '../context/CacheContext';
import { collection, onSnapshot } from 'firebase/firestore';
import {
  UserRecord,
  PostData,
  FeedData,
  FollowData,
  VoteData,
} from '../model/types';

export const CacheProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [userRecords, setUserRecords] = useState<Array<UserRecord>>([]);
  const [posts, setPosts] = useState<Array<PostData>>([]);
  const [feeds, setFeeds] = useState<Array<FeedData>>([]);
  const [follows, setFollows] = useState<Array<FollowData>>([]);
  const [votes, setVotes] = useState<Array<VoteData>>([]);
  function getUserRecord(id: string) {
    return userRecords.find((user) => user.id === id);
  }
  function getUserRecordFromUsername(username: string) {
    return userRecords.find((user) => user.username === username);
  }
  function getPost(id: string) {
    return posts.find((post) => post.id === id);
  }
  function getFollow(id: string) {
    return follows.find((follow) => follow.id === id);
  }
  function getFeedFromTag(tag: string) {
    return feeds.find((feed) => feed.feedTag === tag);
  }
  function getFeedFromID(id: string) {
    return feeds.find((feed) => feed.id === id);
  }

  function allUsers() {
    return userRecords;
  }
  function allFeeds() {
    return feeds;
  }
  function allPosts() {
    return posts;
  }
  function allFollows() {
    return follows;
  }
  function allVotes() {
    return votes;
  }
  const unsubscribe = () => {};

  useEffect(() => {
    onSnapshot(collection(db, 'users'), (querySnapshot) => {
      let snapshotUsers: UserRecord[] = [];
      querySnapshot.forEach((doc) => {
        // We should use zod to validate the data from these records
        const user = {
          follows: doc.data().follows,
          id: doc.id,
          bio: doc.data().bio,
          joinDate: doc.data().value,
          username: doc.data().username,
          expireAt: doc.data().expireAt,
          votes: doc.data().votes,
          posts: doc.data().posts,
          timeWallet: doc.data().timeWallet,
          permanenceTokens: doc.data().permanenceTokens,
        };

        snapshotUsers.push(user);
      });
      const source = querySnapshot.metadata.fromCache
        ? 'local cache'
        : 'server';
      console.log('Users came from ' + source);

      setUserRecords(snapshotUsers);
    });
    return unsubscribe;
  }, []);
  useEffect(() => {
    onSnapshot(collection(db, 'posts'), (querySnapshot) => {
      let snapshotPosts: PostData[] = [];
      querySnapshot.forEach((doc) => {
        // We should use zod to validate the data from these records
        const post = {
          userRecordID: doc.data().userRecordID,
          id: doc.id,
          value: doc.data().value,
          createdAt: doc.data().createdAt,
          expireAt: doc.data().expireAt,
          votes: doc.data().votes,
          posts: doc.data().posts,
          parentType: doc.data().parentType,
          parentID: doc.data().parentID,
          isPermanent: doc.data().isPermanent,
          timeValue: doc.data().timeValue,
          isExpired: doc.data().isExpired,
        };

        snapshotPosts.push(post);
      });
      const source = querySnapshot.metadata.fromCache
        ? 'local cache'
        : 'server';
      console.log('Posts came from ' + source);
      setPosts(snapshotPosts);
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    onSnapshot(collection(db, 'feeds'), (querySnapshot) => {
      let snapshotFeeds: FeedData[] = [];
      querySnapshot.forEach((doc) => {
        // We should use zod to validate the data from these records
        const feed = {
          id: doc.id,
          createdAt: doc.data().createdAt,
          expireAt: doc.data().expireAt,
          posts: doc.data().posts,
          isPermanent: doc.data().isPermanent,
          feedTags: doc.data().feedTags,
          follows: doc.data().follows,
          feedTag: doc.data().feedTag,
          isExpired: doc.data().isExpired,
        };
        snapshotFeeds.push(feed);
      });
      const source = querySnapshot.metadata.fromCache
        ? 'local cache'
        : 'server';
      console.log('Feeds came from ' + source);
      setFeeds(snapshotFeeds);
    });
    return unsubscribe;
  }, []);
  useEffect(() => {
    onSnapshot(collection(db, 'follows'), (querySnapshot) => {
      let snapshotFollows: FollowData[] = [];
      querySnapshot.forEach((doc) => {
        // We should use zod to validate the data from these records
        const follow = {
          id: doc.id,
          createdAt: doc.data().createdAt,
          expireAt: doc.data().expireAt,
          value: doc.data().value,
          isPermanent: doc.data().isPermanent,
          isExpired: doc.data().isExpired,
          parentType: doc.data().parentType,
          parentID: doc.data().parentID,
          userRecordID: doc.data().userRecordID,
          posts: doc.data().posts,
          votes: doc.data().votes,
          timeValue: doc.data().timeValue,
        };
        snapshotFollows.push(follow);
      });
      const source = querySnapshot.metadata.fromCache
        ? 'local cache'
        : 'server';
      console.log('follows came from ' + source);

      setFollows(snapshotFollows);
    });
    return unsubscribe;
  }, []);
  useEffect(() => {
    onSnapshot(collection(db, 'votes'), (querySnapshot) => {
      let snapshotVotes: VoteData[] = [];
      querySnapshot.forEach((doc) => {
        // We should use zod to validate the data from these records
        const vote = {
          id: doc.id,
          createdAt: doc.data().createdAt,
          parentType: doc.data().parentType,
          parentID: doc.data().parentID,
          userRecordID: doc.data().userRecordID,
          timeValue: doc.data().timeValue,
          revived: doc.data().revived,
          killed: doc.data().killed,
          madePermanent: doc.data().madePermanent,
          objectType: doc.data().objectType,
        };

        snapshotVotes.push(vote);
      });
      const source = querySnapshot.metadata.fromCache
        ? 'local cache'
        : 'server';
      console.log('Votes came from ' + source);
      setVotes(snapshotVotes);
    });
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CacheContext.Provider
      value={
        {
          getUserRecord: getUserRecord,
          getUserRecordFromUsername: getUserRecordFromUsername,
          getPost: getPost,
          allUsers: allUsers,
          allFeeds: allFeeds,
          allPosts: allPosts,
          allFollows: allFollows,
          allVotes: allVotes,
          getFeedFromTag: getFeedFromTag,
          getFeedFromID: getFeedFromID,
          getFollow: getFollow,
        } as CacheContextType
      }
    >
      {children}
    </CacheContext.Provider>
  );
};
