import React, { useEffect, useState, useContext } from 'react';

import { DateTime } from 'luxon';
import TimeDuration from '../TimeDuration/index';
import { Stack } from '@mui/system';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import '../fonts.css';
import HealthBar from '../HealthBar/HealthBar';
import PostActions from '../postComponent/PostActions';
import { CacheContext, CacheContextType } from '../context/CacheContext';
import { UserRecord, FeedData } from '../model/types';
import reactStringReplace from 'react-string-replace';
import FeedIcon from '../resource/FeedIcon';
import AvatarIcon from '../resource/AvatarIcon';
import TombstoneCustomIcon from '../resource/TombstoneCustom';
function Follow({
  follow,
  expandToReply,
  useParentID,
}: {
  follow: any;
  expandToReply?: string;
  useParentID: boolean;
}) {
  const getUserRecord =
    useContext<CacheContextType>(CacheContext).getUserRecord;
  const getFeedFromID =
    useContext<CacheContextType>(CacheContext).getFeedFromID;
  const [followDisplayRecord, setFollowDisplayRecord] = useState<
    UserRecord | FeedData | null
  >(null);
  const [isExpired, setIsExpired] = useState(false);
  const [displayName, setDisplayName] = useState<string>('');
  const [displayType, setDisplayType] = useState<string>('');
  const [expiryDate, setExpiryDate] = useState<Date>(
    DateTime.fromSeconds(follow.expireAt.seconds).toJSDate(),
  );
  const [createdTime, setCreatedTime] = useState<DateTime>(
    DateTime.fromSeconds(follow.createdAt.seconds),
  );
  useEffect(() => {
    setIsExpired(expiryDate < new Date() && !follow?.isPermanent);
    setExpiryDate(DateTime.fromSeconds(follow.expireAt.seconds).toJSDate());
    setCreatedTime(DateTime.fromSeconds(follow.createdAt.seconds));

    if (!follow.parentID || follow.parentID === undefined) return;
    const fetchID = useParentID ? follow.parentID : follow.userRecordID;
    if (
      follow.parentType === undefined ||
      follow.parentType === 'users' ||
      !useParentID
    ) {
      setDisplayType('users');
      const userRecord = getUserRecord(fetchID);
      setFollowDisplayRecord(userRecord || null);
      setDisplayName(userRecord?.username || '');
    } else if (follow.parentType === 'feeds' && useParentID) {
      setDisplayType('feeds');
      const feedRecord = getFeedFromID(fetchID);
      setFollowDisplayRecord(feedRecord || null);
      setDisplayName(feedRecord?.feedTag || '');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [follow]);
  function replaceTagsWithLinks(text: string) {
    let newMessage = text;
    const regexpMention = new RegExp(/@\[(.*?)\]\(.*?\)/gm);
    const regexpFeed = new RegExp(/#\[(.*?)\]\(.*?\)/gm);
    const regexpFeedRaw = new RegExp(/#([A-Za-z0-9_]+)/gm);

    let returnFinal = reactStringReplace(
      newMessage,
      regexpMention,
      (match, i) => {
        return (
          <Link to={'/' + match} key={match + i}>
            @{match}
          </Link>
        ) as any;
      },
    );
    returnFinal = reactStringReplace(returnFinal, regexpFeedRaw, (match, i) => {
      return (
        <Link to={'/feeds/' + match} key={match + i}>
          #{match}
        </Link>
      ) as any;
    });
    returnFinal = reactStringReplace(returnFinal, regexpFeed, (match, i) => {
      return (
        <Link to={'/feeds/' + match} key={match + i}>
          #{match}
        </Link>
      ) as any;
    });
    return returnFinal;
  }

  return (
    <Box
      sx={{
        boxSizing: 'border-box',
        background: 'white',
        margin: '0 auto',
        borderRadius: '3px',
        padding: 1,
        width: '100%',
      }}
      id={follow.id}
    >
      <Stack direction="row" spacing={1}>
        <HealthBar objectRecord={follow}>
          {displayType === 'feeds' ? <FeedIcon /> : <AvatarIcon />}
        </HealthBar>
        <Stack direction="column">
          <Stack direction="row" spacing={0.5}>
            <Box
              sx={{
                color: 'black',
                fontSize: '16px',
                fontWeight: 700,
              }}
            >
              {!followDisplayRecord
                ? 'loading'
                : <Link to={'/' + displayName}>{displayName}</Link> || ''}
            </Box>
            <Box
              sx={{
                color: 'rgb(83, 100, 113)',
                fontSize: '16px',
                fontWeight: 400,
              }}
            >
              {displayType === 'users' ? '@' : '#'}
              {!followDisplayRecord
                ? 'loading'
                : <Link to={'/' + displayName}>{displayName}</Link> || ''}
            </Box>
            <Box
              sx={{
                color: 'rgb(83, 100, 113)',
                fontSize: '16px',
                fontWeight: 400,
              }}
            >
              -
            </Box>
            <Box
              sx={{
                color: 'rgb(83, 100, 113)',
                fontSize: '16px',
                fontWeight: 400,
              }}
            >
              <TimeDuration
                jsDate={createdTime.toJSDate()}
                invertSign={true}
                titleDisplay="absoluteTime"
              />{' '}
              ago
            </Box>
            <Box
              sx={{
                color: 'rgb(83, 100, 113)',
                fontSize: '16px',
                fontWeight: 400,
              }}
            >
              -
            </Box>
            <Box
              sx={{
                color: 'rgb(83, 100, 113)',
                fontSize: '16px',
                fontWeight: 400,
              }}
            >
              {isExpired && 'Expired '}
              {follow?.isPermanent ? (
                'IMMORTAL'
              ) : (
                <TimeDuration jsDate={expiryDate} />
              )}{' '}
              {!follow?.isPermanent && (isExpired ? 'ago' : 'remaining')}
            </Box>
          </Stack>
          <Box
            sx={{
              color: isExpired ? '#b7bdb9' : 'black',
              fontSize: '16px',
              fontWeight: 400,
              fontStyle: isExpired ? 'italic' : 'normal',
            }}
            justifyContent="center"
          >
            {isExpired ? (
              <Stack direction="column" spacing={0.5}>
                <Box
                  sx={{ width: '50%', height: '50%' }}
                  justifyContent="center"
                >
                  <TombstoneCustomIcon
                    className="font-face-nokia"
                    username={follow.username}
                    from={DateTime.fromSeconds(
                      follow?.createdAt.seconds,
                    ).toFormat("EEEE',' MMMM d',' ha")}
                    to={DateTime.fromSeconds(follow?.expireAt.seconds).toFormat(
                      "EEEE',' MMMM d',' ha",
                    )}
                    objectType="follow"
                    style={{ width: '200px', height: '250px' }}
                  />
                </Box>
                <Box>this follow is expired, vote to revive it</Box>
              </Stack>
            ) : (
              replaceTagsWithLinks(follow.value)
            )}
          </Box>
          <PostActions
            post={follow}
            expandToReply={expandToReply}
            objectType="follows"
            parentType="follows"
          />
        </Stack>
      </Stack>
    </Box>
  );
}

export default Follow;
