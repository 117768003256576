import React, { useContext } from 'react';

import { CacheContext, CacheContextType } from '../context/CacheContext';
import { Link } from 'react-router-dom';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
function MostVotedTable({
  objectList,
  stat,
  identifier,
}: {
  objectList: Array<any>;
  stat: string;
  identifier: string;
}) {
  const getPost = useContext<CacheContextType>(CacheContext).getPost;
  const getFollow = useContext<CacheContextType>(CacheContext).getFollow;
  const getUserRecord =
    useContext<CacheContextType>(CacheContext).getUserRecord;
  interface voteCountMap {
    [key: string]: number;
  }

  let voteCounts: voteCountMap = {};
  let userIDs: Array<string> = [];
  objectList.forEach((user) => {
    if (voteCounts[user.id] === undefined) {
      userIDs.push(user.id);
      voteCounts[user.id] = 0;
    }

    user.posts &&
      user.posts.forEach((post: string) => {
        voteCounts[user.id] += getPost(post)?.votes?.length || 0;
      });
    user.follows &&
      user.follows.forEach((follow: string) => {
        voteCounts[user.id] += getFollow(follow)?.votes?.length || 0;
      });
  });

  userIDs.sort((a, b) => {
    if (voteCounts[a] === undefined) return 1;
    if (voteCounts[b] === undefined) return -1;
    return voteCounts[a] > voteCounts[b] ? -1 : 1;
  });

  const leaders = userIDs.map((objectID) => {
    return {
      id: objectID,
      identifier: getUserRecord(objectID)?.username,
      statValue: voteCounts[objectID],
    };
  });
  const top5 = leaders.slice(0, 5);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 200 }} size="small">
        <TableHead>
          <TableRow>
            <TableCell>{identifier}</TableCell>
            <TableCell align="right">{stat}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {top5.map((object) => {
            if (object === null) return null;
            return (
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                key={object?.id}
              >
                <TableCell component="th" scope="row">
                  <Link to={'/' + object?.identifier}>
                    @{object?.identifier}
                  </Link>
                </TableCell>
                <TableCell align="right">{object?.statValue}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default MostVotedTable;
