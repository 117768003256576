import * as React from 'react';
const ProfileIcon = (props: any) => (
  <svg
    viewBox="3.648 2.966 30 31.857"
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={31.857}
    {...props}
  >
    <path d="M24.168 11.77c0 0.072 0.01 0.098 0.026 0.062 0.01 -0.031 0.01 -0.093 0 -0.129 -0.015 -0.031 -0.026 -0.005 -0.026 0.067Z" />
    <path d="M5.709 3.088v3.152H4.081v28.37h26.021v-1.578h3.248V3.088l-27.641 0Zm0 29.945V7.814h22.765v25.218H5.709Zm26.018 -1.574h-1.624V6.24H7.333V4.662h24.393l0 26.797Z" />
    <path d="M26.174 27.11v-2.15h-1.075V23.886h-1.075v-1.075h-1.075v-1.079h-3.224v1.079h-2.15v0.004h-3.227v-1.079h-3.224v1.079h-1.075v1.075h-1.075v1.075H7.9v2.15H6.824v3.226h10.756v-0.004H27.252v-3.226zm0 2.149h-8.598v0.004H7.9v-1.075h1.075v-2.149h1.075v-1.075h1.075v-1.075h1.075v-1.075h1.075v1.075h4.305v-0.004h3.221v-1.075h1.075v1.075h1.075v1.075h1.075v1.075h1.075v2.149h1.075zM21.875 13.131v-1.075h-1.075v-1.075h-1.075v-1.074h-2.15v0.004H14.346v1.075h-1.075v1.075h-1.075v1.075h-1.075v4.299h1.075v1.075h1.075v1.075H14.346v1.077h3.231v-0.004h2.15v-1.077h1.075v-1.075h1.075V17.43h1.075v-4.299l-1.075 0zm0 2.149v1.075h-1.075v1.075h-1.075v1.075h-1.075v1.075h-1.075v0.004H15.42V18.508H14.346v-1.075h-1.075v-1.075h-1.075v-2.149h1.075v-1.075H14.346v-1.075h1.075v-1.075h2.156v-0.004h1.075v1.075h1.075V13.131h1.075v1.075h1.075z" />
  </svg>
);
export default ProfileIcon;
