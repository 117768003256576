import * as React from 'react';
const HomeIcon = (props: any) => (
  <svg
    viewBox="6.459 6.028 30 31.956"
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={31.956}
    {...props}
  >
    <path d="M8.761 6.611v3.08H7.17V37.41h25.424v-1.542h3.174V6.61zm0 29.257v-24.64h22.242v24.639zm25.42-1.538h-1.587V9.69H10.348V8.148h23.833zm-6.355-13.865h1.591v1.549h-1.591V34.33h-6.359v-6.16H18.29v6.16h-6.352V22.014h-1.591v-1.549h1.591v-1.538h1.587v-1.538h1.586v-1.542h1.591v-1.538h1.587v-1.542h3.177v1.542h1.587v1.538h1.598v1.542h1.587v1.538h1.586l.001 1.538z" />
  </svg>
);
export default HomeIcon;
