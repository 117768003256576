import React from 'react';
import {
  UserRecord,
  PostData,
  FeedData,
  FollowData,
  VoteData,
} from '../model/types';

export interface CacheContextType {
  getUserRecord: (id: string) => UserRecord | undefined;
  getUserRecordFromUsername: (username: string) => UserRecord | undefined;
  getPost: (id: string) => PostData | undefined;
  allUsers: () => UserRecord[];
  allFeeds: () => FeedData[];
  allPosts: () => PostData[];
  allFollows: () => FollowData[];
  allVotes: () => VoteData[];
  getFeedFromTag: (tag: string) => FeedData | undefined;
  getFeedFromID: (id: string) => FeedData | undefined;
  getFollow: (id: string) => FollowData | undefined;
}
export const CacheContext = React.createContext<CacheContextType>({
  getUserRecord: () => ({} as UserRecord),
  getUserRecordFromUsername: () => ({} as UserRecord),
  getPost: () => ({} as PostData),
  allUsers: () => [],
  allFeeds: () => [],
  allPosts: () => [],
  allFollows: () => [],
  allVotes: () => [],
  getFeedFromTag: () => ({} as FeedData),
  getFeedFromID: () => ({} as FeedData),
  getFollow: () => ({} as FollowData),
});
