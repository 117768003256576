import React, { useContext } from 'react';
import './App.css';
import TimeDuration from './TimeDuration/index';
import SubmitPostComponent from './SubmitPostComponent/SubmitPostComponent';
import Feed from './FeedComponent/FeedComponent';
import { AuthContext, MyContext } from './context/AuthContext';
import { stringToArray, getSchedule } from 'cron-converter';
import './fonts.css';

function HomePage() {
  const userRecord = useContext<MyContext>(AuthContext).userRecord;

  const wipeDate = getSchedule(
    stringToArray('0 0 1 * *'),
    new Date(),
    'America/Los_Angeles',
  )
    .next()
    .toJSDate();

  var taglines = [
    'The social media site that deletes your data; ON PURPOSE ',
    "We're all living on borrowed time",
    'App coming soon',
    'Like twitter, but instead that shitty Justin Timberlake movie',
  ];

  return (
    <div className="App">
      <header className="App-header">
        <p className="font-face-nokia">
          {taglines[Math.floor(Math.random() * taglines.length)]}
        </p>
        <p className="font-face-nokia">
          {' '}
          WIPE COMING IN <TimeDuration jsDate={wipeDate} />
        </p>

        {userRecord && <SubmitPostComponent objectType="posts" />}
        {userRecord && userRecord.follows && userRecord.follows.length > 0 ? (
          <Feed showMenu={true} />
        ) : (
          <Feed />
        )}
      </header>
    </div>
  );
}

export default HomePage;
