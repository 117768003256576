import React, { useState, useEffect, useContext } from 'react';
import { AuthContext, MyContext } from '../context/AuthContext';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { db } from '../firebaseServices';

import Notification from './Notification';
function NotificationList() {
  const userRecord = useContext<MyContext>(AuthContext).userRecord;
  const [notifications, setNotifications] = useState<any[]>([]);

  useEffect(() => {
    if (!userRecord) return;
    onSnapshot(
      query(
        collection(db, 'notifications'),
        where('userRecordID', '==', userRecord.id),
      ),
      (querySnapshot) => {
        let snapshotNotifications: any[] = [];
        querySnapshot.forEach((doc) => {
          // We should use zod to validate the data from these records
          const notification = {
            userRecordID: doc.data().userRecordID,
            id: doc.id,
            message: doc.data().message,
            createdAt: doc.data().createdAt,
            objectType: doc.data().objectType,
            objectID: doc.data().objectID,
            read: doc.data().read,
          };
          snapshotNotifications.push(notification);
        });
        setNotifications(snapshotNotifications);
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRecord]);

  return (
    <div>
      {notifications
        .sort((a, b) =>
          a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0,
        )
        .map((notification) => (
          <div key={notification.id} id={notification.id}>
            <Notification notification={notification} />
          </div>
        ))}
    </div>
  );
}

export default NotificationList;
