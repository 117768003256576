import React, { useEffect, useState } from 'react';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { db } from '../firebaseServices';
import Follow from './FollowComponent';
import {
  Divider,
  Stack,
  Select,
  Box,
  SelectChangeEvent,
  MenuItem,
  FormControl,
} from '@mui/material';
function FollowsList({
  recordID,
  showMenu,
  initialFeed = 'follows',
}: {
  recordID: string;
  showMenu?: boolean;
  initialFeed?: string;
}) {
  const [follows, setFollows] = useState<any[]>([]);
  const [feedType, setFeedType] = useState(initialFeed);

  useEffect(() => {
    if (feedType === 'follows' && recordID !== undefined) {
      onSnapshot(
        query(collection(db, 'follows'), where('userRecordID', '==', recordID)),
        (querySnapshot) => {
          let snapshotFollows: any[] = [];
          querySnapshot.forEach((doc) => {
            // We should use zod to validate the data from these records
            const follow = {
              userRecordID: doc.data().userRecordID,
              id: doc.id,
              value: doc.data().value,
              createdAt: doc.data().createdAt,
              expireAt: doc.data().expireAt,
              votes: doc.data().votes,
              posts: doc.data().posts,
              parentID: doc.data().parentID,
              parentType: doc.data().parentType,
              isPermanent: doc.data().isPermanent,
            };
            snapshotFollows.push(follow);
          });
          setFollows(snapshotFollows);
        },
      );
    } else if (feedType === 'followers' && recordID !== undefined) {
      onSnapshot(
        query(collection(db, 'follows'), where('parentID', '==', recordID)),
        (querySnapshot) => {
          let snapshotFollows: any[] = [];
          querySnapshot.forEach((doc) => {
            // We should use zod to validate the data from these records
            const post = {
              userRecordID: doc.data().userRecordID,
              id: doc.id,
              value: doc.data().value,
              createdAt: doc.data().createdAt,
              expireAt: doc.data().expireAt,
              votes: doc.data().votes,
              posts: doc.data().posts,
              parentID: doc.data().parentID,
              parentType: doc.data().parentType,
              isPermanent: doc.data().isPermanent,
            };
            snapshotFollows.push(post);
          });
          setFollows(snapshotFollows);
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordID, feedType]);

  const handleChange = (event: SelectChangeEvent) => {
    setFeedType(event.target.value as string);
  };

  return (
    <Box width="100%">
      <Stack direction="row" spacing={2} alignContent="center">
        <Box alignContent="center"> Follows </Box>
        {showMenu && (
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={feedType}
              label="Feed Type"
              onChange={handleChange}
              sx={{
                backgroundColor: 'white',
              }}
            >
              <MenuItem value={'followers'}>Followers</MenuItem>
              <MenuItem value={'follows'}>Following</MenuItem>
            </Select>
          </FormControl>
        )}
      </Stack>
      <Stack
        divider={<Divider flexItem />}
        spacing={1}
        padding={0}
        sx={{
          width: {
            xs: '100%',
            md: '560px',
          },
        }}
      >
        {follows
          .sort((a, b) =>
            a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0,
          )
          .map((follow) => {
            return (
              <Follow
                follow={follow}
                key={follow.id}
                useParentID={feedType === 'follows'}
              />
            );
          })}
      </Stack>
    </Box>
  );
}

export default FollowsList;
