import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import FollowButton from '../Follows/FollowButtonComponent';
import Feed from '../FeedComponent/FeedComponent';
import { Stack, Button, Box } from '@mui/material';
import FollowsList from '../Follows/FollowsList';
import VotesList from '../Votes/VotesList';
import { CacheContext, CacheContextType } from '../context/CacheContext';
import AvatarIcon from '../resource/AvatarIcon';

export async function loader({ request }: { request: any }) {
  let location: string = request.url.substring(
    request.url.lastIndexOf('/') + 1,
  );

  return location;
}

function UserPage() {
  const getUserRecordFromUsername =
    useContext<CacheContextType>(CacheContext).getUserRecordFromUsername;
  const location = useLocation().pathname.replace('/', '');
  const [pageUserRecord, setPageUserRecord] = useState(
    getUserRecordFromUsername(location),
  );
  const [showFollows, setShowFollows] = useState(false);
  const [showPosts, setShowPosts] = useState(true);
  const [showVotes, setShowVotes] = useState(false);
  const toggleFollows = () => {
    setShowFollows((prev) => !prev);
    setShowPosts(false);
    setShowVotes(false);
  };

  const toggleVotes = () => {
    setShowVotes((prev) => !prev);
    setShowPosts(false);
    setShowFollows(false);
  };

  const togglePosts = () => {
    setShowPosts((prev) => !prev);
    setShowVotes(false);
    setShowFollows(false);
  };
  useEffect(() => {
    setPageUserRecord(getUserRecordFromUsername(location));
  }, [location, getUserRecordFromUsername]);

  return (
    <div className="App">
      {!pageUserRecord ? (
        <div>loading</div>
      ) : (
        <Stack direction="column" spacing={2}>
          <Stack
            direction="row"
            spacing={2}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <AvatarIcon style={{ width: 40, height: 40 }} />
            <h1>{pageUserRecord.username}</h1>
            <FollowButton parentRecord={pageUserRecord} objectType="users" />
          </Stack>
          <Box> {pageUserRecord.bio ?? ''}</Box>
          <Stack direction="row" spacing={2}>
            <Button onClick={toggleFollows}> Follows</Button>
            <Button onClick={togglePosts}> Posts</Button>
            <Button onClick={toggleVotes}> Votes</Button>
          </Stack>
        </Stack>
      )}
      {pageUserRecord && showPosts && (
        <Feed userRecordIDs={[pageUserRecord.id]} />
      )}
      {pageUserRecord && showFollows && (
        <FollowsList recordID={pageUserRecord.id} showMenu={true} />
      )}
      {pageUserRecord && showVotes && (
        <VotesList
          recordID={pageUserRecord.id}
          showMenu={false}
          isPost={false}
        />
      )}
    </div>
  );
}

export default UserPage;
