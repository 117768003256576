import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
} from 'react';
import { AuthContext, MyContext } from '../context/AuthContext';
import { Link, Outlet } from 'react-router-dom';
import TimeDuration from '../TimeDuration/index';
import { DateTime } from 'luxon';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { auth } from '../firebaseServices';
import '../fonts.css';

import {
  CssBaseline,
  Box,
  Drawer,
  styled,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  Divider,
  IconButton,
  createTheme,
  Badge,
  useMediaQuery,
  Button,
  Tooltip,
} from '@mui/material';
import { Stack } from '@mui/system';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import NotificationsIcon from '@mui/icons-material/Notifications';
// import HomeIcon from '@mui/icons-material/Home';
// import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsIcon from '../resource/NotificationsIcon';
import HomeIcon from '../resource/HomeIcon';
import SettingsIcon from '../resource/SettingsIcon';
//import BrokenHourGlassLogo from '../resource/BrokenHourglassLogo';
import WLogoAnimated from '../resource/WLogoAnimated';
import TimeWalletIcon from '../resource/TimeWalletIcon';
import FeedsIcon from '../resource/FeedsIcon';
import ProfileIcon from '../resource/ProfileIcon';
import TrophyIcon from '../resource/TropyIcon';
import AvatarIcon from '../resource/AvatarIcon';
import SearchBar from '../Search/SearchBar';
import PermanenceIcon from '../resource/PermanenceIcon';
import WipeHouseRedux from '../resource/WipeHouseRedux';
const drawerWidth = 60;
const signOut = async () => {
  await auth.signOut();
};
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

function Nav() {
  const searchRef = useRef<HTMLInputElement>(null);
  const getNotifications = useContext<MyContext>(AuthContext).getNotifications;
  const userRecord = useContext<MyContext>(AuthContext).userRecord;
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });
  const [unreadNotifications, setUnreadNotifications] = useState<number>(0);

  const theme = darkTheme;
  const [open, setOpen] = useState(false);

  const isMobile = useMediaQuery('(max-width:600px)');
  useLayoutEffect(() => {
    if (!isMobile) handleDrawerOpen();
  }, [isMobile]);

  useEffect(() => {
    if (userRecord) {
      let count = 0;
      getNotifications().forEach((notification) => {
        if (notification.read === false || notification.read === undefined) {
          count++;
        }
      });
      setUnreadNotifications(count);
    }
  }, [userRecord, getNotifications]);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" color="inherit" open={open}>
        <Toolbar sx={{ height: '55px' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>

          <Stack
            direction="row"
            spacing={1}
            sx={{
              marginTop: 0.5,
              display: 'flex',
              alignItems: 'center',
              width: '100%',
            }}
          >
            {isMobile ? (
              <Link to="/">
                <WLogoAnimated />
              </Link>
            ) : (
              <Link to="/">
                <WipeHouseRedux className="font-face-nokia" />
              </Link>
            )}
            <Box width="100%" ref={searchRef}>
              <SearchBar parentRef={searchRef} />
            </Box>
            <Stack
              direction="column"
              spacing={0}
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: isMobile ? 'flex-start' : 'flex-end',
              }}
            >
              {!userRecord ? (
                <Box>
                  <Link to="/about">About</Link> |{' '}
                  <Link to="/welcome">How to Wipe</Link> |{' '}
                  <Link to="/login">Sign In</Link> |{' '}
                  <Link to="/signup">Sign Up</Link>
                </Box>
              ) : (
                <Box>
                  <Stack
                    direction="row"
                    spacing={1}
                    display="flex"
                    alignItems="center"
                  >
                    {!isMobile && <AvatarIcon />}

                    <Box
                      sx={{
                        paddingTop: '5px',
                        fontSize: '20px',
                        fontWeight: 700,
                        textDecoration: 'none',
                        color: 'black',
                      }}
                    >
                      <Link
                        style={{
                          textDecoration: 'none',
                          color: 'black',
                        }}
                        to={'/' + userRecord.username}
                      >
                        @{userRecord.username}
                      </Link>
                    </Box>
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={-1}
                      sx={{
                        fontSize: '20px',
                        fontWeight: 400,
                      }}
                    >
                      <Tooltip title="Time Wallet">
                        <IconButton size="small">
                          <Stack direction="column" alignItems="center">
                            <TimeWalletIcon
                              style={{
                                width: isMobile ? '30px' : '40px',
                                height: isMobile ? '30px' : '40px',
                              }}
                            />
                            <TimeDuration
                              jsDate={DateTime.fromSeconds(
                                userRecord.timeWallet.seconds +
                                  DateTime.now().toSeconds(),
                              ).toJSDate()}
                            />
                          </Stack>
                        </IconButton>
                      </Tooltip>
                      <Stack
                        direction="row"
                        sx={{ marginLeft: '5px', marginBottom: '5px' }}
                      >
                        <Tooltip title="Permanence Tokens">
                          <IconButton
                            size="small"
                            style={{
                              paddingTop: isMobile ? '.8rem' : '1rem',
                              paddingRight: isMobile ? '.8rem' : '.25rem',
                            }}
                          >
                            <Stack direction="column">
                              <PermanenceIcon
                                style={{
                                  width: isMobile ? '25px' : '30px',
                                  height: isMobile ? '25px' : '30px',
                                  paddingBottom: isMobile ? '1px' : '3px',
                                }}
                              />
                              {userRecord.permanenceTokens}
                            </Stack>
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </Stack>
                  </Stack>
                </Box>
              )}{' '}
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            justifyContent: 'space-between',
          },
        }}
        PaperProps={{
          sx: {
            overflow: 'hidden',
          },
        }}
        variant={isMobile ? 'temporary' : 'persistent'}
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          {isMobile ? (
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          ) : (
            <WLogoAnimated
              onClick={(e: any) => {
                e.preventDefault();
                window.location.href = '/';
              }}
            />
          )}
        </DrawerHeader>
        <Divider sx={{ marginBottom: 'auto' }} />
        <List sx={{ marginBottom: 'auto' }}>
          <ListItem key="Home">
            <Tooltip title="Home" placement="right">
              <ListItemIcon>
                <Link to="/">
                  <HomeIcon />
                </Link>
              </ListItemIcon>
            </Tooltip>
          </ListItem>
          <ListItem key="Leaderboard">
            <Tooltip title="Leaderboard" placement="right">
              <ListItemIcon>
                <Link to="/leaderboard">
                  <TrophyIcon />
                </Link>
              </ListItemIcon>
            </Tooltip>
          </ListItem>
          <ListItem key="Feeds">
            <Tooltip title="Feeds List" placement="right">
              <ListItemIcon>
                <Link to="/list/feeds">
                  <FeedsIcon />
                </Link>
              </ListItemIcon>
            </Tooltip>
          </ListItem>
          {userRecord && (
            <ListItem key="UserPage">
              <Tooltip title="Profile" placement="right">
                <ListItemIcon>
                  <Link to={'/' + userRecord.username}>
                    <ProfileIcon />
                  </Link>
                </ListItemIcon>
              </Tooltip>
            </ListItem>
          )}
          {userRecord && (
            <ListItem key="Notifications">
              <Tooltip title="Notifications" placement="right">
                <ListItemIcon>
                  <Badge badgeContent={unreadNotifications} color="primary">
                    <Link to="/notifications">
                      <NotificationsIcon />
                    </Link>
                  </Badge>
                </ListItemIcon>
              </Tooltip>
            </ListItem>
          )}

          {userRecord && (
            <ListItem key="Settings">
              <Tooltip title="Settings" placement="right">
                <ListItemIcon>
                  <Link to="/settings">
                    <SettingsIcon />
                  </Link>
                </ListItemIcon>
              </Tooltip>
            </ListItem>
          )}
        </List>
        <Box>{userRecord && <Button onClick={signOut}>Sign Out</Button>}</Box>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <Box sx={{ marginLeft: 5, overflowWrap: 'break-word' }}>
          <Outlet />
        </Box>
      </Main>
    </Box>
  );
}

export default Nav;
