import * as React from 'react';
const AvatarIcon = (props: any) => (
  <svg
    viewBox="0 0 27.799 25.588"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.557.792v.575h-1.149v1.149h-1.15v1.149H8.124l-.027 1.974a661.538 661.538 0 0 0 0 5.205l.027 3.231H9.26v2.299H6.96v1.149H4.67l-.02.554-.02.561-1.164.02-1.169.014v1.15H-.002v5.745h27.716v-5.746h-2.366v-1.149h-2.299v-1.149h-2.298v-1.15h-2.299v-2.298h1.15V3.665h-1.068l-.048-1.15-.054-1.148h-2.278V.217h-4.597v.575Zm3.448 2.292v.567l1.149.048 1.15.047v10.262h-1.15v1.217h-4.597v-2.299h-1.149V4.814h1.15V3.665h1.148v-1.15h2.299v.569Zm4.597 16.163v.574H21.9v1.15h2.298v1.149h1.15v1.149H2.296v-1.15h1.217v-1.148H5.81v-1.15H8.11v-1.149h11.492v.575Z"
      origin="0 1.007885"
    />
  </svg>
);
export default AvatarIcon;
