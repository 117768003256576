import * as React from 'react';
const WipeHouseCatchPhrase = (props: any) => (
  <svg viewBox="0 0 176 54" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M66.44 2.97h2.97v2.97h-2.97V2.97Zm-35.635 0h2.97-2.97ZM33.775 0H66.44v2.97H33.775V0Zm2.97 11.879h2.97v2.97h-2.97v-2.97Zm23.756 0h2.97v2.97h-2.97v-2.97Zm-20.786 2.968h2.97v2.97h-2.97v-2.97Z"
      origin="-0.797954 0"
    />
    <path
      d="M57.532 14.848h2.97v2.97h-2.97v-2.97Zm-14.849 2.97h2.97v2.97h-2.97v-2.97Zm2.97 2.97h2.97v5.939h-2.97v-5.939Zm8.909-2.97h2.97v2.97h-2.97v-2.97Zm-2.97 2.97h2.97v5.939h-2.97v-5.939Z"
      origin="-2.395365 -1.249937"
    />
    <path
      d="M33.776 5.94v5.939h2.97v-2.97h5.939v2.97h2.97v2.969h2.97v2.97h2.97v-2.97h2.97v-2.969h2.97v-2.97h5.939v2.97h2.97V5.94H33.776Z"
      origin="-1.033918 -0.500084"
    />
    <path
      d="M3.534 3.029h32.665V.059H3.534v2.97Zm-2.97 2.97h2.97v-2.97H.564v2.97Zm32.665 5.94v-2.97H6.504v2.97h-2.97v-5.94h32.665v5.939l-2.97.001Z"
      origin="-0.015828 -0.004966"
    />
    <path
      d="M6.504 14.907h2.97v-2.97h-2.97v2.97Zm2.97 2.97h2.97v-2.97h-2.97v2.97Zm2.968 2.97h2.97v-2.97h-2.97v2.97Zm17.817-5.94h2.97v-2.97h-2.97v2.97Zm-2.968 2.97h2.97v-2.97h-2.97v2.97Zm-2.97 2.97h2.97v-2.97h-2.97v2.97Zm-8.909 5.94h2.97v-5.939h-2.97v5.939Zm5.94 0h2.97v-5.939h-2.97v5.939Z"
      origin="-0.243368 -0.803838"
    />
    <path
      d="M42.905 40.982h14.85v-2.97h-2.97v-2.969h-2.97v-2.97h-2.97v2.97h-2.97v2.969h-2.97v2.97Z"
      origin="3.889226 4.600069"
    />
    <path
      d="M48.922 23.615h2.371v2.337h-2.371v-2.337"
      origin="-20.633509 -10.104836"
    >
      <animate
        repeatCount="indefinite"
        dur="3s"
        attributeName="visibility"
        values="hidden;visible;hidden"
      />
    </path>
    <path
      d="M48.953 19.344h2.371v2.337h-2.371v-2.337"
      origin="-20.64655 -8.277279"
    >
      <animate
        dur="3s"
        attributeName="visibility"
        repeatCount="indefinite"
        values="visible;hidden;hidden"
      />
    </path>
    <path
      d="M49.074 27.841h2.371v2.337h-2.371v-2.337"
      origin="-20.697618 -11.913137"
    >
      <animate
        dur="3s"
        values="hidden;hidden;visible"
        repeatCount="indefinite"
        attributeName="visibility"
      />
    </path>
    <text
      style={{
        fontFamily: '&quot',
        fontSize: '6.95084px',
        fontStyle: 'italic',
        fontWeight: 700,
        paintOrder: 'fill',
        whiteSpace: 'pre',
      }}
      transform="matrix(1.02044 0 0 1.43867 -249.066 -78.768)"
      origin="0 -3.347125"
    >
      <tspan
        x={244.215}
        y={90.818}
        style={{
          textDecoration: 'overline solid rgba(255,255,255,.8)',
        }}
      >
        {'Some Things are made to be Discarded '}
      </tspan>
    </text>
    <text
      style={{
        fontFamily: '&quot',
        fontSize: '4.29567px',
        letterSpacing: '1.9px',
        whiteSpace: 'pre',
      }}
      transform="matrix(3.42456 0 0 4.19027 -828.105 -183.27)"
      x={262.765}
      y={48.918}
      origin="-1.523236 -0.163752"
    >
      {'ipe'}
    </text>
    <text
      style={{
        fontFamily: '&quot',
        fontSize: '4.29567px',
        letterSpacing: '1.9px',
        whiteSpace: 'pre',
      }}
      transform="matrix(3.42456 0 0 4.19027 -833.601 -165.169)"
      x={262.765}
      y={48.918}
      origin="-0.771634 -0.967328"
    >
      {'House'}
    </text>
  </svg>
);
export default WipeHouseCatchPhrase;
