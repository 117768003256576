import React, { useEffect, useState } from 'react';

import { Stack } from '@mui/system';
import { Box, IconButton, Tooltip } from '@mui/material';
import { PostData } from '../model/types';
import VotesList from '../Votes/VotesList';
import VoteButton from '../Votes/VoteButton';
import SubmitPostComponent from '../SubmitPostComponent/SubmitPostComponent';
import Replies from './RepliesComponent';
import HourglassFullIcon from '../resource/HourglassFullIcon';
import RepliesIcon from '../resource/RepliesIcon';
import LinkIcon from '../resource/LinkIcon';
import { Link } from 'react-router-dom';
import { AuthContext, MyContext } from '../context/AuthContext';

function PostActions({
  post,
  expandToReply,
  objectType = 'posts',
  parentType = 'posts',
}: {
  post: PostData;
  expandToReply?: string;
  objectType?: string;
  parentType?: string;
}) {
  const userRecord = React.useContext<MyContext>(AuthContext).userRecord;
  const [showReplies, setShowReplies] = useState(false);
  const [showVotes, setShowVotes] = useState(false);
  const toggleReplies = () => {
    setShowReplies((prev) => !prev);
    setShowVotes(false);
  };

  const toggleVotes = () => {
    setShowVotes((prev) => !prev);
    setShowReplies(false);
  };

  useEffect(() => {
    if (expandToReply !== undefined) setShowReplies(true);
  }, [expandToReply]);
  return (
    <Box
      sx={{
        color: 'text.primary',
      }}
    >
      <Stack direction="row" spacing={-0.25} alignItems="center">
        <Tooltip title="Replies">
          <IconButton onClick={() => toggleReplies()}>
            {post.posts ? post.posts.length : 0}{' '}
            <RepliesIcon
              style={{
                width: '25px',
                height: '25px',
              }}
            />
          </IconButton>
        </Tooltip>
        {userRecord && (
          <VoteButton
            parentID={post.id}
            objectType={objectType}
            giveTime={true}
          />
        )}

        {userRecord && (
          <VoteButton
            parentID={post.id}
            objectType={objectType}
            giveTime={false}
          />
        )}

        {userRecord && (
          <VoteButton
            parentID={post.id}
            objectType={objectType}
            makePermanent={true}
          />
        )}
        <Tooltip title="Votes">
          <IconButton onClick={() => toggleVotes()}>
            {post.votes ? post.votes.length : 0}
            <HourglassFullIcon
              style={{
                width: '25px',
                height: '25px',
              }}
            />
          </IconButton>
        </Tooltip>

        <Link
          to={
            '/' +
            objectType +
            '/' +
            post.id +
            (post.parentID !== undefined && objectType !== 'follows'
              ? `&isReply=true`
              : '')
          }
        >
          <Tooltip title="Link to post">
            <IconButton>
              <LinkIcon
                style={{
                  width: '25px',
                  height: '25px',
                }}
              />
            </IconButton>
          </Tooltip>
        </Link>
      </Stack>

      {showReplies && (
        <Stack direction="column">
          {userRecord && (
            <SubmitPostComponent
              parentID={post.id}
              objectType={'posts'}
              parentType={parentType}
            />
          )}
          <Replies
            parentObject={post}
            expandToReply={expandToReply}
            setShowReplies={setShowReplies}
          />
        </Stack>
      )}
      {showVotes && (
        <VotesList recordID={post.id} isPost={true} showMenu={false} />
      )}
    </Box>
  );
}

export default PostActions;
