import React, { useRef, useContext } from 'react';
import { db, auth } from '../firebaseServices';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { Col, Row, Container, Form, Navbar } from 'react-bootstrap';
import { AuthContext, MyContext } from '../context/AuthContext';
import { Alert, Button } from '@mui/material';
import { DateTime } from 'luxon';
import TimeDuration from '../TimeDuration/index';
import { Navigate } from 'react-router-dom';
import { CacheContext, CacheContextType } from '../context/CacheContext';

function SignUpPage() {
  const user = useContext<MyContext>(AuthContext).user;
  const userRecord = useContext<MyContext>(AuthContext).userRecord;
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const confirmPasswordRef = useRef<HTMLInputElement>(null);
  const usernameRef = useRef<HTMLInputElement>(null);
  const allUsers = useContext<CacheContextType>(CacheContext).allUsers;
  const usernames = allUsers().map((user) => user.username);
  const [emailErrors, setEmailErrors] = React.useState<Array<string>>([]);
  const [passwordErrors, setPasswordErrors] = React.useState<Array<string>>([]);
  const [usernameErrors, setUsernameErrors] = React.useState<Array<string>>([]);
  const usernameRegex = new RegExp(/^[a-zA-Z0-9]+$/);
  const emailRegex = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
  );

  const createAccount = async () => {
    if (!validateForm()) return;
    try {
      await createUserWithEmailAndPassword(
        auth,
        emailRef.current!.value,
        passwordRef.current!.value,
      ).then(async function (returnedUser) {
        await addDoc(collection(db, 'users'), {
          useruid: returnedUser.user.uid,
          email: returnedUser.user.email,
          username: usernameRef.current!.value,
          joinDate: serverTimestamp(),
          timeWallet: DateTime.fromSeconds(30 * 24 * 60 * 60).toJSDate(),
          permanenceTokens: 10,
          userSettings: {
            postDays: 1,
            postHours: 0,
            postMinutes: 0,
            voteDays: 0,
            voteHours: 1,
            voteMinutes: 0,
            followDays: 5,
            followHours: 0,
            followMinutes: 0,
          },
        });
      });
    } catch (error) {
      console.error(error);
      alert(error.message);
    }
  };
  function validateUsername() {
    const usernameValue = usernameRef.current!.value;
    setUsernameErrors([]);
    if (
      usernameRef.current!.value.length > 20 ||
      usernameRef.current!.value.length < 3
    ) {
      setUsernameErrors((prev) => [
        ...prev,
        'Username must be between 3 and 20 characters',
      ]);
    }

    if (usernames.includes(usernameValue)) {
      setUsernameErrors((prev) => [...prev, 'Username already taken']);
    }

    if (!usernameRegex.test(usernameValue)) {
      setUsernameErrors((prev) => [
        ...prev,
        'Username can only contain letters and numbers',
      ]);
    }
  }

  function validatePassword() {
    setPasswordErrors([]);
    if (passwordRef.current!.value !== confirmPasswordRef.current!.value) {
      setPasswordErrors((prev) => [...prev, 'Passwords do not match']);
    }
  }

  function validateEmail() {
    setEmailErrors([]);
    if (!emailRegex.test(emailRef.current!.value)) {
      setEmailErrors((prev) => [...prev, 'Please enter a valid email address']);
    }
  }

  function validateForm() {
    try {
      return (
        usernameErrors.length === 0 &&
        passwordErrors.length === 0 &&
        emailErrors.length === 0 &&
        usernameRef.current!.value &&
        passwordRef.current!.value &&
        confirmPasswordRef.current!.value &&
        emailRef.current!.value
      );
    } catch {
      return false;
    }
  }

  return (
    <>
      <Navbar className="justify-content-between" bg="dark" variant="dark">
        {userRecord && (
          <Container fluid>
            {userRecord.username}
            <TimeDuration
              jsDate={DateTime.fromSeconds(
                userRecord.timeWallet.seconds + DateTime.now().toSeconds(),
              ).toJSDate()}
            />
          </Container>
        )}
      </Navbar>
      {!user ? (
        <Container style={{ maxWidth: '500px' }} fluid>
          <Form className="mt-4">
            <Form.Group controlId="formEmail">
              {emailErrors.length > 0 &&
                emailErrors.map((error) => (
                  <Alert severity="error" key={error}>
                    {error}
                  </Alert>
                ))}
              <Form.Label>Email</Form.Label>
              <Form.Control
                onChange={validateEmail}
                ref={emailRef}
                type="email"
                placeholder="email"
              />
            </Form.Group>
            <Form.Group controlId="formPassword">
              {passwordErrors.length > 0 &&
                passwordErrors.map((error) => (
                  <Alert severity="error" key={error}>
                    {error}
                  </Alert>
                ))}
              <Form.Label>Password</Form.Label>
              <Form.Control
                ref={passwordRef}
                type="password"
                placeholder="password"
              />
            </Form.Group>
            <Form.Group controlId="formConfirmPassword">
              <Form.Label> Confirm Password</Form.Label>
              <Form.Control
                onChange={validatePassword}
                ref={confirmPasswordRef}
                type="password"
                placeholder="password"
              />
            </Form.Group>
            <Form.Group controlId="formUsername">
              {usernameErrors.length > 0 &&
                usernameErrors.map((error) => (
                  <Alert severity="error" key={error}>
                    {error}
                  </Alert>
                ))}
              <Form.Label>Username</Form.Label>
              <Form.Control
                onChange={validateUsername}
                ref={usernameRef}
                type="username"
                placeholder="@username"
              />
            </Form.Group>
            <Row>
              <Col xs={6}>
                {validateForm() ? (
                  <Button type="button" onClick={createAccount}>
                    Sign Up
                  </Button>
                ) : (
                  <Button type="button" disabled>
                    {' '}
                    Sign Up{' '}
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
        </Container>
      ) : (
        <Container style={{ maxWidth: '500px' }} fluid>
          {userRecord && <Navigate to="/welcome" />}
        </Container>
      )}
    </>
  );
}

export default SignUpPage;
