import * as React from 'react';
const FeedsIcon = (props: any) => (
  <svg
    viewBox="3.648 2.966 30 31.856"
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={31.856}
    {...props}
  >
    <path d="M13.096 11.164v0.812h-0.54l0.016 1.595 0.016 1.595h-0.576l0.016 1.08 0.016 1.08h-0.54v1.086l-0.268 -0.011 -0.272 -0.016 -0.011 1.61 -0.011 1.605 -1.6 -0.005 -1.595 -0.005v1.086h-0.566v2.162h3.242v2.675h-0.54l0.011 1.064 0.011 1.07 1.595 0.016 1.6 0.011V28.027h0.55l-0.005 -1.606 -0.005 -1.606 0.272 0.016 0.268 0.011v-3.25l0.262 0.011 0.257 0.016 -0.005 -1.62 -0.005 -1.615 2.166 0.016 2.162 0.016v-1.086h0.515v-2.162h-3.756v-2.146l0.283 0.026 0.283 0.026 -0.011 -1.348 -0.016 -1.348 -1.61 -0.011 -1.606 -0.011v0.808Z" />
    <path d="m16.174 11.704 0.011 1.199h-0.2c-0.108 0 -0.226 0.032 -0.257 0.068 -0.036 0.046 -0.061 0.452 -0.068 1.132 -0.016 0.92 -0.005 1.07 0.068 1.142 0.076 0.076 0.283 0.088 1.862 0.076l1.775 -0.005 0.02 0.35c0.011 0.191 0.02 0.602 0.02 0.916l-0.005 0.566 -0.154 0.016c-0.34 0.032 -0.37 0.083 -0.38 0.566 -0.005 0.241 -0.02 0.458 -0.036 0.484 -0.016 0.02 -0.947 0.041 -2.074 0.041h-2.054l-0.056 0.14c-0.036 0.092 -0.061 0.664 -0.068 1.595l-0.011 1.456h-0.16c-0.35 0 -0.35 0 -0.37 1.42 -0.011 0.704 -0.016 1.404 -0.016 1.548v0.272h-0.196c-0.134 0 -0.211 0.032 -0.252 0.098 -0.041 0.068 -0.068 0.55 -0.076 1.59l-0.016 1.492 -0.2 0.026a0.644 0.644 0 0 0 -0.247 0.076c-0.026 0.026 -0.046 0.344 -0.046 0.71 0 0.364 -0.016 0.704 -0.026 0.752 -0.026 0.088 -0.14 0.092 -1.492 0.092h-1.461l0.016 -0.942 0.011 -0.936 0.196 -0.005c0.108 -0.005 0.22 -0.032 0.247 -0.056 0.083 -0.083 0.076 -2.716 -0.005 -2.819 -0.056 -0.068 -0.283 -0.076 -1.6 -0.076H7.336v-1.852h0.206c0.292 0 0.32 -0.072 0.355 -0.968l0.005 -0.112h1.522c1.394 0 1.534 -0.011 1.595 -0.092 0.052 -0.068 0.068 -0.422 0.072 -1.584v-1.498l0.211 -0.032a0.679 0.679 0 0 0 0.262 -0.076c0.026 -0.026 0.052 -0.262 0.052 -0.52v-0.468h0.191c0.34 0 0.36 -0.052 0.376 -1.158l0.016 -0.978 0.176 -0.005a0.324 0.324 0 0 0 0.272 -0.124c0.098 -0.118 0.104 -0.196 0.083 -1.606l-0.016 -1.482h0.191c0.324 0 0.35 -0.068 0.35 -0.868 0 -0.392 0.016 -0.724 0.036 -0.746 0.02 -0.02 0.674 -0.032 1.456 -0.02l1.414 0.016 0.016 1.194Z" />
    <path d="m21.128 11.152 0.005 0.796 -0.262 0.016 -0.262 0.016v3.185h-0.54l0.016 1.62 0.016 1.62h-0.576l0.016 1.595 0.016 1.595h-4.296v1.08h-0.566v2.162l1.878 -0.011 1.878 -0.016v1.039c0 0.576 -0.005 1.06 -0.011 1.08 -0.011 0.026 -0.124 0.032 -0.257 0.026l-0.247 -0.02v2.737h3.19v-1.646h0.566v-3.218l0.262 0.005h0.257l-0.005 -1.07 -0.005 -1.064h0.581l-0.02 -0.54 -0.016 -0.54h0.566v-3.194l1.61 0.005 1.615 0.005 -0.02 -0.544 -0.016 -0.54h0.54v-2.162h-3.236l-0.005 -1.338v-1.337h0.566v-2.135h-3.246l0.011 0.798Z" />
    <path d="M24.23 10.566c0.016 0.046 0.032 0.464 0.032 0.926 0 0.716 -0.011 0.844 -0.076 0.848 -0.041 0.005 -0.149 0.011 -0.232 0.016 -0.088 0 -0.18 0.041 -0.206 0.083 -0.061 0.092 -0.056 0.056 -0.052 1.512 0 1.018 0.016 1.194 0.088 1.276 0.076 0.088 0.2 0.092 1.59 0.092h1.507l0.016 0.479c0.011 0.262 0.011 0.679 0 0.926l-0.016 0.448h-0.18c-0.288 0 -0.329 0.083 -0.329 0.612v0.468h-1.528c-1.244 -0.005 -1.538 0.011 -1.595 0.068 -0.056 0.056 -0.072 0.262 -0.072 0.942a35.505 35.505 0 0 1 -0.026 1.518l-0.032 0.654 -0.18 0.026c-0.298 0.052 -0.324 0.104 -0.324 0.608v0.458h-0.176c-0.268 0 -0.34 0.104 -0.344 0.504 0 0.191 -0.011 0.54 -0.02 0.782 -0.011 0.242 -0.02 0.535 -0.02 0.654 -0.005 0.211 -0.011 0.22 -0.17 0.22a0.416 0.416 0 0 0 -0.257 0.088c-0.088 0.076 -0.092 0.196 -0.092 1.595v1.507h-0.176c-0.298 0 -0.34 0.112 -0.34 0.936v0.71h-2.984v-2.418h0.22c0.124 0 0.247 -0.026 0.283 -0.061 0.088 -0.088 0.088 -2.207 0 -2.294 -0.046 -0.046 -0.535 -0.061 -1.878 -0.061h-1.816v-1.852h0.2c0.272 0 0.344 -0.098 0.324 -0.468a2.05 2.05 0 0 1 0.016 -0.458l0.036 -0.154h2.038c1.822 0 2.042 -0.011 2.13 -0.088 0.088 -0.076 0.092 -0.196 0.092 -1.595v-1.507h0.18c0.314 0 0.355 -0.104 0.36 -0.884 0.011 -1.986 0.026 -2.366 0.092 -2.341 0.036 0.016 0.14 -0.005 0.232 -0.046l0.164 -0.068 0.005 -1.368a54.608 54.608 0 0 1 0.02 -1.414c0.005 -0.032 0.016 -0.108 0.02 -0.18 0.005 -0.112 0.026 -0.128 0.211 -0.128 0.149 0 0.216 -0.026 0.257 -0.098 0.026 -0.056 0.052 -0.427 0.052 -0.824v-0.724h1.462c1.286 0 1.466 0.011 1.492 0.083Z" />
    <path d="M24.168 11.771c0 0.072 0.011 0.098 0.026 0.061a0.258 0.258 0 0 0 0 -0.128c-0.016 -0.032 -0.026 -0.005 -0.026 0.068ZM7.748 15.706v0.54h-0.566v2.162h3.242v-1.08h0.515v-1.08h0.566v-1.08H7.748v0.54Z" />
    <path
      d="m23.808 22.136 0.016 0.54h-0.544l0.016 0.54 0.016 0.54h-0.566l0.011 0.524 0.005 0.53 1.888 0.016 1.888 0.011 -0.02 -0.54 -0.016 -0.54h0.535l0.016 -1.06c0.011 -0.581 0.005 -1.07 -0.005 -1.08 -0.011 -0.011 -0.752 -0.02 -1.642 -0.02h-1.615l0.02 0.54Z"
      style={{
        paintOrder: 'fill',
      }}
    />
    <path d="M5.708 3.088V6.24H4.082v28.37h26.021v-1.578h3.248V3.088H5.71Zm0 29.945V7.813h22.765v25.218H5.708Zm26.017 -1.574h-1.624V6.24H7.334V4.662h24.394v26.796Z" />
  </svg>
);
export default FeedsIcon;
