import React, { useContext } from 'react';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  Hits,
  Highlight,
  Index,
  useInstantSearch,
} from 'react-instantsearch-hooks-web';
import type { Hit as AlgoliaHit } from 'instantsearch.js/es/types';
import { CacheContext, CacheContextType } from '../context/CacheContext';
import { Autocomplete } from './Autocomplete';
import { Paper, Stack, Box } from '@mui/material';
import { replaceTagsWithLinks } from '../lib';
import { Link, useNavigate } from 'react-router-dom';

type HitProps = {
  hit: AlgoliaHit<{
    feedTag?: string;
    username?: string;
    value?: string;
    userRecordID?: string;
  }>;
};

// function customSearchBox(props: UseSearchBoxProps) {
//   const { query, refine } = useSearchBox(props);

//   return <></>;
// }

function SearchBar({
  parentRef,
}: {
  parentRef: React.RefObject<HTMLDivElement>;
}) {
  function FeedHit({ hit }: HitProps) {
    const { setUiState } = useInstantSearch();
    let navigate = useNavigate();
    const RouteChange = () => {
      let path = `/${hit.username}`;
      setUiState({});

      navigate(path);
    };
    return (
      <Box onClick={RouteChange}>
        <Stack direction="row" spacing={1}>
          <small>Feed</small>
          <h3>
            {' '}
            <Highlight attribute="feedTag" hit={hit} />
          </h3>
        </Stack>
      </Box>
    );
  }
  function PostHit({ hit }: HitProps) {
    const userRecord = useContext<CacheContextType>(CacheContext).getUserRecord(
      hit.userRecordID ?? '',
    );
    const { setUiState } = useInstantSearch();
    let navigate = useNavigate();
    const RouteChange = () => {
      let path = '/' + userRecord?.username;
      setUiState({});

      navigate(path);
    };
    return (
      <Box onClick={RouteChange}>
        <Stack direction="row" spacing={1}>
          <small>Post</small>
          <Link to={'/' + userRecord?.username}>
            <h3> @{userRecord?.username}</h3>
          </Link>
        </Stack>
        <Box> {replaceTagsWithLinks(hit.value ?? '')}</Box>
      </Box>
    );
  }
  function UserHit({ hit }: HitProps) {
    const { setUiState } = useInstantSearch();
    let navigate = useNavigate();
    const RouteChange = () => {
      let path = `/${hit.username}`;
      setUiState({});

      navigate(path);
    };

    return (
      <Box onClick={RouteChange}>
        <Stack direction="row" spacing={1}>
          <small>User</small>

          <h3>
            <Link to={`/${hit.username}`}>
              <Highlight attribute="username" hit={hit} />
            </Link>
          </h3>
        </Stack>
      </Box>
    );
  }

  const [searchState, setSearchState] = React.useState<string>('');

  const originalSearchClient = algoliasearch(
    'QNU3DK36V7',
    'ad1987e4a773787974d5bf08405b1f15',
  );

  const searchClient = {
    ...originalSearchClient,
    // @ts-ignore
    search(requests) {
      // @ts-ignore
      if (requests.every(({ params }) => !params.query)) {
        setSearchState('');
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            page: 0,
            processingTimeMS: 0,
            hitsPerPage: 0,
            exhaustiveNbHits: false,
            query: '',
            params: '',
          })),
        });
      } else setSearchState(requests[0].params.query);
      return originalSearchClient.search(requests);
    },
  };

  return (
    //@ts-ignore
    <InstantSearch searchClient={searchClient} indexName="wipe_users_index">
      <Autocomplete
        placeholder="Search"
        detachedMediaQuery="none"
        openOnFocus
      />{' '}
      {searchState.length !== 0 && (
        <Paper
          elevation={4}
          id="autocomplete"
          style={{
            position: 'absolute',
            top: '60px',
            width: parentRef.current?.offsetWidth,
            overflow: 'auto',
          }}
        >
          <Hits hitComponent={UserHit} />
          <Index indexName="wipe_feeds_index">
            <Hits hitComponent={FeedHit} />
          </Index>
          <Index indexName="wipe_posts_index">
            <Hits hitComponent={PostHit} />
          </Index>
        </Paper>
      )}
    </InstantSearch>
  );
}

export default SearchBar;
