import React, {
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
  useContext,
} from 'react';

import { Stack } from '@mui/system';
import { Box } from '@mui/material';
import { PostData, FollowData } from '../model/types';
import Post from './postComponent';
import { CacheContext, CacheContextType } from '../context/CacheContext';

function Replies({
  parentObject,
  expandToReply,
  setShowReplies,
}: {
  parentObject: PostData | FollowData;
  expandToReply?: string;
  setShowReplies: any;
}) {
  let getPosts = useContext<CacheContextType>(CacheContext).allPosts;
  const [posts, setPosts] = useState<PostData[]>([]);
  const replyRef = useRef(null);
  const executeScroll = () => {
    // replyRef is not set currently
    // replyRef?.current?.scrollIntoView();
  };

  useLayoutEffect(() => {
    setTimeout(() => executeScroll(), 500);
  }, []);

  useEffect(() => {
    let snapshotPosts = getPosts();
    setPosts(snapshotPosts.filter((post) => post.parentID === parentObject.id));

    if (expandToReply !== undefined) {
      setShowReplies(true);
      let foundReply =
        parentObject.id === expandToReply ||
        parentObject.posts?.includes(expandToReply);
      if (foundReply) {
        executeScroll();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPosts]);

  if (!parentObject || !posts || posts === undefined || posts.length === 0)
    return (
      <Box
        sx={{
          color: 'text.primary',
          boxSizing: 'border-box',
          background: 'white',
          margin: '0 auto',
          borderRadius: '3px',
          padding: 1,
          width: '100%',
        }}
      >
        {' '}
        No replies yet
      </Box>
    );
  return (
    <Stack>
      {posts
        .sort((a, b) =>
          a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0,
        )
        .map((reply) => (
          <div
            key={reply.id}
            id={reply.id}
            ref={reply.id === expandToReply ? replyRef : null}
          >
            <Post post={reply} expandToReply={expandToReply} />
          </div>
        ))}
    </Stack>
  );
}
export default Replies;
