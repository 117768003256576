import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Stack } from '@mui/system';
import { Box } from '@mui/material';
import { DateTime } from 'luxon';
import TimeDuration from '../TimeDuration/index';
import VisibilitySensor from 'react-visibility-sensor';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebaseServices';
import { CacheContext, CacheContextType } from '../context/CacheContext';
import reactStringReplace from 'react-string-replace';

function Notification({ notification }: { notification: any }) {
  const [isSeen, setIsSeen] = React.useState(false);
  const getUserRecord =
    useContext<CacheContextType>(CacheContext).getUserRecord;

  const markAsSeenInDB = async (id: string) => {
    const notificationRef = doc(db, 'notifications', id);
    updateDoc(notificationRef, { read: true });
  };

  function onChange(isVisible: boolean) {
    if (isVisible && !isSeen && !notification.read) {
      setIsSeen(true);
      notification.read = true;
      markAsSeenInDB(notification.id);
    }
  }

  function populateWithLinks(message: string) {
    let newMessage = message;
    const regexpUsername = new RegExp(/@([A-Za-z0-9_]+)/gm);
    const regexpLinks = new RegExp(/\[.*?\]\((.*?)\)/gm);
    const regexpMessageUsername = new RegExp(/@\[(.*?)\]\(.*?\)/gm);
    const regexpFeed = new RegExp(/#\[(.*?)\]\(.*?\)/gm);
    const regexpFeedRaw = new RegExp(/#([A-Za-z0-9_]+)/gm);

    let replacedText;
    replacedText = reactStringReplace(
      newMessage,
      regexpUsername,
      (match, i) => {
        const username = getUserRecord(match)?.username;
        return (
          <Link to={'/' + username} key={match + i}>
            @{username}
          </Link>
        ) as any;
      },
    );

    replacedText = reactStringReplace(
      replacedText,
      regexpMessageUsername,
      (match, i) => {
        return (
          <Link to={'/' + match.replace('@', '')} key={match + i}>
            @{match}
          </Link>
        ) as any;
      },
    );

    replacedText = reactStringReplace(replacedText, regexpLinks, (match, i) => {
      const objectText = newMessage.includes('following')
        ? 'following'
        : notification.message.objectType.slice(0, -1);
      return (
        <Link
          to={'/' + notification.message.objectType + '/' + match}
          key={match + i}
        >
          {objectText}
        </Link>
      ) as any;
    });

    replacedText = reactStringReplace(
      replacedText,
      regexpFeedRaw,
      (match, i) => {
        return (
          <Link to={'/feeds/' + match.replace('#', '')} key={match + i}>
            {match}
          </Link>
        ) as any;
      },
    );
    replacedText = reactStringReplace(replacedText, regexpFeed, (match, i) => {
      return (
        <Link to={'/feeds/' + match} key={match + i}>
          #{match}
        </Link>
      ) as any;
    });

    return replacedText;
  }

  return (
    <VisibilitySensor onChange={onChange}>
      <Box
        sx={{
          boxSizing: 'border-box',
          background: !notification.read ? '#d8ecf3' : 'white',
          margin: '0 auto',
          borderRadius: '3px',
          padding: 1,
          width: '100%',
        }}
        id={notification.id}
      >
        <Stack direction="row" spacing={1}>
          <Stack direction="column">
            <Stack direction="row" spacing={0.5}>
              <Box
                sx={{
                  color: 'black',
                  fontSize: '16px',
                  fontWeight: 700,
                }}
              >
                {notification.objectType}
              </Box>
              <Box
                sx={{
                  color: 'rgb(83, 100, 113)',
                  fontSize: '16px',
                  fontWeight: 400,
                }}
              >
                -
              </Box>
              <Box
                sx={{
                  color: 'rgb(83, 100, 113)',
                  fontSize: '16px',
                  fontWeight: 400,
                }}
              >
                <TimeDuration
                  jsDate={DateTime.fromSeconds(
                    notification.createdAt.seconds,
                  ).toJSDate()}
                  invertSign={true}
                  titleDisplay="absoluteTime"
                />{' '}
                ago
              </Box>
              <Box
                sx={{
                  color: 'rgb(83, 100, 113)',
                  fontSize: '16px',
                  fontWeight: 400,
                }}
              >
                -
              </Box>
            </Stack>
            <Box
              sx={{
                color: 'black',
                fontSize: '16px',
                fontWeight: 400,
              }}
            >
              {populateWithLinks(notification.message.message)}
            </Box>
          </Stack>
        </Stack>
      </Box>
    </VisibilitySensor>
  );
}

export default Notification;
