import React, { useEffect, useState } from 'react';
import { AuthContext, MyContext } from '../context/AuthContext';
import { Followable, FollowData } from '../model/types';
import { Button, Box } from '@mui/material';
import SubmitPostComponent from '../SubmitPostComponent/SubmitPostComponent';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import LinkIcon from '../resource/LinkIcon';
import FollowIcon from '../resource/FollowIcon';
function FollowButton({
  parentRecord,
  objectType,
  isPermanent = false,
}: {
  parentRecord: Followable;
  objectType: string;
  isPermanent?: boolean;
}) {
  const userRecord = React.useContext<MyContext>(AuthContext).userRecord;
  const getFollows = React.useContext<MyContext>(AuthContext).getFollows;
  const [expand, setExpand] = useState(false);
  const [followState, setFollowState] = useState('notFollowing');
  const [follow, setFollow] = useState<FollowData | undefined>(undefined);

  const toggleAcordion = () => {
    setExpand((prev) => !prev);
  };

  function followIsExpired(follow: FollowData) {
    if (
      follow.isExpired ||
      DateTime.fromSeconds(follow.expireAt.seconds).toJSDate() < new Date()
    )
      return true;

    return false;
  }
  function handleSubmit() {
    toggleAcordion();
  }

  useEffect(() => {
    if (!userRecord) return;
    getFollows().forEach((follow) => {
      if (follow.parentID === parentRecord.id) {
        setFollow(follow);
        if (followIsExpired(follow)) {
          setFollowState('expired');
        } else setFollowState('following');
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRecord, getFollows, follow]);

  if (!userRecord) {
    return <div> Login to follow </div>;
  }
  return (
    <div>
      {followState === 'notFollowing' && (
        <Button onClick={() => toggleAcordion()}>
          {objectType === 'users' ? (
            <FollowIcon style={{ width: 30, height: 25 }} />
          ) : (
            'Follow ' + objectType.slice(0, -1)
          )}
        </Button>
      )}
      {followState === 'following' && (
        <Link to={`/follows/${follow?.id}`}>
          {' '}
          <Button variant="outlined">
            Following <LinkIcon style={{ display: 'inline-block' }} />
          </Button>
        </Link>
      )}

      {followState === 'expired' && (
        <Link to={`/follows/${follow?.id}`}>
          {' '}
          <Button variant="outlined">
            Follow Expired <LinkIcon style={{ display: 'inline-block' }} />
          </Button>
        </Link>
      )}
      {expand && (
        <Box>
          Why are you following this {objectType.slice(0, -1)}?
          <SubmitPostComponent
            objectType="follows"
            parentID={parentRecord.id}
            parentType={objectType}
            onSubmit={handleSubmit}
          />
        </Box>
      )}
    </div>
  );
}

export default FollowButton;
