import React from 'react';
import { Container, useMediaQuery } from '@mui/material';
import WipeHouseCatchPhrase from '../resource/WipeHouseCatchPhrase';
import '../fonts.css';
import TimeWalletIcon from '../resource/TimeWalletIcon';
import TombstoneIcon from '../resource/TombstoneIcon';

import WLogoAnimated from '../resource/WLogoAnimated';

import { LinkPreview } from '@dhaiwat10/react-link-preview';

function AboutPage() {
  const customFetcher = async (url: string) => {
    const response = await fetch(
      `https://wipe-house.herokuapp.com/v2?url=${url}`,
    );
    const json = await response.json();
    return json.metadata;
  };
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Container
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      {' '}
      <header
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 auto',
          marginTop: '5rem',
          alignItems: 'center',
        }}
      >
        <WipeHouseCatchPhrase
          className="font-face-nokia"
          style={{ transform: isMobile ? 'scale(2)' : 'scale(3)' }}
        />
        <h4
          style={{
            marginLeft: '20rem',
          }}
        >
          .
        </h4>
      </header>
      <div
        style={{
          minWidth: '85%',
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 auto',
          marginTop: '3rem',
          alignItems: 'center',
        }}
      >
        <h1
          style={{
            display: 'flex',
            alignItems: 'space-between',
          }}
        >
          {' '}
          In Time
        </h1>
        <div style={{ display: 'inline-block' }}>
          In 2011, Justin Timberlake and Amanda Seyfried starred in a cinematic
          masterwork titled "In Time". The film depicts a world whose only
          currency is time. People are paid in time, they buy things with time,
          and everything they do takes time. When they run out of time, they
          die. Wipe is a social media site that operates on the same principal.{' '}
          <br></br>
          <br></br>
          <br></br>
        </div>
        <LinkPreview
          url="https://www.youtube.com/watch?v=xhYUaR5QiUs"
          width="50%"
          fetcher={customFetcher}
        />
      </div>
      <div
        style={{
          minWidth: '85%',
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 auto',
          marginTop: '3rem',
          alignItems: 'center',
        }}
      >
        <h1
          style={{
            display: 'flex',
            alignItems: 'space-between',
          }}
        >
          {' '}
          <WLogoAnimated
            style={{
              display: 'inline-block',
              width: '40px',
              height: '40px',
            }}
          />{' '}
          Welcome to Wipe
          <WLogoAnimated
            style={{
              display: 'inline-block',
              width: '40px',
              height: '40px',
            }}
          />
        </h1>
        <div style={{ display: 'inline-block' }}>
          Wipe is a time-based microblogging platform; like Mastodon, but with a
          twist. Everything you do takes time, Wipe just keeps track of it. When
          a user signs up, they are given a <b>time wallet</b>{' '}
          <TimeWalletIcon
            style={{
              display: 'inline-block',
              paddingTop: '0.4rem',
              width: '30px',
              height: '30px',
            }}
          />{' '}
          with a year of time. When they submit a post or follow someone, they
          set a lifespan on that action, which is withdrawn from their time
          wallet. When the time runs out, the post or follow <b>expires</b>{' '}
          <TombstoneIcon
            style={{
              display: 'inline-block',
              paddingTop: '0.4rem',
              width: '30px',
              height: '30px',
            }}
          />
          , hiding its content. Expired posts aren't deleted immediately,
          instead they hang around until the next Wipe. <br></br>
          <br></br>
        </div>
      </div>
    </Container>
  );
}

export default AboutPage;
