import React, { useState, useContext } from 'react';
import {
  Button,
  Container,
  Stack,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  useMediaQuery,
} from '@mui/material';
import '../fonts.css';
import { AuthContext, MyContext } from '../context/AuthContext';

import TimeWalletIcon from '../resource/TimeWalletIcon';
import TombstoneIcon from '../resource/TombstoneIcon';
import ClockIcon from '../resource/ClockIcon';
import HourglassUpIcon from '../resource/HourglassUpIcon';
import HourglassDownIcon from '../resource/HourglassDownIcon';
import WipeAnimated from '../resource/WipeAnimated';
import WLogoAnimated from '../resource/WLogoAnimated';
import HourglassFullIcon from '../resource/HourglassFullIcon';
import TrophyIcon from '../resource/TropyIcon';
import TimePicker from '../timePickerComponent/timePickerComponent';
import { TimeSelection } from '../model/types';
import useLongPress from '../useLongPress';
import SettingsIcon from '../resource/SettingsIcon';
import PermanenceIcon from '../resource/PermanenceIcon';
import ProfileIcon from '../resource/ProfileIcon';
import FeedIcon from '../resource/FeedIcon';
import FeedsIcon from '../resource/FeedsIcon';
import HomeIcon from '../resource/HomeIcon';
import FollowIcon from '../resource/FollowIcon';
import WipeHouseCatchPhrase from '../resource/WipeHouseCatchPhrase';

function WelcomePage() {
  const userRecord = useContext<MyContext>(AuthContext).userRecord;
  const isMobile = useMediaQuery('(max-width:600px)');

  const [showClock, setShowClock] = useState(false);
  const [showClock2, setShowClock2] = useState(false);
  const ref = React.useRef(null);
  const ref2 = React.useRef(null);
  const [time, setTime] = useState<TimeSelection>({
    days: 0,
    hours: 1,
    minutes: 0,
  });
  const [time2, setTime2] = useState<TimeSelection>({
    days: 0,
    hours: 1,
    minutes: 0,
  });
  const toggleClock = () => {
    setShowClock((prev) => !prev);
  };
  const toggleClock2 = () => {
    setShowClock2((prev) => !prev);
  };

  const onLongPress = () => {
    toggleClock();
  };
  const onLongPress2 = () => {
    toggleClock2();
  };
  const onClick = () => {
    handleSubmit();
  };
  const onClick2 = () => {
    handleSubmit2();
  };

  function handleSubmit2() {
    setTime({
      days: 0,
      hours: time.hours - time2.hours >= 0 ? time.hours - time2.hours : 0,
      minutes: 0,
    });
  }
  function handleSubmit() {}
  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 300,
  };
  const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);
  const longPressEvent2 = useLongPress(onLongPress2, onClick2, defaultOptions);
  return (
    <Container
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <header
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 auto',
          marginTop: '5rem',
          alignItems: 'center',
        }}
      >
        <WipeHouseCatchPhrase
          className="font-face-nokia"
          style={{ transform: isMobile ? 'scale(2)' : 'scale(3)' }}
        />
        <h4
          style={{
            marginLeft: '20rem',
          }}
        >
          .
        </h4>
      </header>
      <div
        style={{
          minWidth: '85%',
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 auto',
          marginTop: '5rem',
          alignItems: 'center',
        }}
      >
        <h1
          style={{
            display: 'flex',
            alignItems: 'space-between',
          }}
        >
          {' '}
          <WLogoAnimated
            style={{
              display: 'inline-block',
              width: '40px',
              height: '40px',
            }}
          />{' '}
          Welcome to Wipe
          {userRecord?.username ? ', @' + userRecord?.username : ''}!{' '}
          <WLogoAnimated
            style={{
              display: 'inline-block',
              width: '40px',
              height: '40px',
            }}
          />
        </h1>
        <div style={{ display: 'inline-block' }}>
          Wipe is a time-based microblogging platform; like Mastodon, but with a
          twist. Everything you do takes time, Wipe just keeps track of it. When
          a user signs up, they are given a <b>time wallet</b>{' '}
          <TimeWalletIcon
            style={{
              display: 'inline-block',
              paddingTop: '0.4rem',
              width: '30px',
              height: '30px',
            }}
          />{' '}
          with a year of time. When they submit a post or follow someone, they
          set a lifespan on that action, which is withdrawn from their time
          wallet. When the time runs out, the post or follow <b>expires</b>{' '}
          <TombstoneIcon
            style={{
              display: 'inline-block',
              paddingTop: '0.4rem',
              width: '30px',
              height: '30px',
            }}
          />
          , hiding its content. Expired posts aren't deleted immediately,
          instead they hang around until the next Wipe. <br></br>
          <br></br>
        </div>
      </div>
      <div
        style={{
          minWidth: '85%',
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 auto',
          alignItems: 'center',
        }}
      >
        <h1>
          {' '}
          <HourglassFullIcon
            style={{
              display: 'inline-block',
              paddingTop: '0.4rem',
              width: '40px',
              height: '40px',
            }}
          />
          Each month begins with a
          <WipeAnimated
            style={{
              display: 'inline-block',
              paddingTop: '0.4rem',
              width: '160px',
              height: '40px',
            }}
          />
        </h1>{' '}
        <div style={{ display: 'inline-block' }}>
          On Wipe, the platform owns deletes. When a wipe happens, all expired
          objects are deleted. This is the only way to delete anything on Wipe.
          Wipes form natural seasons to compete against housemates. The
          leaderboard{' '}
          <TrophyIcon
            style={{
              display: 'inline-block',
              paddingTop: '0.4rem',
              width: '30px',
              height: '30px',
            }}
          />{' '}
          tracks the most active users, the most popular posts, and most
          discussed topics for each wipe.
        </div>
      </div>

      <div
        style={{
          minWidth: '85%',
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 auto',
          alignItems: 'center',
        }}
      >
        <h1>
          {' '}
          <HourglassUpIcon
            style={{
              display: 'inline-block',
              width: '40px',
              height: '40px',
            }}
          />{' '}
          Giving and taking Time{' '}
          <HourglassDownIcon
            style={{
              display: 'inline-block',
              width: '40px',
              height: '40px',
            }}
          />
        </h1>{' '}
        <div style={{ display: 'inline-block' }}>
          Although it's not possible to delete or edit posts, they can be voted
          on. If you don't want to see a post, you can hasten its expiry by
          voting it down{' '}
          <HourglassDownIcon
            style={{
              display: 'inline-block',
              paddingTop: '0.4rem',
              width: '20px',
              height: '30px',
            }}
          />
          . Likewise, you can vote a post up{' '}
          <HourglassUpIcon
            style={{
              display: 'inline-block',
              paddingTop: '0.4rem',
              width: '20px',
              height: '30px',
            }}
          />{' '}
          to keep it alive longer. Like posts and follows, votes use time from
          your wallet.
        </div>
      </div>
      <div
        style={{
          minWidth: '85%',
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 auto',
          alignItems: 'center',
        }}
      >
        <h1>
          {' '}
          <TimeWalletIcon
            style={{
              display: 'inline-block',
              paddingTop: '0.4rem',
              width: '40px',
              height: '40px',
            }}
          />{' '}
          Earning Time{' '}
          <TimeWalletIcon
            style={{
              display: 'inline-block',
              paddingTop: '0.4rem',
              width: '40px',
              height: '40px',
            }}
          />
        </h1>{' '}
        <div style={{ display: 'inline-block' }}>
          It can be tough out there to spend all this time and never get any
          back. For that reason, we've implemented a few ways to earn time on
          Wipe:
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <TableContainer style={{ marginLeft: '2rem' }}>
              <Table sx={{ minWidth: 200 }}>
                <TableBody style={{ border: 0 }}>
                  <TableRow
                    sx={{
                      '& td': {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ borderBottom: 'none' }}
                    >
                      <WLogoAnimated
                        style={{
                          display: 'inline-block',
                          width: '40px',
                          height: '40px',
                        }}
                      />
                    </TableCell>
                    <TableCell align="left">
                      Users are given an additional 30 days of time each wipe.
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{
                      '& td': {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ borderBottom: 'none' }}
                    >
                      <HourglassUpIcon
                        style={{
                          display: 'inline-block',
                          width: '30px',
                          height: '30px',
                        }}
                      />
                    </TableCell>
                    <TableCell align="left">
                      {' '}
                      Whenever one of your posts is voted beyond its initial
                      expiration date, you'll be given time equal to the
                      difference
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      '& td': {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ borderBottom: 'none' }}
                    >
                      <FollowIcon
                        style={{
                          display: 'inline-block',
                          width: '30px',
                          height: '30px',
                        }}
                      />{' '}
                    </TableCell>
                    <TableCell align="left">
                      {' '}
                      Whenever a user follows you, you'll get the time from that
                      follow
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      '& td': {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ borderBottom: 'none' }}
                    >
                      <TrophyIcon
                        style={{
                          display: 'inline-block',
                          width: '30px',
                          height: '30px',
                        }}
                      />{' '}
                    </TableCell>
                    <TableCell align="left">
                      {' '}
                      If you earn a place on the leaderboard for the wipe,
                      you'll be rewarded with a generous time bounty.
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      <div
        style={{
          minWidth: '85%',
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 auto',
          alignItems: 'center',
        }}
      >
        <h1>
          {' '}
          <ClockIcon
            style={{
              display: 'inline-block',
              width: '40px',
              height: '40px',
            }}
          />{' '}
          Timing is Everything{' '}
          <ClockIcon
            style={{
              display: 'inline-block',
              width: '40px',
              height: '40px',
            }}
          />
        </h1>
        You can choose how much time to spend on each action by clicking and
        holding the action button, this will make the time picker appear. If
        you're on a computer, you can scroll to increase and decrease the time
        you're spending. If you're on a phone, you can drag the selector
        clockwise to increase the time, and counterclockwise to decrease it.
        Let's practice:
        <Box ref={ref}>
          <Stack direction="row" spacing={2}>
            {showClock ? (
              <Stack direction="row" spacing={2}>
                <Button disabled>Try Me!</Button>
              </Stack>
            ) : (
              <Stack direction="row" spacing={2}>
                <Button
                  variant="outlined"
                  {...longPressEvent}
                  sx={{ touchAction: 'none' }}
                >
                  Try Me!
                </Button>{' '}
              </Stack>
            )}
            <TimePicker
              time={time}
              setTime={setTime}
              submit={handleSubmit}
              showClock={showClock}
              toggleClock={toggleClock}
              parentRef={ref}
            />
          </Stack>{' '}
        </Box>
        You selected{' '}
        <b>
          {Math.floor(time.hours / 24)} days, {time.hours % 24} hours,{' '}
          {time.minutes} minutes.
        </b>
        Now let's say you want to remove time from whatever you just posted.
        Click and hold the downvote button to below to take time away:
        <Box ref={ref2}>
          <Stack direction="row" spacing={2}>
            {showClock2 ? (
              <Stack direction="row" spacing={2}>
                <Button disabled>
                  <HourglassDownIcon />
                </Button>
              </Stack>
            ) : (
              <Stack direction="row" spacing={2}>
                <Button {...longPressEvent2} sx={{ touchAction: 'none' }}>
                  <HourglassDownIcon />{' '}
                </Button>{' '}
              </Stack>
            )}
            <TimePicker
              time={time2}
              setTime={setTime2}
              submit={handleSubmit2}
              showClock={showClock2}
              toggleClock={toggleClock2}
              parentRef={ref2}
            />
          </Stack>{' '}
        </Box>
        If you don't want to bother with the time picker, you can just click the
        buttons to add or remove time based on your user settings, which default
        to:
        <b>
          1 day for posts <br></br>1 hour for votes<br></br> 5 days for follows
        </b>{' '}
        You can change these settings at any time via the settings page in the
        side panel:
        <SettingsIcon
          style={{
            display: 'inline-block',
            width: '40px',
            height: '40px',
          }}
        />
      </div>
      <div
        style={{
          minWidth: '85%',
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 auto',
          alignItems: 'center',
        }}
      >
        <h1>
          {' '}
          <PermanenceIcon
            style={{
              display: 'inline-block',
              paddingTop: '0.4rem',
              width: '40px',
              height: '40px',
            }}
          />
          Keep it Forever
          <PermanenceIcon
            style={{
              display: 'inline-block',
              paddingTop: '0.4rem',
              width: '40px',
              height: '40px',
            }}
          />
        </h1>{' '}
        <div style={{ display: 'inline-block' }}>
          We may have fudged things a bit when we said that the platform owns
          Delete. There's one thing you can do to keep stuff around forever -
          you can make it permanent. Permanent posts and follows are not deleted
          during a wipe, and can never expire, but be wary- they're also here to
          stay. Once something has been made permanent, it can't be undone.
          <br></br>
          <b>Anyone</b> can make <b>any</b> post or follow permanent by click
          the permavote button{' '}
          <PermanenceIcon
            style={{
              display: 'inline-block',
              paddingTop: '0.4rem',
              width: '40px',
              height: '40px',
            }}
          />
          . You can also mark a post or follow as permanent from the beginning
          by clicking the{' '}
          <Button>
            Post Permanently
            <PermanenceIcon />
          </Button>{' '}
          or{' '}
          <Button>
            Follow Permanently
            <PermanenceIcon />
          </Button>{' '}
          buttons on submission. <br></br>
          <b>
            {' '}
            Permanent posts and follows are surfaced at the top of a user's
            profile{' '}
            <ProfileIcon
              style={{
                display: 'inline-block',
                paddingTop: '0.4rem',
                width: '40px',
                height: '40px',
              }}
            />
            , so be sure not to say anything stupid enough that you'll regret it
            when someone makes it last forever.
          </b>{' '}
          To mark things permanent, you spend Permanence Tokens (also denoted by{' '}
          <PermanenceIcon
            style={{
              display: 'inline-block',
              paddingTop: '0.4rem',
              width: '40px',
              height: '40px',
            }}
          />
          next to your time wallet in the top right corner of the screen ). You
          only start with 10, and get 1 per wipe, so use them wisely.
        </div>
      </div>

      <div
        style={{
          minWidth: '85%',
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 auto',
          alignItems: 'center',
        }}
      >
        <h1>
          {' '}
          <FeedIcon
            style={{
              display: 'inline-block',
              width: '40px',
              height: '40px',
            }}
          />{' '}
          Feeding Time{' '}
          <FeedIcon
            style={{
              display: 'inline-block',
              width: '40px',
              height: '40px',
            }}
          />
        </h1>{' '}
        <div style={{ display: 'inline-block' }}>
          Feeds work like hashtags. You can follow a feed by clicking the follow
          button next to it. You can also create your own feed by posting a new
          #tag. Feeds are a great way to find content you're interested in, but
          they also expire. Each post made tagging a feed adds its lifespan to
          the feed's. When a feed expires, it doesn't delete the posts that were
          tagged with it, but it does remove it from the feed list{' '}
          <FeedsIcon
            style={{
              display: 'inline-block',
              width: '40px',
              height: '40px',
            }}
          />{' '}
          and stops surfacing posts to anyone following it.{' '}
        </div>
      </div>

      <div
        style={{
          minWidth: '85%',
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 auto',
          alignItems: 'center',
        }}
      >
        <h1>
          {' '}
          <HomeIcon
            style={{
              display: 'inline-block',
              width: '40px',
              height: '40px',
            }}
          />{' '}
          A home is a place in Time.{' '}
          <HomeIcon
            style={{
              display: 'inline-block',
              width: '40px',
              height: '40px',
            }}
          />
        </h1>{' '}
        <div style={{ display: 'inline-block' }}>
          Above all else, we want to make you feel at home here. That's why
          we're Wipe.<b>House</b>. We hope you wouldn't yell at your housemates,
          or be hateful towards them, or force them to see illegal pornographic
          content, or make them watch a video of you eating a banana and really
          just mushing it all up and being super sloppy and noisy and gross with
          it. <br></br>
          <br></br> We hope you wouldn't do any of those things, but we've had
          bad housemates in the past, and we know what to do with them: kick
          them out. Be kind to eachother and don't get us sued, and we'll all
          have a great time here.{' '}
        </div>
      </div>
    </Container>
  );
}

export default WelcomePage;
