import * as React from 'react';
const TrophyIcon = (props: any) => (
  <svg
    viewBox="3.648 2.966 30 31.857"
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={31.857}
    {...props}
  >
    <path d="M24.168 11.77c0 0.072 0.01 0.098 0.026 0.062 0.01 -0.031 0.01 -0.093 0 -0.129 -0.015 -0.031 -0.026 -0.005 -0.026 0.067Z" />
    <path d="M5.709 3.088v3.152H4.081v28.37h26.021v-1.578h3.248V3.088l-27.641 0Zm0 29.945V7.814h22.765v25.218H5.709Zm26.018 -1.574h-1.624V6.24H7.333V4.662h24.393l0 26.797Z" />
    <path d="M22.872 18.984h1.349v-0.675h0.675v-0.675h0.675v-1.349h-0.675v1.349h-0.675v0.675h-0.675v-4.723h2.024v2.699h0.675V12.911H22.872z" />
    <path d="M23.547 11.561v-1.349H11.401v1.349H7.352v5.398h0.675v1.349h0.675v0.675h0.675v0.675h0.675v0.675h1.349v0.675h1.35v0.675h1.349v0.675h0.675v5.398h-0.675v0.675h-0.675v0.675h-1.349v0.675h-0.675v2.024H23.546v-2.024h-0.675v-0.675h-1.349v-0.675h-0.675v-0.675h-0.675V22.357h0.675v-0.675h1.349v-0.675h1.349v-0.675h1.349v-0.675h0.675v-0.675h0.675v-0.675h0.675V16.96h-0.675v1.349h-0.675v0.675h-0.675v0.675H23.546v0.675h-1.349v0.675H20.847v0.675h-0.675v0.675h-0.675v5.398h-4.049V22.357h-0.675v-0.675h-0.675v-0.675h-1.35v-0.675h-1.349v-0.675h-1.349v-0.675H9.377v-0.675h-0.675V16.96h-0.675v-4.723h4.049v-1.349h10.797v1.349h4.049v4.723h0.675l0 -5.398zm-0.675 18.219v1.349H12.075v-1.349zm-2.024 -0.675h-6.748v-0.675h6.748z" />
    <path d="M12.075 18.984h-1.349v-0.675h-0.675v-0.675h-0.675v-1.349h0.675v1.349h0.675v0.675h0.675v-4.723h-2.024v2.699h-0.675V12.911h3.374zm8.239 -6.629h-5.84v-0.531h5.84zm-5.84 0.531h-0.531v-0.531h0.531zm6.37 0h-0.531v-0.531h0.531zm-6.37 6.901h-0.531v-0.531h0.531zm6.37 0h-0.531v-0.531h0.531zm-0.531 0.531h-5.84v-0.531h5.84zm-5.309 -6.37v-0.531h4.778v0.531h0.531v-1.062h-5.84v1.062z" />
    <path d="M19.783 14.478h-0.531v-0.531h0.531zm-0.531 0.531h-0.531v-0.531h0.531zm-0.531 0.531h-0.531v-0.531h0.531zm-3.185 -1.062h-0.531v-0.531h0.531zm4.247 3.716h-0.531v-0.531h0.531zm-4.247 0h-0.531v-0.531h0.531zm0.531 -3.185h-0.531v-0.531h0.531zm0.531 0.531h-0.531v-0.531h0.531zm2.654 2.124h-0.531v-0.531h0.531z" />
    <path d="M16.067 17.664h-0.531v-0.531h0.531zm2.655 -0.531h-0.531v-0.531h0.531zm-0.531 -0.531h-0.531v-1.062h0.531zm-1.593 0.531h-0.531v-0.531h0.531zm0.531 -0.531h-0.531v-1.062h0.531z" />
    <path d="M20.314 19.256V18.195h-0.531v0.531h-1.062v-0.531h-0.531v-0.531h-0.531v-0.531h-0.531v0.531h-0.531v0.531h-0.531v0.531h-1.062v-0.531h-0.531v1.062zm-5.841 -6.378v1.062h0.531v-0.531h1.062v0.531h0.531v0.531h0.531v0.531h0.531v-0.531h0.531v-0.531h0.531v-0.531h1.062v0.531h0.531v-1.062h-5.84Z" />
  </svg>
);
export default TrophyIcon;
