import * as React from 'react';
const TombstoneCustomIcon = (props: any) => (
  <svg
    viewBox="0 0 97.2 121.5"
    xmlns="http://www.w3.org/2000/svg"
    width={100}
    height={125}
    {...props}
  >
    <path d="M82.01 36.45h3.04v69.86h-3.04v3.04H15.19v-3.04h-3.04V36.45h3.04v-6.074h3.036V27.34h3.04V24.3h3.038v-3.04h3.036v-3.034h3.04l-.004-3.036h6.074v-3.04h24.3v3.04h6.076v3.036h3.034v3.034h3.04v3.04h3.04v3.04h3.034v3.036h3.04l-.004 6.074Zm-3.036 0H75.94v-3.04H72.9v-3.034h-3.04v-3.042h-3.034V24.3h-3.042v-3.04H60.75v-3.034h-24.3v3.034h-3.04v3.04h-3.034v3.034h-3.042v3.042h-3.038v3.034H21.26v3.04h-3.04v66.826h60.75l.004-66.826ZM97.2 33.41v85.056h-3.036v3.034H3.04v-3.04l-3.04.006V33.41h3.04v-6.07h3.036v-6.08h3.04v-3.034h3.034V15.19h3.04v-3.04h3.036V9.116h3.034v-3.04h6.08v-3.04h6.07L33.414 0H63.79v3.04h6.076v3.036h6.074v3.04h3.034v3.034h3.04v3.04h3.036v3.036h3.04v3.04h3.036v6.074h3.038v6.074l3.036-.004Zm-6.074 0h-3.04v-6.07H85.05V24.3h-3.04v-3.04h-3.036v-3.034h-3.038l.004-3.036H72.9v-3.04h-3.04V9.116h-6.07v-3.04H33.414v3.04H27.34v3.034H24.3v3.04h-3.034v3.036h-3.04v3.04H15.19V24.3h-3.04v3.04H9.116v6.074h-3.04v82.012h85.05V33.41Z" />
    <g origin="0 0">
      <path d="M39.98 20.446h18.915v1.84H39.98v-1.84Zm-1.72 1.84h1.72v1.842h-1.72v-1.841Zm20.635 0h1.72v1.842h-1.72v-1.841ZM38.26 46.219h1.72v1.84h-1.72v-1.84Zm20.635 0h1.72v1.84h-1.72v-1.84Zm-18.915 1.84h18.915V49.9H39.98v-1.841Zm0-23.931v3.681h1.72v-1.84h15.476v1.84h1.72v-3.681H39.98Zm15.476 3.682h1.72v1.84h-1.72v-1.84Zm-1.719 1.84h1.72v1.84h-1.72v-1.84Zm-1.72 1.84h1.72v1.842h-1.72v-1.841ZM41.7 27.81h1.72v1.84H41.7v-1.84Zm13.756 12.886h1.72v1.84h-1.72v-1.84Zm-13.756 0h1.72v1.84H41.7v-1.84Z" />
      <path d="M43.42 29.65h1.72v1.84h-1.72v-1.84Zm1.718 1.84h1.72v1.842h-1.72v-1.841Zm8.599 7.365h1.72v1.84h-1.72v-1.84Zm-10.317 0h1.72v1.84h-1.72v-1.84Zm8.597-1.841h1.72v1.84h-1.72v-1.84Zm-1.72-3.682h1.72v3.682h-1.72v-3.682Z" />
      <path d="M45.138 37.014h1.72v1.84h-1.72v-1.84Zm1.72-3.682h1.72v3.682h-1.72v-3.682Zm10.318 9.204v1.84h-3.44v-1.84h-1.719v-1.84h-1.72v-1.842h-1.72v1.841h-1.719v1.841h-1.72v1.84H41.7v-1.84h-1.72v3.682h18.916v-3.682h-1.72Z" />
    </g>
    <text
      style={{
        fontFamily: '&quot',
        fontSize: 37,
        fontStyle: 'italic',
        fontWeight: 700,
        whiteSpace: 'pre',
        textAnchor: 'middle',
      }}
      x={-96.765}
      y={-194.794}
      origin="0 0"
    >
      <tspan
        x={50.285}
        y={60.939}
        style={{
          textDecoration: 'underline solid rgba(255,255,255,.8)',
          fontSize: '8.9px',
          wordSpacing: 0,
        }}
      >
        {'Here Lies'}
      </tspan>
    </text>
    <a href={'/' + props.username}>
      <text
        style={{
          fontFamily: '&quot',
          fontSize: '5px',
          fontWeight: 700,
          whiteSpace: 'pre',
          textAnchor: 'middle',
        }}
        x={50.488}
        y={70.316}
        origin="0 0"
      >
        @
        {String(props.username).length > 15
          ? props.username.slice(0, 12) + '...'
          : props.username}
        's
      </text>
    </a>
    <text
      style={{
        fontFamily: '&quot',
        fontSize: '8.3px',
        fontWeight: 700,
        whiteSpace: 'pre',
        textAnchor: 'middle',
      }}
      x={48.76}
      y={79}
      origin="0 0"
    >
      {props.objectType}
    </text>
    <text
      style={{
        fontFamily: '&quot',
        fontSize: 3.3,
        fontWeight: 700,
        whiteSpace: 'pre',
        textAnchor: 'middle',
      }}
      x={49.622}
      y={87.342}
      origin="0 0"
    >
      {props.from}
    </text>
    <text
      style={{
        fontFamily: '&quot',
        fontSize: 3.3,
        fontWeight: 700,
        whiteSpace: 'pre',
        textAnchor: 'middle',
      }}
      x={49.622}
      y={101.861}
      origin="0 0"
    >
      {props.to}
    </text>
    <text
      style={{
        fontFamily: '&quot',
        fontSize: 6,
        fontStyle: 'italic',
        fontWeight: 700,
        whiteSpace: 'pre',
        textAnchor: 'middle',
      }}
      x={48.832}
      y={95}
    >
      {'to'}
    </text>
  </svg>
);
export default TombstoneCustomIcon;
