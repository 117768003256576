import React, { useContext, useState } from 'react';

import { Box, Stack, styled } from '@mui/material';
import SubmitWithTimeButton from '../SubmitWithTimeButton/SubmitWithTimeButton';
import { AuthContext, MyContext } from '../context/AuthContext';
import { Link } from 'react-router-dom';
import { MentionsInput, Mention } from 'react-mentions';
import { CacheContext, CacheContextType } from '../context/CacheContext';
import AvatarIcon from '../resource/AvatarIcon';

const UsernameLink = styled(Link)`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  color: black;
`;

function SubmitPostComponent({
  parentID,
  onSubmit,
  objectType,
  parentType,
  defaultComposition,
}: {
  parentID?: string;
  onSubmit?: () => void;
  objectType: string;
  parentType?: string;
  defaultComposition?: string;
}) {
  const [currentComposition, setCurrentComposition] = useState('');
  const users = useContext<CacheContextType>(CacheContext).allUsers();
  const feeds = useContext<CacheContextType>(CacheContext).allFeeds();

  const [mentions, setMentions] = useState<string[]>([]);
  const [feedTags, setFeedTags] = useState<string[]>([]);
  function mentionMatcher(query: string, text: string) {
    return text.toLowerCase().indexOf(query.toLowerCase()) === 0;
  }

  function searchUsernames(query: string, callback: (data: any) => void) {
    const matches = users.filter((userRecord) =>
      mentionMatcher(query, userRecord.username),
    );
    const displayMatches = matches.map((userRecord) => ({
      id: userRecord.id,
      display: userRecord.username,
    }));
    callback(displayMatches);
  }

  function searchFeeds(query: string, callback: (data: any) => void) {
    const matches = feeds.filter((feed) => mentionMatcher(query, feed.feedTag));
    const displayMatches = matches.map((userRecord) => ({
      id: userRecord.id,
      display: userRecord.feedTag,
    }));
    callback(displayMatches);
  }

  const regexpFeed = new RegExp(/#([A-Za-z0-9_]+)/gm);
  const handleCompositionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    newValue: string,
    newPlainTextValue: string,
    mentions: any,
  ) => {
    let feedMentions: Array<any> = [];
    let userMentions: Array<any> = [];
    setCurrentComposition(event.target.value);
    mentions.forEach((mention: any) => {
      if (mention.display.includes('#')) {
        feedMentions.push(mention);
      } else {
        userMentions.push(mention);
      }
    });

    const matches = event.target.value.match(regexpFeed);
    matches?.forEach((match) => {
      feedMentions.push({ display: match });
    });
    setMentions(userMentions);
    setFeedTags(feedMentions);
  };

  const handleSubmit = () => {
    setCurrentComposition('');
    onSubmit && onSubmit();
  };

  const userRecord = useContext<MyContext>(AuthContext).userRecord;

  // const useStyles = makeStyles({
  //   textarea: {
  //     resize: 'none',
  //   },
  // });

  return (
    <Box
      sx={{
        boxSizing: 'border-box',
        background: 'white',
        margin: '0 auto',
        borderRadius: '3px',
        padding: 1,
        width: '100%',
        textAlign: 'left',
      }}
    >
      <Stack direction="row" spacing={1} sx={{ maxWidth: '560px' }}>
        <AvatarIcon />
        <Stack direction="column" style={{ width: '100%' }}>
          <Stack direction="row" spacing={0.5}>
            <Box
              sx={{
                color: 'black',
                fontSize: '16px',
                fontWeight: 700,
              }}
            >
              {!userRecord
                ? 'loading'
                : (
                    <UsernameLink to={'/' + userRecord.username}>
                      {userRecord.username}
                    </UsernameLink>
                  ) || ''}
            </Box>
            <Box
              sx={{
                color: 'rgb(83, 100, 113)',
                fontSize: '16px',
                fontWeight: 400,
              }}
            >
              @
              {!userRecord
                ? 'loading'
                : (
                    <UsernameLink to={'/' + userRecord.username}>
                      {userRecord.username}
                    </UsernameLink>
                  ) || ''}
            </Box>
            <Box
              sx={{
                color: 'rgb(83, 100, 113)',
                fontSize: '16px',
                fontWeight: 400,
              }}
            >
              -
            </Box>
          </Stack>
          <Box style={{ resize: 'vertical', width: '100%' }}>
            <MentionsInput
              value={currentComposition}
              onChange={handleCompositionChange}
              style={{
                wordWrap: 'break-word',
                wordBreak: 'break-all',
                resize: 'vertical',
                minHeight: '56px',
              }}
            >
              <Mention
                trigger={/@((..*))/}
                data={searchUsernames}
                appendSpaceOnAdd={true}
                displayTransform={(id, display) => `@${display}`}
              />
              <Mention
                trigger={/#((..*))/}
                data={searchFeeds}
                appendSpaceOnAdd={true}
                markup="#[__display__](__id__)"
                displayTransform={(id, display) => `#${display}`}
              />
            </MentionsInput>
            <Box style={{ textAlign: 'right', marginTop: '12px' }}>
              {parentID ? (
                <SubmitWithTimeButton
                  objectType={objectType}
                  value={currentComposition}
                  parentID={parentID}
                  parentType={parentType}
                  onSubmit={handleSubmit}
                  mentions={mentions}
                  feedTags={feedTags}
                />
              ) : (
                <SubmitWithTimeButton
                  objectType={objectType}
                  value={currentComposition}
                  onSubmit={handleSubmit}
                  mentions={mentions}
                  feedTags={feedTags}
                />
              )}
            </Box>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
}

export default SubmitPostComponent;
