import React from 'react';
import firebase from 'firebase/auth';
import { UserRecord } from '../model/types';

export interface MyContext {
  userRecord: UserRecord | null;
  user: firebase.User | null;
  getNotifications: () => any[];
  getFollows: () => any[];
}

export const AuthContext = React.createContext<MyContext>({
  user: null,
  userRecord: null,
  getNotifications: () => [],
  getFollows: () => [],
});
