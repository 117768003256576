import * as React from 'react';
const FeedIcon = (props: any) => (
  <svg
    viewBox="12.655 20.394 45 42.354"
    xmlns="http://www.w3.org/2000/svg"
    width={45}
    height={42.354}
    {...props}
  >
    <path d="M27.183 23.169v1.687h-1.121l0.032 3.31 0.032 3.31h-1.196l0.032 2.242 0.032 2.242h-1.121v2.253l-0.555 -0.021 -0.566 -0.032 -0.021 3.342 -0.021 3.331 -3.321 -0.011 -3.31 -0.011v2.253h-1.174v4.484h6.726v5.552h-1.121l0.021 2.21 0.021 2.221 3.31 0.032 3.321 0.021v-3.417h1.142l-0.011 -3.331 -0.011 -3.331 0.566 0.032 0.555 0.021v-6.748l0.544 0.021 0.534 0.032 -0.011 -3.363 -0.011 -3.353 4.495 0.032 4.484 0.032v-2.253h1.068v-4.484h-7.794v-4.452l0.587 0.053 0.587 0.053 -0.021 -2.797 -0.032 -2.797 -3.342 -0.021 -3.331 -0.021v1.676Z" />
    <path d="m33.568 24.29 0.021 2.488h-0.416c-0.224 0 -0.47 0.064 -0.534 0.139 -0.075 0.096 -0.128 0.94 -0.139 2.349 -0.032 1.911 -0.011 2.221 0.139 2.37 0.16 0.16 0.587 0.181 3.865 0.16l3.684 -0.011 0.043 0.726c0.021 0.395 0.043 1.249 0.043 1.9l-0.011 1.175 -0.32 0.032c-0.705 0.064 -0.769 0.171 -0.79 1.175 -0.011 0.502 -0.043 0.95 -0.075 1.004 -0.032 0.043 -1.965 0.085 -4.303 0.085h-4.26l-0.117 0.288c-0.075 0.192 -0.128 1.377 -0.139 3.31l-0.021 3.021h-0.331c-0.726 0 -0.726 0 -0.769 2.947 -0.021 1.463 -0.032 2.915 -0.032 3.214v0.566h-0.406c-0.277 0 -0.438 0.064 -0.523 0.203 -0.086 0.139 -0.139 1.142 -0.16 3.299l-0.032 3.096 -0.416 0.053c-0.224 0.032 -0.459 0.107 -0.513 0.16 -0.053 0.053 -0.096 0.715 -0.096 1.473 0 0.758 -0.032 1.463 -0.053 1.559 -0.053 0.181 -0.288 0.192 -3.096 0.192h-3.032l0.032 -1.954 0.021 -1.943 0.406 -0.011c0.224 -0.011 0.459 -0.064 0.513 -0.117 0.171 -0.171 0.16 -5.638 -0.011 -5.851 -0.117 -0.139 -0.587 -0.16 -3.321 -0.16H15.225v-3.844h0.427c0.609 0 0.662 -0.149 0.737 -2.007l0.011 -0.235h3.161c2.894 0 3.182 -0.021 3.31 -0.192 0.107 -0.139 0.139 -0.875 0.15 -3.288v-3.107l0.438 -0.064c0.246 -0.032 0.491 -0.107 0.544 -0.16 0.053 -0.053 0.107 -0.544 0.107 -1.078v-0.972h0.395c0.705 0 0.747 -0.107 0.779 -2.402l0.032 -2.029 0.363 -0.011c0.246 0 0.427 -0.085 0.566 -0.256 0.203 -0.246 0.214 -0.406 0.171 -3.331l-0.032 -3.075h0.395c0.673 0 0.726 -0.139 0.726 -1.804 0 -0.811 0.032 -1.505 0.075 -1.548 0.043 -0.043 1.399 -0.064 3.022 -0.043l2.936 0.032 0.032 2.477Z" />
    <path d="m43.85 23.147 0.011 1.655 -0.544 0.032 -0.545 0.032v6.609h-1.121l0.032 3.363 0.032 3.363h-1.196l0.032 3.31 0.032 3.31h-8.915v2.242h-1.175v4.484l3.897 -0.021 3.897 -0.032v2.157c0 1.196 -0.011 2.2 -0.021 2.242 -0.021 0.053 -0.256 0.064 -0.534 0.053l-0.512 -0.043v5.68h6.62v-3.417h1.175V51.484l0.544 0.011h0.534l-0.011 -2.221 -0.011 -2.21h1.207l-0.043 -1.121 -0.032 -1.121h1.174v-6.631l3.342 0.011 3.353 0.011 -0.043 -1.132 -0.032 -1.121h1.121v-4.484h-6.716l-0.011 -2.776V25.923h1.174v-4.431h-6.737l0.021 1.655Z" />
    <path d="M50.288 21.93c0.032 0.096 0.064 0.961 0.064 1.922 0 1.484 -0.021 1.751 -0.16 1.762 -0.086 0.011 -0.31 0.021 -0.481 0.032 -0.182 0 -0.374 0.085 -0.427 0.171 -0.128 0.192 -0.117 0.117 -0.107 3.139 0 2.114 0.032 2.477 0.182 2.648 0.16 0.181 0.416 0.192 3.299 0.192h3.128l0.032 0.993c0.021 0.544 0.021 1.409 0 1.922l-0.032 0.929h-0.374c-0.598 0 -0.683 0.171 -0.683 1.271v0.972h-3.171c-2.584 -0.011 -3.193 0.021 -3.31 0.139 -0.117 0.117 -0.149 0.544 -0.149 1.954 0.011 0.993 -0.021 2.413 -0.053 3.15l-0.064 1.356 -0.374 0.053c-0.619 0.107 -0.673 0.214 -0.673 1.26v0.95h-0.363c-0.555 0 -0.705 0.214 -0.715 1.046 0 0.395 -0.021 1.121 -0.043 1.623 -0.021 0.502 -0.043 1.111 -0.043 1.356 -0.011 0.438 -0.021 0.459 -0.352 0.459 -0.181 0 -0.427 0.075 -0.534 0.181 -0.182 0.16 -0.192 0.406 -0.192 3.31v3.128h-0.363c-0.619 0 -0.705 0.235 -0.705 1.943v1.473h-6.193v-5.018h0.459c0.256 0 0.512 -0.053 0.587 -0.128 0.181 -0.181 0.181 -4.58 0 -4.762 -0.096 -0.096 -1.111 -0.128 -3.897 -0.128h-3.769v-3.844h0.416c0.566 0 0.715 -0.203 0.673 -0.972 -0.021 -0.342 -0.011 -0.769 0.032 -0.95l0.075 -0.32h4.228c3.78 0 4.239 -0.021 4.42 -0.182 0.181 -0.16 0.192 -0.406 0.192 -3.31v-3.128h0.374c0.651 0 0.737 -0.214 0.747 -1.836 0.021 -4.121 0.053 -4.912 0.192 -4.858 0.075 0.032 0.288 -0.011 0.48 -0.096l0.342 -0.139 0.011 -2.84c0.011 -1.559 0.032 -2.883 0.043 -2.936 0.011 -0.064 0.032 -0.224 0.043 -0.374 0.011 -0.235 0.053 -0.267 0.438 -0.267 0.31 0 0.448 -0.053 0.534 -0.203 0.053 -0.117 0.107 -0.886 0.107 -1.708v-1.505h3.032c2.669 0 3.043 0.021 3.096 0.171Z" />
    <path d="M50.16 24.429c0 0.149 0.021 0.203 0.053 0.128 0.021 -0.064 0.021 -0.192 0 -0.267 -0.032 -0.064 -0.053 -0.011 -0.053 0.139ZM16.079 32.596v1.121h-1.174v4.484h6.726v-2.242h1.068v-2.242h1.175v-2.242H16.079v1.121Z" />
    <path
      d="m49.413 45.943 0.032 1.121h-1.132l0.032 1.121 0.032 1.121h-1.174l0.021 1.089 0.011 1.1 3.919 0.032 3.919 0.021 -0.043 -1.121 -0.032 -1.121h1.11l0.032 -2.199c0.021 -1.207 0.011 -2.221 -0.011 -2.242 -0.021 -0.021 -1.559 -0.043 -3.406 -0.043H49.37l0.043 1.121Z"
      style={{
        paintOrder: 'fill',
      }}
    />
  </svg>
);
export default FeedIcon;
