import React from 'react';
import { Link } from 'react-router-dom';
import reactStringReplace from 'react-string-replace';
import linkify from 'linkify-it';

function ellipsizeMiddleOfUrl(url: string) {
  if (url.length > 40) {
    return url.substring(0, 20) + '...' + url.substring(url.length - 20);
  }
  return url;
}

export function replaceTagsWithLinks(text: string) {
  const linkCheck = new linkify();

  let newMessage = text;
  const regexpMention = new RegExp(/@\[(.*?)\]\(.*?\)/gm);
  const regexpFeed = new RegExp(/#\[(.*?)\]\(.*?\)/gm);
  const regexpFeedRaw = new RegExp(/#([A-Za-z0-9_]+)/gm);
  let returnFinal: any = newMessage;
  linkCheck.match(newMessage)?.forEach((match) => {
    returnFinal = reactStringReplace(newMessage, match.raw, (innermatch, i) => {
      return (
        <Link to={match.url} key={match.url + i} title={match.raw}>
          {ellipsizeMiddleOfUrl(match.raw)}
        </Link>
      ) as any;
    });
  });
  returnFinal = reactStringReplace(returnFinal, regexpMention, (match, i) => {
    return (
      <Link to={'/' + match} key={match + i}>
        @{match}
      </Link>
    ) as any;
  });
  returnFinal = reactStringReplace(returnFinal, regexpFeedRaw, (match, i) => {
    return (
      <Link to={'/feeds/' + match} key={match + i}>
        #{match}
      </Link>
    ) as any;
  });
  returnFinal = reactStringReplace(returnFinal, regexpFeed, (match, i) => {
    return (
      <Link to={'/feeds/' + match} key={match + i}>
        #{match}
      </Link>
    ) as any;
  });

  return returnFinal;
}
