import * as React from 'react';
const FollowIcon = (props: any) => (
  <svg viewBox="0 0 30 26" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.856.792v.575h-1.15v1.149h-1.149v1.149h-1.135l-.027 1.974a661.538 661.538 0 0 0 0 5.205l.027 3.231h1.135v2.299H9.26v1.149H6.967l-.02.554-.02.561-1.163.02-1.17.014v1.15H2.297v5.745h27.716v-5.746h-2.366v-1.149h-2.298v-1.149h-2.299v-1.15h-2.298v-2.298h1.15V3.665h-1.069l-.047-1.15-.054-1.148h-2.278V.217h-4.597v.575Zm3.447 2.292v.567l1.15.048 1.149.047v10.262h-1.15v1.217h-4.596v-2.299h-1.15V4.814h1.15V3.665h1.149v-1.15h2.298v.569ZM21.9 19.247v.574H24.2v1.15h2.298v1.149h1.15v1.149H4.594v-1.15H5.81v-1.148H8.11v-1.15h2.298v-1.149H21.9v.575ZM2.31 6.01c-.028.02-.048.54-.048 1.142v1.109l-1.129.013-1.135.02v2.299l1.135.02 1.13.014v2.298H4.56v-2.298l1.17-.014 1.162-.02V8.296l-1.163-.02-1.17-.014V5.963H3.46c-.609 0-1.13.02-1.15.048z"
      origin="0 1.007885"
    />
  </svg>
);
export default FollowIcon;
