import * as React from 'react';
const WipeAnimated = (props: any) => (
  <svg
    viewBox="0 0 110.442 29.564"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m69.108 10.092-.012 1.143v11.367l3.383-.001V10.096l-3.371-.004Z"
      origin="-0.806754 21.424331"
    />
    <path
      d="M69.099 6.198h3.379v2.777h-3.379V6.198Z"
      origin="-20.449555 -2.231905"
    />
    <path
      d="m75.325 11.401-.01 1.023V21.6l-.002 1.021h3.378V11.404l-3.366-.004Z"
      origin="-1.014974 23.059793"
    />
    <path
      d="m75.314 21.662-.012.704v7.02l3.38-.001v-7.722l-3.368-.001Z"
      origin="-2.802779 23.050065"
    />
    <path
      d="m77.044 22.611 1.274.01h12.68l-.001-2.717H77.049l-.005 2.707Z"
      origin="-5.46431 -7.320901"
    />
    <path
      d="m87.631 11.401-.01 1.023V21.6l-.002 1.021H91V11.404l-3.368-.004Z"
      origin="-1.014975 26.695175"
    />
    <path
      d="m89.217 10.016-1.08-.009H77.383l.001 2.717h11.829l.004-2.708Z"
      origin="7.471861 4.678554"
    />
    <path
      d="m93.695 11.388-.011 1.023v9.176l-.001 1.02h3.379V11.393l-3.367-.005Z"
      origin="-1.014928 29.613045"
    />
    <path
      d="m95.056 12.703 1.148.009h11.434l-.001-2.717H95.061l-.005 2.708Z"
      origin="-7.793355 -3.67869"
    />
    <path
      d="m93.684 18.38 1.273.009h12.681l-.001-2.717H93.689l-.005 2.708Z"
      origin="-6.928765 -5.768127"
    />
    <path
      d="m106.003 11.321-.01.541v4.85l-.002.538h3.38v-5.928h-3.368Z"
      origin="-1.909432 33.255394"
    />
    <path
      d="m93.975 22.598 1.273.01h12.681l-.001-2.717H93.98l-.005 2.707Z"
      origin="-6.949619 -7.320945"
    />
    <path
      d="M66.126 3.22h2.97v2.97h-2.97V3.22Zm-35.635 0h2.97-2.97Zm2.97-2.97h32.665v2.97H33.461V.25Zm2.97 11.879h2.97v2.97h-2.97v-2.97Zm23.756 0h2.97v2.97h-2.97v-2.97Zm-20.786 2.968h2.97v2.97h-2.97v-2.97Z"
      origin="-0.78982 -0.014032"
    />
    <path
      d="M57.218 15.098h2.97v1.948h-2.97v-1.948Zm-14.849 1.948h2.97v1.947h-2.97v-1.947Zm2.97 1.947h2.97v3.894h-2.97v-3.894Zm8.909-1.947h2.97v1.947h-2.97v-1.947Zm-2.97 1.947h2.97v3.894h-2.97v-3.894Z"
      origin="-2.377743 -1.270982"
    />
    <path
      d="M33.462 6.19v5.939h2.97v-2.97h5.939v2.97h2.97v2.969h2.97v2.97h2.97v-2.97h2.97v-2.969h2.97v-2.97h5.939v2.97h2.97V6.19H33.462Z"
      origin="-1.024305 -0.521131"
    />
    <path
      d="M3.22 3.279h32.665V.309H3.22v2.97ZM.25 6.249h2.97v-2.97H.25v2.97Zm32.665 5.94v-2.97H6.19v2.97H3.22v-5.94h32.665v5.939l-2.97.001Z"
      origin="-0.007016 -0.02601"
    />
    <path
      d="M6.19 14.326h2.97v-2.139H6.19v2.139Zm2.97 2.139h2.97v-2.139H9.16v2.139Zm2.968 2.139h2.97v-2.139h-2.97v2.139Zm17.817-4.278h2.97v-2.139h-2.97v2.139Zm-2.968 2.139h2.97v-2.139h-2.97v2.139Zm-2.97 2.139h2.97v-2.139h-2.97v2.139Zm-8.909 4.278h2.97v-4.278h-2.97v4.278Zm5.94 0h2.97v-4.278h-2.97v4.278Z"
      origin="-0.231618 -0.820673"
    />
    <path
      d="M48.608 22.865h2.371v2.337h-2.371v-2.337"
      origin="-20.501069 -10.21181"
    >
      <animate
        repeatCount="indefinite"
        dur="3s"
        attributeName="visibility"
        values="hidden;visible;hidden"
      />
    </path>
    <path
      d="M48.639 18.594h2.371v2.337h-2.371v-2.337"
      origin="-20.514143 -8.384254"
    >
      <animate
        dur="3s"
        attributeName="visibility"
        repeatCount="indefinite"
        values="visible;hidden;hidden"
      />
    </path>
    <path
      d="M48.76 27.091h2.371v2.337H48.76v-2.337"
      origin="-20.565143 -12.020112"
    >
      <animate
        dur="3s"
        values="hidden;hidden;visible"
        repeatCount="indefinite"
        attributeName="visibility"
      />
    </path>
  </svg>
);
export default WipeAnimated;
