import * as React from 'react';
const HourglassUpIcon = (props: any) => (
  <svg
    viewBox="6.461 5.287 30 32.707"
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={32.707}
    {...props}
  >
    <path d="M10.918 35.196h21.306v1.937H10.918zm21.306-1.937h1.937v1.937h-1.937zm-23.243 0h1.937v1.937H8.981zm23.243-25.18h1.937v1.937h-1.937zm-23.243 0h1.937v1.937H8.981zm1.937-1.937h21.306v1.937H10.918zm19.369 23.243v1.937H12.855v-1.937h-1.937v3.874h21.306v-3.874z" />
    <path d="M12.855 27.449h1.937v1.937h-1.937zm1.937-1.937h1.937v1.937h-1.937zm1.936-1.937h1.937v1.937h-1.937zm11.621 3.874h1.937v1.937H28.35zM12.854 13.891h1.937v1.937h-1.936zm15.495 0h1.937v1.937H28.35zm-1.936 11.621h1.937v1.937h-1.936zm-1.937-1.937h1.937v1.937h-1.936zm-9.684-7.748h1.937v1.937h-1.937z" />
    <path d="M26.414 15.826h1.937v1.937h-1.937zm-9.685 1.937h1.937V19.7h-1.938zm1.937 1.937h1.937v3.874h-1.938zm5.811-1.937h1.937V19.7h-1.937zM22.54 19.7h1.937v3.874H22.54z" />
    <path d="M10.918 10.016v3.874h1.937v-1.937h3.874v1.937h1.937v1.937h1.937v1.937h1.937v-1.937h1.937V13.89h1.937v-1.937h3.874v1.937h1.937v-3.874z" />
  </svg>
);
export default HourglassUpIcon;
