import React, { useContext } from 'react';
import TimeDuration from '../TimeDuration/index';
import { stringToArray, getSchedule } from 'cron-converter';
import { CacheContext, CacheContextType } from '../context/CacheContext';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import StatTable from './StatTable';
import MostFollowedTable from './MostFollowedTable';
import MostVotedTable from './MostVotedTable';
function LeaderboardPage() {
  const wipeDate = getSchedule(
    stringToArray('0 0 1 * *'),
    new Date(),
    'America/Los_Angeles',
  )
    .next()
    .toJSDate();

  const allUsers = useContext<CacheContextType>(CacheContext).allUsers;
  const allPosts = useContext<CacheContextType>(CacheContext).allPosts;
  const allFeeds = useContext<CacheContextType>(CacheContext).allFeeds;
  const allFollows = useContext<CacheContextType>(CacheContext).allFollows;
  let follows = allFollows();
  let posts = allPosts();
  let users = allUsers();
  let feeds = allFeeds();
  console.log(posts, feeds);
  return (
    <div className="App">
      <h1 style={{ textAlign: 'center' }}>Leaderboard</h1>
      <h2 style={{ textAlign: 'center' }}>
        Time left until wipe: <TimeDuration jsDate={wipeDate} />
      </h2>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Users</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Most Posts</TableCell>
              <TableCell align="right">Most Follows</TableCell>
              <TableCell align="right">Most Followed</TableCell>
              <TableCell align="right">Most Mentioned</TableCell>
              <TableCell align="right">Most Votes</TableCell>
              <TableCell align="right">Most Voted For</TableCell>

              <TableCell align="right">Biggest Time Wallet</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>
                {' '}
                <StatTable
                  objectList={users}
                  statType="array"
                  identifier="username"
                  stat="posts"
                />
              </TableCell>
              <TableCell align="right">
                <StatTable
                  objectList={users}
                  statType="array"
                  identifier="username"
                  stat="follows"
                />
              </TableCell>
              <TableCell align="right">
                <MostFollowedTable
                  identifier="username"
                  stat="Followers"
                  objectList={follows}
                />
              </TableCell>
              <TableCell align="right">
                {' '}
                <StatTable
                  objectList={users}
                  statType="array"
                  identifier="username"
                  stat="mentions"
                />
              </TableCell>
              <TableCell align="right">
                {' '}
                <StatTable
                  objectList={users}
                  statType="array"
                  identifier="username"
                  stat="votes"
                />
              </TableCell>
              <TableCell align="right">
                {' '}
                <MostVotedTable
                  objectList={users}
                  identifier="username"
                  stat="votes"
                />
              </TableCell>
              <TableCell align="right">
                {' '}
                <StatTable
                  objectList={users}
                  statType="time"
                  identifier="username"
                  stat="timeWallet"
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Posts</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Most Posts</TableCell>
              <TableCell align="right">Most Votes</TableCell>
              <TableCell align="right">Most Time Gained</TableCell>
              <TableCell align="right">Most Time Lost</TableCell>
              <TableCell align="right">Most Replies</TableCell>
              <TableCell align="right"> Longest Lived</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>Most Posts</TableCell>
              <TableCell align="right">Most Votes</TableCell>
              <TableCell align="right">Most Time Gained</TableCell>
              <TableCell align="right">Most Time Lost</TableCell>
              <TableCell align="right">Most Replies</TableCell>
              <TableCell align="right"> Longest Lived</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Feeds</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Most Posts</TableCell>
              <TableCell align="right">Most Votes</TableCell>
              <TableCell align="right"> Most Followed</TableCell>
              <TableCell align="right">Most Time Gained</TableCell>
              <TableCell align="right"> Longest Lived</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>Most Posts</TableCell>
              <TableCell align="right">Most Votes</TableCell>
              <TableCell align="right"> Most Followed</TableCell>
              <TableCell align="right">Most Time Gained</TableCell>
              <TableCell align="right"> Longest Lived</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default LeaderboardPage;
