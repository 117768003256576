import * as React from 'react';
const WLogoAnimated = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="182.292 419.972 100 57.972"
    style={{
      zoom: 1,
    }}
    width={100}
    height={57.972}
    {...props}
  >
    <path d="M276.494 424.269h4.221v4.221h-4.221v-4.221Zm-50.64 0h4.221Zm4.221-4.221h46.42v4.221h-46.42v-4.221Zm4.221 16.881h4.221v4.221h-4.221v-4.221Zm33.759 0h4.221v4.221h-4.221v-4.221Zm-29.539 4.218h4.221v4.221h-4.221v-4.221Z" />
    <path d="M263.835 441.149h4.221v4.221h-4.221v-4.221Zm-21.102 4.221h4.221v4.221h-4.221v-4.221Zm4.221 4.221h4.221v8.44h-4.221v-8.44Zm12.66-4.221h4.221v4.221h-4.221v-4.221Zm-4.221 4.221h4.221v8.44h-4.221v-8.44Z" />
    <path d="M230.076 428.49v8.44h4.221v-4.221h8.44v4.221h4.221v4.219h4.221v4.221h4.221v-4.221h4.221v-4.219h4.221v-4.221h8.44v4.221h4.221v-8.44h-46.424Z" />
    <path d="M187.099 424.353h46.42v-4.221h-46.42v4.221Zm-4.221 4.221h4.221v-4.221h-4.221v4.221Zm46.42 8.441v-4.221H191.32v4.221h-4.221v-8.441h46.42v8.44l-4.221.001Z" />
    <path d="M191.32 441.233h4.221v-4.221h-4.221v4.221Zm4.221 4.221h4.221v-4.221h-4.221v4.221Zm4.218 4.221h4.221v-4.221h-4.221v4.221Zm25.319-8.441h4.221v-4.221h-4.221v4.221Zm-4.218 4.221h4.221v-4.221h-4.221v4.221Zm-4.221 4.221h4.221v-4.221h-4.221v4.221Zm-12.662 8.441h4.221v-8.44h-4.221v8.44Zm8.441 0h4.221v-8.44h-4.221v8.44Zm30.63 19.712h21.103v-4.221h-4.221v-4.219h-4.221v-4.221h-4.221v4.221h-4.221v4.219h-4.221v4.221Z" />
    <path d="M251.599 453.607h3.369v3.321h-3.369v-3.321">
      <animate
        repeatCount="indefinite"
        dur="3s"
        attributeName="visibility"
        values="hidden;visible;hidden"
      />
    </path>
    <path d="M251.643 447.538h3.369v3.321h-3.369v-3.321">
      <animate
        dur="3s"
        attributeName="visibility"
        repeatCount="indefinite"
        values="visible;hidden;hidden"
      />
    </path>
    <path d="M251.815 459.613h3.369v3.321h-3.369v-3.321">
      <animate
        dur="3s"
        values="hidden;hidden;visible"
        repeatCount="indefinite"
        attributeName="visibility"
      />
    </path>
  </svg>
);
export default WLogoAnimated;
