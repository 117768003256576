import React from 'react';
import TimeDisplay from '../TimeDisplay';
import { Link } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
function StatTable({
  objectList,
  stat,
  identifier,
  statType,
}: {
  objectList: Array<any>;
  stat: string;
  statType?: string;
  identifier: string;
}) {
  objectList.sort((a, b) => {
    if (a[stat] === undefined) return 1;
    if (b[stat] === undefined) return -1;
    switch (statType) {
      case 'time':
        return a[stat].seconds > b[stat].seconds ? -1 : 1;
      case 'array':
        return a[stat].length > b[stat].length ? -1 : 1;
      default:
        return a[stat] > b[stat] ? -1 : 1;
    }
  });
  const leaders = objectList.map((object) => {
    if (object[stat] === undefined) return null;
    let statValue;
    switch (statType) {
      case 'time':
        if (object[stat].seconds === undefined) return null;

        statValue = <TimeDisplay timeValue={object[stat].seconds * 1000} />;
        break;
      case 'array':
        statValue = object[stat].length;
        break;
      default:
        statValue = object[stat];
        break;
    }
    return {
      id: object.id,
      identifier: object[identifier],
      statValue: statValue,
    };
  });
  const top5 = leaders.slice(0, 5);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 200 }} size="small">
        <TableHead>
          <TableRow>
            <TableCell>{identifier}</TableCell>
            <TableCell align="right">{stat}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {top5.map((object) => {
            if (object === null) return null;
            return (
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                key={object?.id}
              >
                <TableCell component="th" scope="row">
                  <Link to={'/' + object?.identifier}>
                    @{object?.identifier}
                  </Link>{' '}
                </TableCell>
                <TableCell align="right">{object?.statValue}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default StatTable;
