import React, { useState, useEffect } from 'react';
import TimePicker from '../timePickerComponent/timePickerComponent';
import { AuthContext, MyContext } from '../context/AuthContext';
import { Box, Button, Tooltip } from '@mui/material';
import { Stack } from '@mui/material';
import { DateTime } from 'luxon';
import { TimeSelection } from '../model/types';
import useLongPress from '../useLongPress';
import { CacheContext, CacheContextType } from '../context/CacheContext';

import {
  setDoc,
  doc,
  updateDoc,
  collection,
  arrayUnion,
  Timestamp,
} from 'firebase/firestore';
import { db } from '../firebaseServices';
import PermanenceIcon from '../resource/PermanenceIcon';
function SubmitWithTimeButton({
  objectType,
  value,
  parentID,
  parentType,
  onSubmit,
  mentions,
  feedTags,
}: {
  objectType: string;
  value: string;
  parentID?: string;
  parentType?: string;
  mentions?: Array<any>;
  feedTags?: Array<any>;
  onSubmit: () => void;
}) {
  const creationType = objectType.slice(0, -1);
  const userRecord = React.useContext<MyContext>(AuthContext).userRecord;
  const getFeedFromID =
    React.useContext<CacheContextType>(CacheContext).getFeedFromID;
  const getUserRecord =
    React.useContext<CacheContextType>(CacheContext).getUserRecord;
  const [showClock, setShowClock] = useState(false);
  const ref = React.useRef(null);
  const [time, setTime] = useState<TimeSelection>({
    days: 0,
    hours: 1,
    minutes: 0,
  });

  function addMention(
    objectType: string,
    objectID: string,
    userRecordID: string,
    mentionedUserRecordID: string,
  ) {
    const mentionRef = doc(collection(db, 'mentions'));
    setDoc(mentionRef, {
      userRecordID: userRecordID,
      createdAt: Timestamp.now(),
      objectType: objectType,
      objectID: objectID,
      mentionedUserRecordID: mentionedUserRecordID,
    });

    const userRef = doc(db, 'users', mentionedUserRecordID);
    updateDoc(userRef, {
      mentions: arrayUnion(mentionRef.id),
    });
    return mentionRef;
  }
  function addFeedTag(
    feed: any,
    timeValue: number,
    userRecordID: string,
    objectType: string,
    objectID: string,
  ) {
    let feedRef;
    if (feed.id === undefined) {
      feedRef = doc(collection(db, 'feeds'));
      feed.id = feedRef.id;

      setDoc(feedRef, {
        feedTag: feed.display.replace('#', ''),
        createdAt: Timestamp.now(),
      });
    } else {
      feedRef = doc(db, 'feeds', feed.id);
    }

    const feedTagRef = doc(collection(db, 'feedTags'));
    setDoc(feedTagRef, {
      feedID: feed.id,
      userRecordID: userRecordID,
      objectType: objectType,
      objectID: objectID,
      createdAt: Timestamp.now(),
    });
    const feedRecord = getFeedFromID(feed.id);
    updateDoc(feedRef, {
      feedTags: arrayUnion(feedTagRef),
      expireAt: DateTime.fromSeconds(
        feedRecord?.expireAt.seconds + timeValue,
      ).toJSDate(),
      [objectType]: arrayUnion(objectID),
    });
    return feedTagRef;
  }

  const handleSubmit = (isPermanent: boolean = false) => {
    const timeValue =
      time.days * 24 * 60 * 60 + time.hours * 60 * 60 + time.minutes * 60;
    const expireAt = isPermanent
      ? DateTime.fromSeconds(99999999999).toJSDate()
      : DateTime.fromSeconds(Timestamp.now().seconds + timeValue).toJSDate();
    if (isPermanent && userRecord && userRecord.permanenceTokens < 1) {
      alert('Not enough permanence tokens');
      return;
    } else if (timeValue > userRecord?.timeWallet.seconds) {
      alert('Not enough time in your wallet');
      return;
    } else {
      const newObjectRef = doc(collection(db, objectType));

      if (mentions) {
        mentions.forEach((mention) => {
          addMention(
            objectType,
            newObjectRef.id,
            userRecord?.id || '',
            mention.id,
          );
        });
      }
      if (feedTags) {
        feedTags.forEach((feed) => {
          addFeedTag(
            feed,
            timeValue,
            userRecord?.id || '',
            objectType,
            newObjectRef.id,
          );
        });
      }
      setDoc(newObjectRef, {
        userRecordID: userRecord?.id,
        objectType: objectType,
        value: value,
        createdAt: Timestamp.now(),
        expireAt: expireAt,
        timeValue: isPermanent ? null : timeValue,
        isPermanent: isPermanent,
        parentID: parentID ?? null,
        parentType: parentType ?? null,
        mentions: mentions ?? null,
        feedTags: feedTags ?? null,
      });
      if (parentType && parentID) {
        const parentDocRef = doc(db, parentType, parentID || '');

        switch (objectType) {
          case 'follows':
            const followedUser = getUserRecord(parentID);
            const newUserTimeWallet = DateTime.fromSeconds(
              followedUser?.timeWallet.seconds + timeValue,
            ).toJSDate();
            updateDoc(parentDocRef, {
              timeWallet: newUserTimeWallet,
            });
            break;
          default:
            updateDoc(parentDocRef, {
              [objectType]: arrayUnion(newObjectRef.id),
            });
            break;
        }
      }

      const newTimeWallet = DateTime.fromSeconds(
        userRecord?.timeWallet.seconds - timeValue,
      ).toJSDate();
      const userRef = doc(db, 'users', userRecord?.id || '');
      updateDoc(userRef, {
        timeWallet: isPermanent ? userRecord?.timeWallet : newTimeWallet,
        permanenceTokens:
          isPermanent && userRecord
            ? userRecord?.permanenceTokens - 1
            : userRecord?.permanenceTokens,
        [objectType]: arrayUnion(newObjectRef.id),
      });

      onSubmit && onSubmit();
    }
  };

  const toggleClock = () => {
    setShowClock((prev) => !prev);
  };

  const onLongPress = () => {
    toggleClock();
  };

  const onClick = () => {
    handleSubmit();
  };
  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 300,
  };
  const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);

  useEffect(() => {
    switch (objectType) {
      case 'posts':
        setTime({
          days: userRecord?.userSettings.postDays,
          hours: userRecord?.userSettings.postHours,
          minutes: userRecord?.userSettings.postMinutes,
        });
        break;
      case 'votes':
        setTime({
          days: userRecord?.userSettings.voteDays,
          hours: userRecord?.userSettings.voteHours,
          minutes: userRecord?.userSettings.voteMinutes,
        });
        break;
      case 'follows':
        setTime({
          days: userRecord?.userSettings.followDays,
          hours: userRecord?.userSettings.followHours,
          minutes: userRecord?.userSettings.followMinutes,
        });
        break;
    }
  }, [objectType, userRecord]);

  return (
    <Box ref={ref} style={{ display: 'flex', justifyContent: 'flex-end' }}>
      {!userRecord ? (
        'Sign in to ' + creationType
      ) : (
        <Stack direction="row" spacing={2}>
          {value.length === 0 || showClock ? (
            <Stack direction="row" spacing={2}>
              <Button variant="outlined" disabled>
                {creationType + ' permanently'}
                <PermanenceIcon />
              </Button>
              <Button variant="outlined" disabled>
                {creationType}
              </Button>
            </Stack>
          ) : (
            <Stack direction="row" spacing={2}>
              <Tooltip title={creationType + ' will never expire'}>
                <Button variant="outlined" onClick={() => handleSubmit(true)}>
                  {creationType + ' permanently'}
                  <PermanenceIcon />
                </Button>
              </Tooltip>

              <Tooltip title="Click to use default time, hold down to select custom lifespan">
                <Button
                  variant="outlined"
                  sx={{ touchAction: 'none' }}
                  {...longPressEvent}
                >
                  {creationType}
                </Button>
              </Tooltip>
            </Stack>
          )}
          <TimePicker
            time={time}
            setTime={setTime}
            submit={handleSubmit}
            showClock={showClock}
            toggleClock={toggleClock}
            parentRef={ref}
          />
        </Stack>
      )}
    </Box>
  );
}

export default SubmitWithTimeButton;
