import * as React from 'react';
const HourglassFullIcon = (props: any) => (
  <svg
    viewBox="6.461 5.287 30 32.707"
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={32.707}
    {...props}
  >
    <path d="M32.224 8.079H10.918V6.142h21.306zm-21.306 1.937H8.981V8.079h1.937zm23.243 0h-1.937V8.079h1.937zm-23.243 25.18H8.981v-1.937h1.937zm23.243 0h-1.937v-1.937h1.937zm-1.937 1.937H10.918v-1.937h21.306zM12.855 13.89v-1.937h17.432v1.937h1.937v-3.874H10.918v3.874z" />
    <path d="M30.287 15.826H28.35v-1.937h1.937zm-1.937 1.937h-1.937v-1.937h1.937zM26.414 19.7h-1.937v-1.937h1.937zm-11.621-3.874h-1.937v-1.937h1.937zm15.495 13.558h-1.937v-1.937h1.937zm-15.495 0h-1.937v-1.937h1.937zm1.936-11.621h-1.937v-1.937h1.937zm1.937 1.937h-1.937v-1.937h1.937zm9.684 7.748h-1.937v-1.937h1.937z" />
    <path d="M16.728 27.449h-1.937v-1.937h1.937zm9.685-1.937h-1.937v-1.937h1.937zm-1.937-1.937h-1.937v-3.874h1.937zm-5.811 1.937h-1.937v-1.937h1.937zm1.937-1.937h-1.937v-3.874h1.937z" />
    <path d="M32.224 33.259v-3.874h-1.937v1.937h-3.874v-1.937h-1.937v-1.937h-1.937v-1.937h-1.937v1.937h-1.937v1.937h-1.937v1.937h-3.874v-1.937h-1.937v3.874zM10.911 9.989v3.874h1.937v-1.937h3.874v1.937h1.937V15.8h1.937v1.937h1.937V15.8h1.937v-1.937h1.937v-1.937h3.874v1.937h1.937V9.989H10.911Z" />
  </svg>
);
export default HourglassFullIcon;
