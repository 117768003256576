import * as React from 'react';
const ClockIcon = (props: any) => (
  <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="#242424">
      <path
        d="m10900 12290-1515 1160h-115v230l-232 2-233 3-3 111-3 111-803 7c-442 4-1169 4-1615 0l-811-7v-227h-470v-230l-1632-1160h-228v-230h-230v-230h-230v-460h-230v-240h-230v-690h-228l-7-465-7-465h-218V7880h218l7-465 7-465h215l6-167c4-91 7-246 7-345v-178h230v-230h227l8-230 7-230h217l3-117 3-118 118-3 117-3v-229h230v-230h230v-230h230v-230h230v-228l235-7 235-7v-228h460v-230h-230 4640-240v230h460v230h240v230h115l817 700h228v230h230v240h230v460h230v230h230v173c0 94 3 249 7 344l6 172 111 3 111 3 3 462 2 462 113 3 112 3 3 813 2 812h-230l-2 463-3 462-112 3-113 3v163c0 89-3 245-7 344l-6 182h-227v230h-230v460h-230v240h-230v230h-230Zm-3020 810v-110h178c97 0 255-3 350-7l172-6v-214l173-6c94-4 250-7 345-7h172v-230h243l-5-115-5-115h457v-230h240v-240h230v-230h228l7-230 7-230h218v-230h228l7-235 7-235h228v-920h230V7660h-230v-177c0-98-3-256-7-350l-6-173h-215l-7-235-7-235h-228v-460h-230v-230h-230v-230h-230v-229l-117-3-118-3-3-117-3-118h-449v-230h-240v-230l-232-2-233-3-3-111-3-111-559-7c-307-4-984-4-1504 0l-946 7v227h-690v230h-460v230h-229l-3 118-3 117-117 3-118 3v229h-230v230h-230v460h-230v230h-230l-2 233-3 232-111 3-111 3-6 172c-4 95-7 250-7 345v172h-217l-6 173c-9 223-9 1521 0 1745l6 172h217v168c0 93 3 251 7 350l6 182h215l7 230 8 230h227v460h230v230h230v230h229l3 118 3 117 118 3 117 3v229h230v230h460v230h178c99 0 254 3 345 7l167 6v214l182 6c99 4 257 7 350 7h168v220h1390v-110Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          fill: '#000',
          paintOrder: 'stroke',
        }}
        transform="translate(-34.899 -69.608) scale(.01876)"
      />
      <path
        d="M4935.293 13370.064h420.765v310.634h-420.765v-310.634Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="translate(-34.899 -69.608) scale(.01876)"
      />
      <path
        d="m4595.258 13111.678 67.492-2.123 341.513 15.816-2.898 340.933-405.716 2.806-.391-357.432Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="translate(-34.899 -69.608) scale(.01876)"
      />
      <path
        d="M4206.558 13007.374h452.175v232.189h-452.175v-232.189Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="translate(-34.899 -69.608) scale(.01876)"
      />
      <path
        d="M3964.902 12766.441h339.804v243.771h-339.804v-243.771Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="translate(-34.899 -69.608) scale(.01876)"
      />
      <path
        d="M4151.806 12744.719h420.765v265.169h-420.765v-265.169Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="translate(-34.899 -69.608) scale(.01876)"
      />
      <path
        d="M3728.276 12515.515h491.051v253.375h-491.051v-253.375Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="translate(-34.899 -69.608) scale(.01876)"
      />
      <path
        d="M3488.063 12259.936h387.183v257.22h-387.183v-257.22Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="translate(-34.899 -69.608) scale(.01876)"
      />
      <path
        d="M3237.178 12044.034h284.041v243.771h-284.041v-243.771ZM6033 12753l-232-3-3-113-3-112-232-3-233-2v-230h-460v-230h-240v-230h-230v-230h-230v-240h-230v-230h-230v-460h-228l-7-235-7-235h-215l-6-172c-4-95-7-251-7-345v-173h-217l-6-182c-9-233-9-1052 0-1275l6-173h217v-172c0-95 3-251 7-345l6-173h215l7-235 7-235h228v-460h230v-230h230v-230h229l3-117 3-118 118-3 117-3v-229h460v-230h240v-230h460v-230h2550v230h690v230h240v230l228 2 227 3 3 113 3 112h229v240h230v230h228l7 230 7 230h217l3 118 3 117 111 3 112 3 7 229 8 230h214l6 173c4 94 7 355 7 580v407h230v293c0 162-3 369-7 460l-6 167h-217v293c0 160-3 370-7 465l-6 172h-215l-7 235-7 235h-216l-7 230-8 230h-227v230h-230v240h-230v230h-230v230h-240v230h-460v230l-232 2-233 3-3 111-3 111-691 6c-646 7-1300 6-1845 0zm1847-578v-115l348-2 347-3 3-117 3-118h459v-218l230-7 230-7v-228h228l7-230 7-230h228v-230h-240v-230h-220v-242l460 7 460 7v-472h230V7650h-230v-690h-230v-240h-228l-7-230-7-230h-228v-230h-230v-230h-460v-228l-230-7-230-7v-216l-224-7c-124-4-229-4-235 0-8 4-11 115-11 351v344h-230v230h230v230h-930v-230h240v-230h230v-460h-230v230h-240v-470h700v-230l-577 2-578 3-3 693-2 692h-460V5342l-224-7c-124-4-229-4-235 0-7 4-11 48-11 114v109l-230 7-230 7v216l-230 7-230 7v228h-230v230h-229l-3 118-3 117-111 3-112 3-7 229-8 230h-227v460h-230v2550h230v470h228l7 230 7 230h228v230h230v230h230v230h230v228l230 7 229 7 3 112 3 111 348 3 347 2v230h1620v-115z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="translate(-34.899 -69.608) scale(.01876)"
      />
      <path
        d="M6720 11940v-120h-230v-220h230v-470h229l3-117 3-118 113-3 112-3v-219h240v460h230v917l-172 6c-95 4-305 7-465 7h-293v-120zM5448 11593l-118-4v-57c0-31 3-135 7-229l6-173h457v229l113 3 112 3 3 113 3 112h-63c-35 0-139 2-233 4-93 2-223 1-287-1zM8468 11593l-118-4v-218l113-3 112-3 3-117 3-118h229v230h230v227l-172 6c-95 4-198 6-228 5s-108-4-172-5zM4407 10495c-4-96-4-254 0-350l6-175h444l6 173c4 94 7 200 7 235v62h-229l-3 113-3 112-111 3-111 3-6-176zM9730 9395v-115h-230v-240h473l-6-172c-4-95-7-199-7-230v-58h-461l3-117 3-118 112-3 112-3 3-112 3-112 233-3 232-2v230h230v940h-230v230h-470v-115zM4170 9045v-235h-230v-700h293c160 0 370 3 465 7l172 6v292c0 160-3 367-7 458l-6 167h-216l-3 118-3 117-232 3-233 2v-235zm460-580v-115h-220v230h220v-115zM7559.283 8995.197l-444.386-3.496-117-3-118-3v-459l-.72-233.222 240.72 3.222h220l219.549 4.292-.163 694.204Zm-219.386-353.496v-115h-220v230h220v-115ZM4170 7190v-230h470v460h-470v-230zM9730 7363c0-32 3-136 7-230l6-173h227v220h230v240h-470v-57zM5560 6030v-230h460v460h-460v-230zM8570 6203c0-32 3-136 7-230l6-173h457v460h-470v-57zM10628.052 4871.09h272.969v229.301h-272.969V4871.09Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="translate(-34.899 -69.608) scale(.01876)"
      />
      <path
        d="M10353.289 4641.886h318.567v229.301h-318.567v-229.301Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="translate(-34.899 -69.608) scale(.01876)"
      />
      <path
        d="M10095.234 4411.338h353.401v232.189h-353.401v-232.189Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="translate(-34.899 -69.608) scale(.01876)"
      />
      <path
        d="M9967.714 4170.405h251.248v243.771h-251.248v-243.771Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="translate(-34.899 -69.608) scale(.01876)"
      />
      <path
        d="M10669.657 12198.995h272.969v229.301h-272.969v-229.301Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="rotate(-90 150.495 213.655) scale(.01876)"
      />
      <path
        d="m10439.893 12418.57 43.785-1.428 221.555 10.637-1.88 229.301-263.206 1.887-.254-240.397Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="rotate(-90 146.113 217.865) scale(.01876)"
      />
      <path
        d="M10201.161 12660.126h293.346v232.189h-293.346v-232.189Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="rotate(-90 141.897 222.333) scale(.01876)"
      />
      <path
        d="M10002.467 12847.558h220.446v243.771h-220.446v-243.771Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="rotate(-90 137.486 225.959) scale(.01876)"
      />
      <path
        d="M9965.183 12689.344h272.969v265.169h-272.969v-265.169Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="rotate(-90 137.279 223.191) scale(.01876)"
      />
      <path
        d="M9707.285 12947.204h318.567v253.375h-318.567v-253.375Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="matrix(0 -.01876 .01876 0 -95.074 360.8)"
      />
      <path
        d="M9487.32 13134.81h251.183v257.22H9487.32v-257.22Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="rotate(-90 128.11 231.474) scale(.01876)"
      />
      <path
        d="M9298.149 13337.75h184.27v243.771h-184.27v-243.771Z"
        style={{
          strokeWidth: 0,
          stroke: '#000',
          paintOrder: 'fill',
          fill: '#000',
        }}
        transform="rotate(-90 123.933 235.156) scale(.01876)"
      />
    </g>
  </svg>
);
export default ClockIcon;
