import React, { useContext, useEffect, useState } from 'react';

import { DateTime } from 'luxon';
import TimeDuration from '../TimeDuration/index';
import { Stack } from '@mui/system';
import { Box, styled } from '@mui/material';
import { PostData } from '../model/types';
import { Link } from 'react-router-dom';
import HealthBar from '../HealthBar/HealthBar';
import PostActions from './PostActions';
import '../fonts.css';
import { CacheContext, CacheContextType } from '../context/CacheContext';
import AvatarIcon from '../resource/AvatarIcon';
import { replaceTagsWithLinks } from '../lib';
import linkify from 'linkify-it';
import { LinkPreview } from '@dhaiwat10/react-link-preview';
import TombstoneCustomIcon from '../resource/TombstoneCustom';

const UsernameLink = styled(Link)`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  color: black;
`;

function Post({
  post,
  expandToReply,
}: {
  post: PostData;
  expandToReply?: string;
}) {
  const customFetcher = async (url: string) => {
    const response = await fetch(
      `https://wipe-house.herokuapp.com/v2?url=${url}`,
    );
    const json = await response.json();
    return json.metadata;
  };
  const linkCheck = new linkify();
  const getUserRecord =
    useContext<CacheContextType>(CacheContext).getUserRecord;
  const getPost = useContext<CacheContextType>(CacheContext).getPost;
  const [postRecord, setPostRecord] = useState<PostData | undefined>(
    getPost(post.id),
  );
  const [postUserRecord, setPostUserRecord] = useState<any | undefined>();
  const [isExpired, setIsExpired] = useState(false);
  const [expiryDate, setExpiryDate] = useState<Date>(
    DateTime.fromSeconds(post.expireAt.seconds).toJSDate(),
  );
  const [createdTime, setCreatedTime] = useState<DateTime>(
    DateTime.fromSeconds(post.createdAt.seconds),
  );
  const [isPermanent, setIsPermanent] = useState(false);
  const [linkToPreview, setLinkToPreview] = useState<string | undefined>();
  useEffect(() => {
    setPostRecord(getPost(post.id));
    if (!postRecord) return;
    setExpiryDate(
      DateTime.fromSeconds(postRecord?.expireAt.seconds).toJSDate(),
    );
    setCreatedTime(DateTime.fromSeconds(postRecord?.createdAt.seconds));

    setIsPermanent(postRecord?.isPermanent);
    setIsExpired(expiryDate < new Date() && !postRecord?.isPermanent);
    if (!postRecord?.userRecordID || postRecord.userRecordID === undefined)
      return;

    setPostUserRecord(getUserRecord(postRecord.userRecordID));
    const links = linkCheck.match(postRecord?.value);
    if (links && links.length > 0) {
      setLinkToPreview(links[0].url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postRecord, getPost]);

  return (
    <Box
      sx={{
        boxSizing: 'border-box',
        background: 'white',
        margin: '0 auto',
        borderRadius: '3px',
        padding: 1,
        width: '100%',
      }}
      id={postRecord?.id}
    >
      <Stack direction="row" spacing={7}>
        <Box style={{ width: 30, height: 30 }}>
          <HealthBar objectRecord={postRecord ?? post}>
            <AvatarIcon style={{ width: 40, height: 45 }} />
          </HealthBar>
        </Box>
        <Stack direction="column">
          <Stack direction="row" spacing={0.5}>
            <Box
              sx={{
                color: 'black',
                fontSize: '16px',
                fontWeight: 700,
              }}
            >
              {!postUserRecord
                ? 'loading'
                : (
                    <UsernameLink to={'/' + postUserRecord.username}>
                      {postUserRecord.username}
                    </UsernameLink>
                  ) || ''}
            </Box>
            <Box
              sx={{
                color: 'rgb(83, 100, 113)',
                fontSize: '16px',
                fontWeight: 400,
              }}
            >
              @
              {!postUserRecord
                ? 'loading'
                : (
                    <UsernameLink to={'/' + postUserRecord.username}>
                      {postUserRecord.username}
                    </UsernameLink>
                  ) || ''}
            </Box>
            <Box
              sx={{
                color: 'rgb(83, 100, 113)',
                fontSize: '16px',
                fontWeight: 400,
              }}
            >
              -
            </Box>
            <Box
              sx={{
                color: 'rgb(83, 100, 113)',
                fontSize: '15px',
                fontWeight: 400,
              }}
            >
              <TimeDuration
                jsDate={createdTime.toJSDate()}
                invertSign={true}
                titleDisplay="absoluteTime"
              />{' '}
              ago
            </Box>
            <Box
              sx={{
                color: 'rgb(83, 100, 113)',
                fontSize: '15px',
                fontWeight: 400,
              }}
            >
              -
            </Box>
            <Box
              sx={{
                color: 'rgb(83, 100, 113)',
                fontSize: '15px',
                fontWeight: 400,
              }}
            >
              {isExpired && 'Expired '}
              {isPermanent ? (
                'IMMORTAL'
              ) : (
                <TimeDuration jsDate={expiryDate} />
              )}{' '}
              {!isPermanent && (isExpired ? 'ago' : 'remain')}
            </Box>
          </Stack>
          <Box
            sx={{
              color: isExpired ? '#b7bdb9' : 'black',
              fontSize: '20px',
              fontWeight: 400,
              fontStyle: isExpired ? 'italic' : 'normal',
            }}
            justifyContent="center"
          >
            {isExpired ? (
              <Stack direction="column" spacing={0.5} sx={{ width: '100%' }}>
                <Box sx={{ width: '240px', margin: 'auto' }}>
                  <TombstoneCustomIcon
                    className="font-face-nokia"
                    username={postUserRecord.username}
                    from={DateTime.fromSeconds(
                      postRecord?.createdAt.seconds,
                    ).toFormat("EEEE',' MMMM d',' ha")}
                    to={DateTime.fromSeconds(
                      postRecord?.expireAt.seconds,
                    ).toFormat("EEEE',' MMMM d',' ha")}
                    objectType="post"
                    style={{ width: '200px', height: '250px' }}
                  />
                  <Box> this post is expired, vote to revive it</Box>
                </Box>
              </Stack>
            ) : (
              <Box>
                {replaceTagsWithLinks(postRecord?.value)}
                {linkToPreview && (
                  <LinkPreview
                    url={linkToPreview}
                    width="85%"
                    fetcher={customFetcher}
                    className="font-face-nokia"
                  />
                )}
              </Box>
            )}
          </Box>
          <PostActions
            post={postRecord ?? post}
            expandToReply={expandToReply}
          />
        </Stack>
      </Stack>
    </Box>
  );
}

export default Post;
