import React, { useRef, useState, useCallback, useEffect } from 'react';
import { Box, Typography, Backdrop } from '@mui/material';
import { TimeSelection } from '../model/types';
import ClockIcon from '../resource/ClockIcon';
import { Stack } from '@mui/system';
// TODO: Figure out why numbers work fine with picker but not with text input
function TimePicker({
  time,
  setTime,
  submit,
  showClock,
  toggleClock,
  parentRef,
}: {
  time: TimeSelection;
  setTime: (time: TimeSelection) => void;
  submit: () => void;
  showClock: boolean;
  toggleClock: () => void;
  parentRef: React.RefObject<HTMLDivElement>;
}) {
  const [timeSelection, setTimeSelection] = useState<number>(1);
  const ref = useRef<HTMLDivElement>(null);
  const [multiplier, setMultiplier] = useState(0);
  const [parentTop, setParentTop] = useState<any>(0);
  const [parentLeft, setParentLeft] = useState<any>(0);

  const pathsRef = useRef<Array<SVGPathElement | null>>([]);
  const cx = 54;
  const cy = 50;
  const r = 37;
  const slices = 12;
  const [lastPointerIndex, setLastPointerIndex] = useState(null);

  function RenderPaths() {
    const pointerDrag = (e: any, pointerIndex: any) => {
      if (lastPointerIndex === null) setLastPointerIndex(() => pointerIndex);

      if (lastPointerIndex === pointerIndex) return;
      adjustTime(pointerIndex, lastPointerIndex);
      setLastPointerIndex(pointerIndex);

      //setTimeSelection(timeSelection + (pointerIndex - lastPointerIndex));
    };

    const adjustTime = useCallback((pointerIndex: any, lastPointer: any) => {
      if (lastPointer === pointerIndex) return;
      let modifier = 0;
      switch (lastPointer - pointerIndex) {
        case 1:
          modifier = -1;
          break;
        case -1:
          modifier = 1;
          break;
        case 11:
          modifier = 1;
          break;
        case -11:
          modifier = -1;
          break;
      }
      if (timeSelection + modifier < 1) return;
      setTimeSelection(timeSelection + modifier);
      setMultiplier(Math.floor((timeSelection + modifier) / 12));
      time.hours = timeSelection + modifier;
      setTime(time);
    }, []);
    const paths = [...Array(slices)].map((_, i) => {
      var fromAngle, toAngle, fromCoordX, fromCoordY, toCoordX, toCoordY, d;
      fromAngle = (i * 360) / slices;
      toAngle = ((i + 1) * 360) / slices;
      fromCoordX = cx + r * Math.cos((fromAngle * Math.PI) / 180);
      fromCoordY = cy + r * Math.sin((fromAngle * Math.PI) / 180);
      toCoordX = cx + r * Math.cos((toAngle * Math.PI) / 180);
      toCoordY = cy + r * Math.sin((toAngle * Math.PI) / 180);
      d =
        'M' +
        cx +
        ',' +
        cy +
        ' L' +
        fromCoordX +
        ',' +
        fromCoordY +
        ' A' +
        r +
        ',' +
        r +
        ' 0 0,1 ' +
        toCoordX +
        ',' +
        toCoordY +
        'z';
      return (
        <path
          d={d}
          ref={(el) => (pathsRef.current[i] = el)}
          stroke="transparent"
          pointerEvents={'all'}
          onPointerOver={(e) => pointerDrag(e, i)}
          fill={
            Math.floor(timeSelection / 12) % 2 === 0
              ? time.hours % 12 > i
                ? 'black'
                : 'transparent'
              : time.hours % 12 > i
              ? 'transparent'
              : 'black'
          }
          key={'path' + i}
        ></path>
      );
    });
    return <g>{paths}</g>;
  }

  const preventDefault = useCallback((e: any) => {
    e = e || window.event;
    if (e.preventDefault) {
      e.preventDefault();
    }
    e.returnValue = false;
  }, []);

  const disableScroll = () => {
    document.addEventListener('wheel', preventDefault, {
      passive: false,
    });
    //document.addEventListener('touchmove', preventDefault, { passive: false });
  };

  const enableScroll = () => {
    document.removeEventListener('wheel', preventDefault, false);
    //document.removeEventListener('touchmove', preventDefault, false);
  };

  const handleScroll = (e: any) => {
    const modifier = e.deltaY > 0 ? 1 : -1;
    if (timeSelection + modifier < 1) return;
    setTimeSelection(timeSelection + modifier);
    setMultiplier(Math.floor((timeSelection + modifier) / 12));
    time.hours = timeSelection + modifier;
    setTime(time);

    return false;
  };

  const handleSubmit = () => {
    submit();
    setTimeSelection(0);
    setMultiplier(0);
    toggleClock();
    enableScroll();
  };

  useEffect(() => {
    if (showClock) {
      disableScroll();
      document.addEventListener('pointerup', handleSubmit, { once: true });
    } else {
      enableScroll();
    }
    setParentTop(
      () =>
        (parentRef?.current?.offsetTop || 0) -
        window.scrollY +
        (parentRef?.current?.offsetHeight || 0) / 2,
    );
    setParentLeft(
      () =>
        (parentRef?.current?.offsetLeft || 0) -
        window.scrollX +
        (parentRef?.current?.offsetWidth || 0) / 2,
    );

    //eslint-disable-next-line
  }, [showClock, parentRef]);
  return (
    <>
      {' '}
      {showClock && (
        <Box>
          <Backdrop
            sx={{ color: '#fff', zindex: 1 }}
            open={showClock}
            onWheel={handleScroll}
          >
            <Stack ref={ref} direction="row" spacing={1}>
              <Box
                position="absolute"
                left={parentLeft - 100}
                top={parentTop - 100}
              >
                <svg
                  height="200px"
                  width="200px"
                  viewBox="0 0 100 100"
                  style={{
                    transform: 'rotate(270deg)',
                    position: 'absolute',
                    pointerEvents: 'none',
                    zIndex: 3,
                  }}
                >
                  <RenderPaths />
                </svg>

                <ClockIcon height="200px" width="200px" zindex={1} />
                {multiplier > 0 && (
                  <Typography
                    sx={{
                      color: 'black',
                      fontSize: '40px',
                      fontWeight: 700,
                    }}
                  >
                    {' '}
                    {'+' + (multiplier * 12).toString() + ' hours'}
                  </Typography>
                )}
              </Box>
            </Stack>
          </Backdrop>
        </Box>
      )}
    </>
  );
}

export default TimePicker;
