import * as React from 'react';
const RepliesIcon = (props: any) => (
  <svg
    viewBox="7.266 4.436 30 30.93"
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30.93}
    {...props}
  >
    <path d="M11.349 20.603h1.271v1.078h-1.271zm-1.27-15.636h15.245v1.078H10.079zm7.623 10.783h7.623v1.078h-7.624zm7.622-9.704h1.27v1.078h-1.27zm0 8.626h1.27v1.078h-1.27zM8.808 6.046h1.27v1.078h-1.27zM7.537 7.124h1.271v7.548H7.537zm19.057 0h1.271v7.548h-1.271zM8.808 14.672h1.27v1.078h-1.27zm2.541 2.157v1.078h1.271V15.75h-2.541v1.078zm-1.271 2.157v2.157h1.271v-3.235h-1.271zm6.352-2.157h1.271v1.078H16.43zm-1.271 1.078h1.271v1.078h-1.271zm-1.27 1.078h1.27v1.078h-1.27zm-1.27 1.078h1.27v1.078h-1.27zm21.188-2.162H18.562v1.078h15.245zm-7.622 10.784h-7.623v1.078h7.623zm-7.623-9.705h-1.271v1.078h1.271zm0 8.627h-1.271v1.078h1.271zm16.516-8.627h-1.271v1.078h1.271zm1.271 1.078h-1.271v7.549h1.271zm-19.057 0h-1.271v7.549h1.27zm17.786 7.549h-1.271v1.078h1.271zm-3.812 2.157v-1.078h2.541v1.078h-1.271v1.078h-1.27z" />
    <path d="M32.537 30.841h1.271v3.235h-1.271zm-5.082-1.078h-1.27v1.078h1.27zm1.271 1.078h-1.271v1.078h1.271zm1.271 1.078h-1.27v1.078h1.27zm1.27 1.078h-1.27v1.078h1.271zm1.271 1.078h-1.27v1.078h1.27zm0-11.861H19.833v1.078h12.704zm0 3.235H22.373v1.078h10.164z" />
  </svg>
);
export default RepliesIcon;
