import * as React from 'react';
const SettingsIcon = (props: any) => (
  <svg
    viewBox="6.459 6.028 30 31.956"
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={31.956}
    {...props}
  >
    <path d="M8.761 6.611v3.08H7.17V37.41h25.424v-1.542h3.174V6.61H8.762Zm0 29.257v-24.64h22.242v24.639H8.761Zm25.42-1.538h-1.587V9.69H10.348V8.148h23.833v26.181Z" />
    <path d="M18.936 14.167h4.968v1.141h-4.968zm4.968 1.141h1.242v1.141h-1.242zm-1.242 1.142h1.242v1.141h-1.242zm-1.242 1.141h1.242v3.424H21.42zm1.242 3.424h3.727v1.141h-3.727z" />
    <path d="M26.389 19.874h1.242v1.141h-1.242zm1.242-1.141h1.242v1.141h-1.242zm1.242 1.141h1.242v4.565h-1.242zm-11.179-4.566h1.242v1.141h-1.242zm-1.242 1.141h1.242v1.141h-1.242zM15.21 17.59h1.242v6.848H15.21zm12.421 6.848h1.242v1.141h-1.242zm-1.242 1.142h1.242v1.141h-1.242zm-12.421-1.141h1.242v1.141h-1.242zm-1.242 1.141h1.242v1.141h-1.242zm-1.242 1.142h1.242v1.141h-1.242zm-1.242 1.141h1.242v1.141h-1.242zM9 29.004h1.242v1.141H9zm7.453 0h1.242v1.141h-1.243zm1.242-1.141h1.242v1.141h-1.242zm-2.484 2.283h1.242v1.141h-1.242zm-1.242 1.141h1.242v1.141h-1.242zm-1.244 1.141h1.242v1.141h-1.242zm6.21-5.707h1.242v1.141h-1.242zm1.242 0h6.21v1.141h-6.211z" />
  </svg>
);
export default SettingsIcon;
