import React, { useEffect, useState, PropsWithChildren } from 'react';
import { AuthContext, MyContext } from '../context/AuthContext';
import firebase from 'firebase/auth';
import { auth } from '../firebaseServices';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { db } from '../firebaseServices';
import { UserRecord } from '../model/types';

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<firebase.User | null>(null);
  const [userRecord, setUserRecord] = useState<UserRecord | null>(null);
  const [notifications, setNotifications] = useState<any[]>([]);
  const [follows, setFollows] = useState<any[]>([]);
  function getNotifications() {
    if (!userRecord) return;
    return notifications;
  }
  function getFollows() {
    if (!userRecord) return;
    return follows;
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      setUser(firebaseUser);
      if (user === null) setUserRecord(null);
      if (!user) return;
      onSnapshot(
        query(collection(db, 'users'), where('useruid', '==', user.uid)),
        (querySnapshot) => {
          let snapshotUsers: UserRecord[] = [];
          querySnapshot.forEach((doc) => {
            // We should use zod to validate the data from these records
            const userRec = {
              useruid: user.uid,
              id: doc.id,
              bio: doc.data().bio,
              username: doc.data().username,
              joinDate: doc.data().joinDate,
              timeWallet: doc.data().timeWallet,
              email: doc.data().email,
              follows: doc.data().follows,
              notifications: doc.data().notifications,
              userSettings: doc.data().userSettings,
              votes: doc.data().votes,
              posts: doc.data().posts,
              fcmToken: doc.data().fcmToken,
              permanenceTokens: doc.data().permanenceTokens,
            };
            snapshotUsers.push(userRec);
          });
          setUserRecord(snapshotUsers[0]);
        },
      );

      if (!userRecord) return;
      onSnapshot(
        query(
          collection(db, 'follows'),
          where('userRecordID', '==', userRecord.id),
        ),
        (querySnapshot) => {
          let snapshotFollows: any[] = [];
          querySnapshot.forEach((doc) => {
            // We should use zod to validate the data from these records
            const post = {
              userRecordID: doc.data().userRecordID,
              id: doc.id,
              value: doc.data().value,
              createdAt: doc.data().createdAt,
              expireAt: doc.data().expireAt,
              votes: doc.data().votes,
              posts: doc.data().posts,
              parentID: doc.data().parentID,
              isPermanent: doc.data().isPermanent,
              parentType: doc.data().parentType,
            };
            snapshotFollows.push(post);
          });
          userRecord.follows = snapshotFollows;
          setUserRecord(() => userRecord);
          setFollows(snapshotFollows);
        },
      );
      onSnapshot(
        query(
          collection(db, 'notifications'),
          where('userRecordID', '==', userRecord.id),
        ),
        (querySnapshot) => {
          let snapshotNotifications: any[] = [];
          querySnapshot.forEach((doc) => {
            // We should use zod to validate the data from these records
            const notification = {
              userRecordID: doc.data().userRecordID,
              id: doc.id,
              message: doc.data().message,
              createdAt: doc.data().createdAt,
              objectType: doc.data().objectType,
              objectID: doc.data().objectID,
              read: doc.data().read,
            };
            snapshotNotifications.push(notification);
          });
          userRecord.notifications = snapshotNotifications;
          setUserRecord(() => userRecord);
          setNotifications(snapshotNotifications);
        },
      );
    });
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, userRecord]);

  return (
    <AuthContext.Provider
      value={
        {
          user: user,
          userRecord: userRecord,
          getNotifications: getNotifications,
          getFollows: getFollows,
        } as MyContext
      }
    >
      {children}
    </AuthContext.Provider>
  );
};
